import React from "react";
import "../../../../styles/forms.css";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import {
  getAllSeedPresentations,
  registerSeedPresentation,
  updateSeedPresentation,
  deleteSeedPresentation,
} from "../../../../store/seeds/seedPresentation/thunks/seedPresentationThunks";
import { getAllSeedSpecies } from "../../../../store/seeds/seedSpecie/thunks/seedSpecieThunks";
import { getFixedCostPerPresentation } from "../../../../store/costManagement/fixedCost/thunks/fixedCostThunks";
import SeedPresentationListTable from "../../../../components/seeds/seedPresentations/tables/SeedPresentationsListTable";

function SeedPresentationListTablePage() {
  const { reload } = useDataAndReloadPage(
    [getAllSeedPresentations, getAllSeedSpecies, getFixedCostPerPresentation],
    []
  );

  const { handleOperation } = useOperationHandler({
    register: registerSeedPresentation,
    update: updateSeedPresentation,
    delete: deleteSeedPresentation,
  });

  const handleSeedPresentationOperation = async (operation, fixedCostData) => {
    await handleOperation(operation, fixedCostData);

    reload();
  };

  return (
    <div className="custom-border-large">
      <SeedPresentationListTable
        onSeedPresentationsOperation={handleSeedPresentationOperation}
      />
    </div>
  );
}

export default SeedPresentationListTablePage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "../customerApiService";

export const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

export const registerCustomer = createAsyncThunk(
  "customer/register",
  async (customerData, thunkAPI) => {
    try {
      return await customerService.registerCustomer(customerData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async (customerData, thunkAPI) => {
    try {
      let customer = await customerService.getCustomer(customerData);
      return customer;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (customerData, thunkAPI) => {
    try {
      return await customerService.updateCustomer(customerData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getAllCustomers = createAsyncThunk(
  "customer/listCustomers",
  async (thunkAPI) => {
    try {
      return await customerService.getAllCustomers();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getCustomersOwing = createAsyncThunk(
  "customer/getCustomersOwing",
  async (thunkAPI) => {
    try {
      return await customerService.getCustomersOwing();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getFilteredCustomers = createAsyncThunk(
  "customer/listFilteredCustomers",
  async (dataSearch, thunkAPI) => {
    try {
      return await customerService.getFilteredCustomers(dataSearch);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "users/deleteCustomer",
  async (customerData, thunkAPI) => {
    try {
      return await customerService.deleteCustomer(customerData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

import React from "react";
import TextField from "../../../common/forms/fields/textFields/TextField";
import EmailField from "../../../common/forms/fields/textFields/EmailField";
import PhoneField from "../../../common/forms/fields/numberFields/PhoneField";
import CheckField from "../../../common/forms/fields/selectionFields/CheckField";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import PartnerTypeField from "../../../common/forms/fields/selectionFields/PartnerTypeField";

const CustomerFormFields = ({ formData, onChange }) => {
  return (
    <div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Nombre Del Cliente"
          fieldName="name"
          fieldValue={capitalizeFirstLetterOfWords(formData?.name)}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group mt-4">
        <EmailField
          fieldLabel="Email"
          fieldName="email"
          fieldValue={formData.email}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Direccion"
          fieldName="address"
          fieldValue={capitalizeFirstLetterOfWords(formData?.address)}
          onChangeHandler={onChange}
        />
      </div>
      <div className="form-group mt-4">
        <PhoneField
          fieldLabel="Numero Telefonico"
          fieldName="phone_number"
          fieldValue={formData.phone_number}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group w-50 mt-4">
        <TextField
          fieldLabel="Documento"
          fieldName="document"
          fieldValue={formData.document}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="d-flex justify-content-around mt-4">
        <div className="form-group align-self-center">
          <CheckField
            fieldLabel="Tiene Rut?"
            fieldName="have_rut"
            fieldValue={formData.have_rut}
            onChangeHandler={onChange}
            isChecked={formData.have_rut}
          />
        </div>
        <div className="form-group">
          <PartnerTypeField
            selectedType={formData.partner_type}
            onChange={onChange}
            required={true}
          />
        </div>
      </div>
      {/* Observations */}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="description"
          fieldValue={formData.description}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
};

export default CustomerFormFields;

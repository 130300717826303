import React from "react";
import { sortAlphabetically } from "../../../../../helpers/sortHelper";

function AltitudeSelect({ selectedAltitude, onChange, required }) {
  const altitudeRanges = ["0 - 1000", "1001 - 2000", "2001 +"];

  const sortedAltitudeRanges = sortAlphabetically(altitudeRanges);

  return (
    <div className="form-group">
      <label className="form-label fw-lighter mt-3">Rango Altitudinal:</label>
      <select
        className="form-select fst-italic"
        name="altitude_range"
        value={selectedAltitude}
        onChange={onChange}
        required={required ? true : false}
      >
        <option value="" disabled></option>
        {sortedAltitudeRanges.map((range, index) => (
          <option key={index} value={range}>
            {range}
          </option>
        ))}
      </select>
    </div>
  );
}

export default AltitudeSelect;

import React, { useState } from "react";
import { CloseIcon } from "../../../helpers/Iconos";
import QualityTestFormFields from "./QualityTestFormFields";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";
import TitleSection from "../../common/forms/fieldSections/TitleSection";

function QualityTestForm({
  onSubmitForm,
  isLoading,
  onCancel,
  seed,
  user,
  quality_test,
}) {
  const isUpdating = quality_test?.id ? true : false;

  const initialFormData = {
    user: user?.id,
    seed: seed,
    test_type: quality_test?.test_type || null,
    observations: quality_test?.observations || null,
    test_creation_date: quality_test?.test_creation_date || null,
    test_evaluation_date: quality_test?.test_evaluation_date || null,
    created_at: quality_test?.created_at || null,
    used_seeds_count: quality_test?.used_seeds_count || null,
    germinated_seed_count: quality_test?.germinated_seed_count || 0,
    non_germinated_seed_count: quality_test?.non_germinated_seed_count || null,
    germination_percentage: quality_test?.germination_percentage || null,
    cleaning_performed: quality_test?.cleaning_performed || null,
    total_impurity_weight_for_seed:
      quality_test?.total_impurity_weight_for_seed || null,
    total_seed_entry_weight: quality_test?.total_seed_entry_weight || null,
    test_sample_weight: quality_test?.test_sample_weight || 0,
    test_purity_sample_weight: quality_test?.test_purity_sample_weight || 0,
    total_impurity_sample_weight:
      quality_test?.total_impurity_sample_weight || null,
    purity_percentage: quality_test?.purity_percentage || 0,
    pathogens_present: quality_test?.pathogens_present || null,
    pathogen_test: quality_test?.pathogen_test || null,
    fungi_present: quality_test?.fungi_present || null,
    bacteria_present: quality_test?.bacteria_present || null,
    insects_present: quality_test?.insects_present || null,
    sanitization_result: quality_test?.sanitization_result || null,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToReturn = {
      ...formData,
      seed: seed?.id,
      purity_percentage: calculatePurityPercentage(),
      total_impurity_sample_weight:
        formData.test_sample_weight - formData.test_purity_sample_weight,
      non_germinated_seed_count:
        formData.used_seeds_count - formData.germinated_seed_count,
      germination_percentage: calculateGerminationPercentage(),
    };
    onSubmitForm(formDataToReturn);
  };

  const calculatePurityPercentage = () => {
    if (formData.test_type === "purity") {
      return (
        (formData.test_purity_sample_weight / formData.test_sample_weight) *
        100
      ).toFixed(2);
    }
    return 0;
  };

  const calculateGerminationPercentage = () => {
    if (["vigor", "germination", "viability"].includes(formData.test_type)) {
      return (formData.germinated_seed_count / formData.used_seeds_count) * 100;
    }
    return 0;
  };

  const handleCancel = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  return (
    <div className="aside-form">
      <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
        <div className="container">
          <TitleSection title="Register Prueba De Calidad" />
        </div>
        <div className="mt-1">
          <ButtonSection
            text={CloseIcon}
            className="btn btn-primary rounded-circle p-2 lh-1"
            onClick={handleCancel}
            type="button"
          />
        </div>
      </div>
      <form className="p-5 pt-0" onSubmit={handleSubmit}>
        <div className="mb-3">
          <QualityTestFormFields
            isUpdating={isUpdating}
            formData={formData}
            onChange={handleInputChange}
          />
        </div>
        {!isUpdating && (
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection text="Registrar" color="green" />
          </div>
        )}
      </form>
    </div>
  );
}

export default QualityTestForm;

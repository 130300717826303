import React from "react";
import ListTable from "../../common/tables/ListTable";
import { formatDateTime } from "../../../helpers/dateHelper";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";

function QualityTestListTable({
  onDelete,
  isLoading,
  onRedirect,
  onOpenRegisterForm,
  qualityTests,
}) {
  const sanitationTests = qualityTests?.filter(
    (test) => test.test_type === "sanitation"
  );
  const purityTests = qualityTests?.filter(
    (test) => test.test_type === "purity"
  );
  const germinationTests = qualityTests?.filter(
    (test) =>
      test.test_type === "germination" ||
      test.test_type === "vigor" ||
      test.test_type === "viability"
  );

  const sanitationColums = [
    {
      selector: (row) => formatDateTime(row?.test_evaluation_date),
      name: "Fecha De Evaluacion",
    },
    {
      selector: (row) => row?.sanitization_result,
      name: "Resultado De Prueba",
    },
    {
      selector: (row) => row?.pathogen_test && "Realizada",
      name: "Prueba De Patogenos",
    },
  ];

  const purityColumns = [
    {
      selector: (row) => formatDateTime(row?.test_evaluation_date),
      name: "Fecha De Evaluacion",
    },
    { selector: (row) => row?.purity_percentage, name: "Porcentaje De Pureza" },
    {
      selector: (row) => row?.cleaning_performed && "Realizada",
      name: "Limpieza Realizada",
    },
  ];

  const germinationVigorViabilityColumns = [
    {
      selector: (row) => formatDateTime(row?.test_evaluation_date),
      name: "Fecha De Evaluacion",
    },
    {
      selector: (row) => row?.germination_percentage,
      name: "Porcentaje De Germinacion",
    },
    { selector: (row) => row?.used_seeds_count, name: "Semillas Probadas" },
  ];

  return (
    <div>
      <div>
        <div className="mt-2 bg-primary bg-gradient text-white px-3 rounded">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="fs-5 fw-light">Pruebas de Sanidad</p>
            </div>
            <div>
              <ButtonSection
                text="Crear Prueba De Sanidad"
                color="yellow"
                onClick={() => onOpenRegisterForm("sanitation")}
                type="submit"
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          {sanitationTests?.length > 0 ? (
            <ListTable
              onDelete={onDelete}
              isLoading={isLoading}
              data={sanitationTests}
              onRedirect={onRedirect}
              columns={sanitationColums}
            />
          ) : (
            <p className="h5 pb-2 mb-2 mt-3 text-danger border-bottom border-danger">
              No hay pruebas de vigor registradas.
            </p>
          )}
        </div>
      </div>
      <br />
      <div>
        <div className="mt-2 bg-primary bg-gradient text-white px-3 rounded">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="fs-5 fw-light">Pruebas de Pureza</p>
            </div>
            <div>
              <ButtonSection
                text="Crear Prueba De Pureza"
                color="yellow"
                onClick={() => onOpenRegisterForm("purity")}
                type="submit"
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          {purityTests?.length > 0 ? (
            <ListTable
              data={purityTests}
              onDelete={onDelete}
              isLoading={isLoading}
              columns={purityColumns}
              onRedirect={onRedirect}
            />
          ) : (
            <p className="h5 pb-2 mb-2 mt-3 text-danger border-bottom border-danger">
              No hay pruebas de pureza registradas.
            </p>
          )}
        </div>
      </div>
      <br />
      <div>
        <div className="mt-2 bg-primary bg-gradient text-white px-3 rounded">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p className="fs-5 fw-light">
                Pruebas de Germinación, Vigor, Viabilidad
              </p>
            </div>
            <div>
              <ButtonSection
                text="Crear Prueba De Germinacion"
                color="yellow"
                onClick={() => onOpenRegisterForm()}
                type="submit"
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          {germinationTests?.length > 0 ? (
            <ListTable
              onDelete={onDelete}
              isLoading={isLoading}
              onRedirect={onRedirect}
              data={germinationTests}
              columns={germinationVigorViabilityColumns}
            />
          ) : (
            <p className="h5 pb-2 mb-2 mt-3 text-danger border-bottom border-danger">
              No hay pruebas de germinación registradas.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QualityTestListTable;

import React from "react";

import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";

import { formatNumber } from "../../../../helpers/formatNumber";
import { formatDateTime } from "../../../../helpers/dateHelper";

function TransactionSaleAside({
  data,
  // onSubmit,
  onDeleteItem,
  openTransactionOverviewModal,
}) {
  const calculateTotalSaleValue = (seeds) => {
    const totalSaleValue = seeds.reduce(
      (suma, obj) =>
        suma +
        obj.selectedPresentation.assigned_sale_value * obj.numberOfSobres,
      0
    );

    return formatNumber(totalSaleValue);
  };

  const columns = [
    { selector: (row) => row.seed_code, name: "Codigo" },
    { selector: (row) => `${row.presentationWeight} g`, name: "Presentacion" },
    { selector: (row) => row.numberOfSobres, name: "Sobres" },
    {
      name: "Total",
      selector: (row) =>
        `${formatNumber(
          row.selectedPresentation.assigned_sale_value * row.numberOfSobres
        )} $`,
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection title="Detalles" />
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Informacion Creador" />
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="fw-lighter">Usuario Espora: </h6>
            <p className="fst-italic">{data?.user?.email}</p>
          </div>
        </div>
      </div>
      <div>
        <SubtitlesSection subTitle="Informacion Del Cliente" />
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <div>
              <h6 className="fw-lighter">Cliente: </h6>
              <p className="fst-italic">{data?.customer?.name}</p>
            </div>
            <div>
              <div>
                <h6 className="fw-lighter">Dirección: </h6>
                <p className="fst-italic">{data?.customer?.address}</p>
              </div>
            </div>
            <div>
              <h6 className="fw-lighter">Fecha de Creación: </h6>
              <p className="fst-italic">
                {formatDateTime(data?.customer?.created_at)}
              </p>
            </div>
          </div>
          <div className="w-50">
            <div>
              <h6 className="fw-lighter">Documento: </h6>
              <p className="fst-italic">{data?.customer?.document}</p>
            </div>
            <div>
              <h6 className="fw-lighter">Email: </h6>
              <p className="fst-italic">{data?.customer?.email}</p>
            </div>
            <div>
              <h6 className="fw-lighter">Teléfono: </h6>
              <p className="fst-italic">{data?.customer?.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SubtitlesSection subTitle="Semillas Seleccionadas" />
      </div>
      <div>
        <ListTable
          data={data.seeds}
          columns={columns}
          onDelete={onDeleteItem}
          emptyMessage="No Hay Semillas Seleccionadas"
        />
      </div>
      <div className="mt-4">
        <h5>
          Subtotal ({data.seeds.length} items): $
          {data.seeds.length > 0 ? calculateTotalSaleValue(data.seeds) : 0}
        </h5>
      </div>
      <hr />
      <div className="d-flex justify-content-center mt-2">
        <div className="mt-2">
          <ButtonSection
            text="Crear Transaccion"
            color="blue"
            // onClick={() => onSubmit(data)}
            onClick={openTransactionOverviewModal}
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export default TransactionSaleAside;

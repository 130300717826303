import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./auth/authSlice";

import seedSlice from "./seeds/seeds/seedSlice";
import guardianReducer from "./partners/guardians/guardianSlice";
import customerReducer from "./partners/customers/customerSlice";
import seedGroupSlice from "./seeds/seedGroup/seedGroupSlice";
import seedSpecieSlice from "./seeds/seedSpecie/seedSpecieSlice";
import qualityTestSlice from "./qualityTests/qualityTestSlice";
import fixedCostSlice from "./costManagement/fixedCost/fixedCostSlice";
import transactionsSlice from "./transactions/transactionsSlice";
import seedPresentationSlice from "./seeds/seedPresentation/seedPresentationSlice";

/**
 * Redux store configuration.
 */
export const store = configureStore({
  /**
   * Reducers define how the application state should change in response to actions.
   * Each reducer corresponds to a slice of the application state.
   * The keys in the `reducer` object represent different slices, and the values are the corresponding reducer functions.
   */
  reducer: {
    /**
     * Auth Reducer: Manages authentication-related state.
     * Handles actions related to user authentication and token management.
     */
    auth: userReducer,

    /**
     * Guardian Reducer: Manages state related to guardians.
     * Handles actions for registering, retrieving, updating, and deleting guardians.
     */
    guardian: guardianReducer,

    /**
     * Customer Reducer: Manages state related to customers.
     * Handles actions for registering, retrieving, updating, and deleting Ccustomers.
     */
    customer: customerReducer,

    /**
     * Seed Reducer: Manages state related to seeds.
     * Handles actions for registering, retrieving, updating, and deleting seeds.
     */
    seed: seedSlice,

    /**
     * SeedGroup Reducer: Manages state related to seedGroups.
     * Handles actions for registering, retrieving, updating, and deleting seedGroups.
     */
    seedGroup: seedGroupSlice,

    /**
     * SeedSpecie Reducer: Manages state related to SeedSpecie.
     * Handles actions for registering, retrieving, updating, and deleting SeedSpecie.
     */
    seedSpecie: seedSpecieSlice,

    /**
     * QualityTest Reducer: Manages state related to QualityTest.
     * Handles actions for registering, retrieving, updating, and deleting QualityTestGroup.
     */
    qualityTest: qualityTestSlice,

    /**
     * FixedCosts Reducer: Manages state related to Fixed Costs.
     * Handles actions for registering, retrieving, updating, and deleting Fixed Costs.
     */
    fixedCost: fixedCostSlice,

    /**
     * transactionsSlice Reducer: Manages state related to transactions.
     * Handles actions for registering, retrieving, updating, and deleting Transactions.
     */
    transactions: transactionsSlice,

    /**
     * FixedCosts Reducer: Manages state related to Seed Presentations.
     * Handles actions for registering, retrieving, updating, and deleting Seed Presentations.
     */
    seedPresentation: seedPresentationSlice,
  },
});

/**
 * The `store` is the Redux store that holds the complete state tree of the application.
 * It is the result of calling `configureStore()` and is passed to the `Provider` component in the application.
 * The `store` allows components to access the global state and dispatch actions to update the state.
 */

import React from "react";

import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

function InfoSection({ title, data }) {
  return (
    <div>
      <h6 className="fw-lighter">{title}</h6>
      <p className="fs-5 fst-italic">{capitalizeFirstLetterOfWords(data)}</p>
    </div>
  );
}

export default InfoSection;

import React, { useState } from "react";
import SeedGroupFields from "./SeedGroupFields";
import ErrorMessages from "../../../common/forms/fieldSections/ErrorMessages";

const SeedGroupForm = ({ onRegisterSeedGroup, seedGroup, errorMessage }) => {
  const initialFormData = {
    seedGroupId: seedGroup?.id,
    seed_group_name: seedGroup?.seed_group_name || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const value = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,

      [e.target.name]: value.toLowerCase(),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onRegisterSeedGroup(formData);
  };

  const isUpdating = Object.keys(seedGroup).length > 0;

  return (
    <div>
      <form className="p-5 d-flex" onSubmit={handleSubmit}>
        <div className="mb-3 w-50">
          <div>
            <SeedGroupFields
              formData={formData}
              onChange={handleChange}
              onRegister={handleSubmit}
              isUpdating={isUpdating}
            />
          </div>
          <div>
            <ErrorMessages errorMessage={errorMessage} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SeedGroupForm;

import React, { useState, useEffect } from "react";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";

function Filter({ onFilter, filters }) {
  const [filterValues, setFilterValues] = useState(
    filters.reduce((acc, filter) => {
      acc[filter.key] = filter.initialValue || "";
      return acc;
    }, {})
  );

  useEffect(() => {}, [filterValues]);

  const handleFilterClick = () => {
    onFilter(filterValues);
  };

  const handleClearClick = () => {
    const clearedFilterValues = filters.reduce((acc, filter) => {
      acc[filter.key] = "";
      return acc;
    }, {});
    setFilterValues(clearedFilterValues);
  };

  return (
    <div className="p-3 mb-2 bg-success bg-gradient text-dark custom-border-large shadow position-relative">
      <div className="d-flex justify-content-between align-items-center">
        {filters.map((filter) => (
          <div key={filter.key}>
            {filter.component({
              selectedValue: filterValues[filter.key],
              onChange: (e) => {
                let value = e.target
                  ? e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value
                  : e.id;

                setFilterValues({
                  ...filterValues,
                  [filter.key]: value,
                });
              },
            })}
          </div>
        ))}
        <div className="d-flex align-items-center mt-5">
          <div>
            <ButtonSection
              text="Limpiar"
              color="blue"
              onClick={handleClearClick}
            />
          </div>
          <div className="ms-1">
            <ButtonSection
              text="Aplicar Filtros"
              color="green"
              onClick={handleFilterClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;

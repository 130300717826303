import React from "react";
import CustomerSelection from "../../../common/forms/fieldSections/CustomerSelection";

const TransactionSaleHeader = ({ formData, handleCustomerChange }) => {
  return (
    <div>
      <CustomerSelection
        selectedOption={formData}
        handleChange={handleCustomerChange}
      />
    </div>
  );
};

export default TransactionSaleHeader;

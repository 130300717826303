import React from "react";

import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "../store/store";
import AuthRoutes from "./AuthRoutes";
import SeedRoutes from "./SeedRoutes";
import CustomerRoutes from "./CustomerRoutes";
import GuardianRoutes from "./GuardianRoutes";
import DashboardRoutes from "./DashboardRoutes";
import SeedGroupRoutes from "./SeedGroupRoutes";
import SeedSpecieRoutes from "./SeedSpecieRoutes";
import Error404 from "../containers/error/Error404";
import QualityTestRoutes from "./QualityTestRoutes";
import FixedCostRoutes from "./FixedCostRoutes";
import SeedPresentationRoutes from "./SeedPresentationRoutes";
import TransactionRoutes from "./TransactionRoutes";
import EsporaUserLoginPage from "../containers/pages/authentication/EsporaUserLoginPage";

const AppRoutes = () => (
  <Provider store={store}>
    <Routes>
      <Route path="/*" element={<DashboardRoutes />} />

      <Route path="*" element={<Error404 />} />

      <Route path="/login" element={<EsporaUserLoginPage />} />

      <Route path="/auth/*" element={<AuthRoutes />} />

      <Route path="/guardians/*" element={<GuardianRoutes />} />

      <Route path="/customers/*" element={<CustomerRoutes />} />

      <Route path="/seeds/*" element={<SeedRoutes />} />

      <Route path="/fixed-costs/*" element={<FixedCostRoutes />} />

      <Route path="/seed-groups/*" element={<SeedGroupRoutes />} />

      <Route path="/seed-species/*" element={<SeedSpecieRoutes />} />

      <Route
        path="/seed-presentations/*"
        element={<SeedPresentationRoutes />}
      />

      <Route path="/qualty-test/*" element={<QualityTestRoutes />} />

      <Route path="/transactions/*" element={<TransactionRoutes />} />
    </Routes>
  </Provider>
);

export default AppRoutes;

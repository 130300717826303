import React from "react";
import InfoSection from "../fieldSections/InfoSection";

const SeedSpecieDetails = ({ selectedSeedSpecie }) => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <InfoSection
          title="Especie De La Semilla"
          data={selectedSeedSpecie?.seed_specie_name}
        />
        <InfoSection
          title="Nombre Botanico"
          data={selectedSeedSpecie?.seed_botanic_name}
        />
        <InfoSection
          title="Semilla Por Gramo"
          data={selectedSeedSpecie?.average_seeds_per_gram}
        />
        <InfoSection
          title="Preservacion"
          data={selectedSeedSpecie?.species_preservation}
        />
        <InfoSection
          title="Mantenimiento"
          data={selectedSeedSpecie?.variety_maintenance}
        />
        <InfoSection
          title="Grupo De Semilla"
          data={selectedSeedSpecie?.seed_group?.seed_group_name}
        />
      </div>
      <hr />
    </div>
  );
};

export default SeedSpecieDetails;

import React from "react";
import { Route, Routes } from "react-router-dom";
import EsporaUsersListTablePage from "../containers/pages/authentication/EsporaUsersListTablePage";
import EsporaUserRegisterPage from "../containers/pages/authentication/EsporaUserRegisterPage";
import SecurityDataEsporaUser from "../containers/pages/authentication/SecurityDataEsporaUser";
import EsporaUserDetailPage from "../containers/pages/authentication/EsporaUserDetailPage";
import PrivateRoute from "../hocs/PrivateRoute";

const AuthRoutes = () => (
  <Routes>
    {/* Route for registering a new Espora user */}
    <Route
      path="register"
      element={
        <PrivateRoute>
          <EsporaUserRegisterPage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing and editing the account details of the authenticated user */}
    <Route
      path="my-account"
      element={
        <PrivateRoute>
          <EsporaUserDetailPage />
        </PrivateRoute>
      }
    />

    {/* Route for managing the security details of the authenticated user */}
    <Route
      path="security-details"
      element={
        <PrivateRoute>
          <SecurityDataEsporaUser />
        </PrivateRoute>
      }
    />

    {/* Route for viewing a list of Espora users (requires authentication) */}
    <Route
      path="espora-users"
      element={
        <PrivateRoute>
          <EsporaUsersListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default AuthRoutes;

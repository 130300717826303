import React, { useState } from "react";
import "../../../../styles/forms.css";
import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";
import CustomerFormFields from "./CustomerFormFields";
import ErrorMessages from "../../../common/forms/fieldSections/ErrorMessages";

const CustomerForm = ({ onCancel, isLoading, errorMessage, onSubmitForm }) => {
  const initialFormData = {
    name: "",
    email: "",
    address: "",
    document: "",
    have_rut: true,
    description: "",
    partner_type: "",
    phone_number: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitForm(formData);
  };

  const handleCancel = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  return (
    <GenericAsideForm
      title="Registrar Comprador"
      onCancel={handleCancel}
      isLoading={isLoading}
      formData={formData}
      errorMessage={errorMessage}
      onSubmitForm={onSubmitForm}
      formFieldsComponent={
        <>
          <CustomerFormFields
            formData={formData}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
          />
          <ErrorMessages error={formData.error} errorMessage={errorMessage} />
        </>
      }
    />
  );
};

export default CustomerForm;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../../store/auth/authSlice";
import EsporaUserDetails from "../../../components/auth/forms/EsporaUserDetails";

function EsporaUserDetailPage() {
  const { isSuccess, isLoading, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isSuccess]);

  const handleEsporaUserUpdate = async (esporaUserData) => {
    if (
      window.confirm("¿Estás seguro de que quieres actualizar tu información?")
    ) {
      const action = await dispatch(updateProfile(esporaUserData));

      if (action.error) {
        alert("Se Produjo Un Error Actualizando El Perfil");
        return;
      }

      alert("Perfil Actualizado");
    }
  };

  return (
    <div className="container custom-border-large shadow mt-5 w-50">
      <EsporaUserDetails
        updateEsporaUser={handleEsporaUserUpdate}
        isLoading={isLoading}
        esporaUser={user}
      />
    </div>
  );
}

export default EsporaUserDetailPage;

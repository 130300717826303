import React from "react";

const DateSelector = ({
  onChangeHandler,
  fieldLabel,
  fieldName,
  fieldValue,
  isReadOnly,
}) => {
  return (
    <div className="input-group">
      <div className="col_one_third c-azul">
        <label className="form-label fw-lighter" htmlFor="nacimiento">
          {fieldLabel}
        </label>
        <input
          className="form-control fst-italic"
          type="date"
          name={fieldName ? fieldName : null}
          value={fieldValue ? fieldValue : ""}
          readOnly={isReadOnly ? true : false}
          onChange={onChangeHandler ? onChangeHandler : null}
        />
      </div>
    </div>
  );
};

export default DateSelector;

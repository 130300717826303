import { createAsyncThunk } from "@reduxjs/toolkit";
import seedService from "../seedApiService";

const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

export const registerSeed = createAsyncThunk(
  "seed/register",
  async (seedData, thunkAPI) => {
    try {
      return await seedService.registerSeed(seedData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getSeed = createAsyncThunk(
  "seed/singleSeed",
  async (seedData, thunkAPI) => {
    try {
      let seed = await seedService.getSeed(seedData);
      return seed;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getSeedsWithoutQualityTests = createAsyncThunk(
  "seed/getSeedsWithoutQualityTests",

  async (thunkAPI) => {
    try {
      return await seedService.getSeedsWithoutQualityTests();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const updateSeed = createAsyncThunk(
  "seed/updateSeed",

  async (seedData, thunkAPI) => {
    try {
      let seed = await seedService.updateSeed(seedData);

      return seed;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getFilteredSeeds = createAsyncThunk(
  "seed/listFilteredSeeds",
  async (dataSearch, thunkAPI) => {
    try {
      return await seedService.getFilteredSeeds(dataSearch);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

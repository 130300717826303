import React from "react";
import { useSelector } from "react-redux";
import ListTable from "../../../common/tables/ListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

function SeedsListTable({ onRedirect }) {
  const { isLoading, seeds } = useSelector((state) => state.seed);
  console.log(seeds);
  const columns = [
    { sortable: true, selector: (row) => row?.seed_code, name: "Codigo" },
    {
      sortable: true,
      selector: (row) =>
        capitalizeFirstLetterOfWords(row?.seed_specie?.seed_specie_name),
      name: "Especie",
    },
    {
      sortable: true,
      selector: (row) => row?.guardian?.altitude_range,
      name: "Rango Altitudinal",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.seed_usage),
      name: "Uso De Semilla",
    },
    {
      sortable: true,
      selector: (row) => `${row?.seed_current_weight} g`,
      name: "Peso Disponible",
    },
    {
      sortable: true,
      selector: (row) => row?.seed_specie?.average_seeds_per_gram,
      name: "Semilla Por Gramo",
    },
  ];

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
            <div className="container">
              <TitleSection title="Stock De Semillas" />
            </div>
          </div>
          {/* <div>
            <SeedFiltersListTable onFilter={onSearch} />
          </div> */}
          <div className="pt-2">
            <ListTable
              pagination
              data={seeds}
              columns={columns}
              isLoading={isLoading}
              onRedirect={onRedirect}
              emptyMessage="No Hay Semillas Disponibles"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SeedsListTable;

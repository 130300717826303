import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateCustomer,
  getCustomer,
} from "../../../../store/partners/customers/thunks/customerThunks";
import { getFilteredTransactions } from "../../../../store/transactions/thunks/transactionsThunks";
import CustomerDetails from "../../../../components/partners/customers/forms/CustomerDetails";

function CustomerDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      Promise.all([
        dispatch(getCustomer(slug)),
        dispatch(getFilteredTransactions(`customer__id=${slug}`)),
      ]);
    };

    fetchData();
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleCustomerUpdate = async (updatedCustomerData) => {
    const updatedCustomerWithSlug = { ...updatedCustomerData, slug };
    const action = await dispatch(updateCustomer(updatedCustomerWithSlug));

    if (action.error) {
      alert("Ocurrio Un Error Actualizando El Cliente");
      return;
    }

    alert("Cliente Actualizado.");
  };

  const handleRedirectToTransactionDetail = async (trasactionData) => {
    navigate(`/transactions/detail/${trasactionData.id}/`);
  };

  return (
    <div className="container">
      <CustomerDetails
        onSubmitForm={handleCustomerUpdate}
        handleRedirectToTransactionDetail={handleRedirectToTransactionDetail}
      />
    </div>
  );
}

export default CustomerDetailPage;

import React from "react";
import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";
import SeedFormFields from "../../../seeds/seeds/forms/SeedFormFields";
import TransactionPurchaseOverview from "../modal/TransactionPurchaseOverview";
import Modal from "../../../common/navigation/Modal";
import ListTable from "../../../common/tables/ListTable";
import SeedRegistrationPage from "../../../../containers/pages/seeds/seeds/SeedRegistrationPage";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import { formatNumber } from "../../../../helpers/formatNumber";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const TransactionPurchaseBody = ({
  formData,
  onDelete,
  isFormOpen,
  onRedirect,
  allSpecies,
  seedToEdit,
  showSeedModal,
  onUpdatedSeed,
  closeSeedModal,
  handleCancelForm,
  handleInputChange,
  handleSeedDataSubmit,
  handleCreateTransaction,
  showTransactionOverviewModal,
  closeTransactionOverviewModal,
}) => {
  const dataSeedToTable = formData["seeds"].map((seed) => ({
    ...seed,
    seed_specie: allSpecies.find((specie) => specie.id === seed.seed_specie),
  }));

  const renderForm = () => (
    <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
      <GenericAsideForm
        title="Modificar Semilla"
        onCancel={handleCancelForm}
        formData={seedToEdit}
        errorMessage="Error Message"
        onSubmitForm={onUpdatedSeed}
        isUpdating={true}
        formFieldsComponent={
          <SeedFormFields
            formData={seedToEdit}
            onChange={handleInputChange}
            filteredGuardians={formData.guardian.name}
            filteredSeedSpecies={allSpecies}
            isUpdating={true}
          />
        }
      />
    </div>
  );

  const columns = [
    {
      selector: (row) =>
        capitalizeFirstLetterOfWords(row.seed_specie.seed_specie_name),
      name: "Especie",
    },
    {
      selector: (row) => `${formatNumber(row.seed_entry_weight)} g`,
      name: "Peso A Ingresar",
    },
    {
      selector: (row) => `${formatNumber(row.seed_cost_per_gram)} $`,
      name: "Costo Por Gramo",
    },
    {
      selector: (row) => `${formatNumber(row.total_cost)} $`,
      name: "Costo Total",
    },
  ];

  return (
    <div>
      {isFormOpen && renderForm()}
      <Modal showModal={showSeedModal} closeModal={closeSeedModal}>
        <SeedRegistrationPage onDataSubmit={handleSeedDataSubmit} />
      </Modal>
      <Modal
        showModal={showTransactionOverviewModal}
        closeModal={closeTransactionOverviewModal}
      >
        <TransactionPurchaseOverview
          formData={formData}
          allSpecies={allSpecies}
          onChange={handleInputChange}
          onCreate={handleCreateTransaction}
        />
      </Modal>
      <hr />
      <div className="container">
        <div>
          <SubtitlesSection subTitle="Semillas Para Agregar" className="mb-4" />
        </div>
        <div>
          <ListTable
            columns={columns}
            onDelete={onDelete}
            data={dataSeedToTable}
            onRedirect={onRedirect}
            emptyMessage="No Hay Semillas Disponibles"
          />
        </div>
        <div className="mt-4">
          <h5>
            Subtotal De Transaccion ({formData.seeds.length} items): $
            {formData.seeds.length > 0
              ? formatNumber(
                  formData.seeds.reduce(
                    (suma, obj) => suma + Number(obj.total_cost),
                    0
                  )
                )
              : 0}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default TransactionPurchaseBody;

import React from "react";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import {
  getAllSeedGroups,
  registerSeedGroup,
  updateSeedGroup,
  deleteSeedGroup,
} from "../../../../store/seeds/seedGroup/thunks/seedGroupThunks";
import SeedGroupsListTable from "../../../../components/seeds/seedGroups/tables/SeedGroupsListTable";

const SeedGroupListTablePage = () => {
  const { reload } = useDataAndReloadPage([getAllSeedGroups], []);

  const { handleOperation } = useOperationHandler({
    register: registerSeedGroup,
    update: updateSeedGroup,
    delete: deleteSeedGroup,
  });

  const handleSeedGroupOperation = async (operation, fixedCostData) => {
    const action = await handleOperation(operation, fixedCostData);
    if (!action.errors) {
      reload();
    }
  };

  return (
    <SeedGroupsListTable onSeedGroupOperation={handleSeedGroupOperation} />
  );
};

export default SeedGroupListTablePage;

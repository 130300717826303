import React from "react";

const AccountSelection = ({ selectedTransactionAccount, onChange }) => {
  const accountOptions = [
    "",
    "cuenta principal",
    "cuanta segundaria",
    "cuenta tercera",
  ];

  return (
    <div className="form-group">
      <label className="form-label fw-lighter">
        Cuenta Asociada A La Transaccion:
      </label>
      <select
        className="form-select form-select-sm fst-italic"
        name="associated_account"
        value={selectedTransactionAccount}
        onChange={onChange}
      >
        {accountOptions.map((status, index) => (
          <option key={index} value={status} className="fst-italic">
            {status}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AccountSelection;

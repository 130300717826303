import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = "http://127.0.0.1:8000/api";

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

const transactionsService = {
  getTransaction: async (id) => {
    const response = await axios.get(
      `${API_BASE_URL}/transactions/details/${id}/`,
      headers
    );

    return response.data;
  },

  registerPurchaseTransaction: async (transactionData) => {
    const url = `${API_BASE_URL}/transactions/purchase/create/`;
    const response = await axios.post(url, transactionData, headers);

    return response.data;
  },

  registerSaleTransaction: async (transactionData) => {
    const url = `${API_BASE_URL}/transactions/sale/create/`;
    const response = await axios.patch(url, transactionData, headers);

    return response.data;
  },

  registerAdjustmentTransaction: async (transactionData) => {
    const url = `${API_BASE_URL}/transactions/adjust/create/`;
    const response = await axios.patch(url, transactionData, headers);

    return response.data;
  },

  updateTransaction: async (transactionData) => {
    const url = `${API_BASE_URL}/transactions/update/${transactionData.slug}/`;
    const response = await axios.patch(url, transactionData, headers);
    return response.data;
  },

  getFilteredTransactions: async (dataSearch) => {
    const url = `${API_BASE_URL}/transactions/list?${dataSearch}`;
    const response = await axios.get(url, headers);

    return response.data;
  },
};

export default transactionsService;

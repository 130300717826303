import React from "react";

/**
 * Component for displaying a loading spinner.
 * @returns {JSX.Element} - JSX element representing the loading spinner.
 */
const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;

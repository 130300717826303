import React from "react";
import SeedSpecieSelection from "../../../common/forms/fieldSections/SeedSpecieSelection";
import TransactionSaleSeedCard from "./TransactionSaleSeedCard";
import TransactionSaleOverview from "../modal/TransactionSaleOverview";
import Modal from "../../../common/navigation/Modal";

function TransactionSaleBody({
  seeds,
  addSeed,
  formData,
  onChange,
  allSpecies,
  handleSeedSpecieChange,
  handleCreateTransaction,
  showTransactionOverviewModal,
  closeTransactionOverviewModal,
}) {
  return (
    <div className="mt-2">
      <div className="p-1 bg-success bg-gradient text-dark custom-border-large shadow position-relative">
        <div className="col-sm-1 col-md-12">
          <SeedSpecieSelection
            selectedOption={formData?.seed_specie}
            handleChange={handleSeedSpecieChange}
          />
        </div>
      </div>
      <br />
      <div className="container">
        {seeds?.map((seed) => (
          <div key={seed.id}>
            <TransactionSaleSeedCard seed={seed} onAddSeed={addSeed} />
          </div>
        ))}
      </div>
      <Modal
        showModal={showTransactionOverviewModal}
        closeModal={closeTransactionOverviewModal}
      >
        <TransactionSaleOverview
          formData={formData}
          onChange={onChange}
          allSpecies={allSpecies}
          onCreate={handleCreateTransaction}
        />
      </Modal>
    </div>
  );
}

export default TransactionSaleBody;

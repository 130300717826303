import React from "react";
import TextField from "../../common/forms/fields/textFields/TextField";
import EmailField from "../../common/forms/fields/textFields/EmailField";
import PhoneField from "../../common/forms/fields/numberFields/PhoneField";
import TextAreaField from "../../common/forms/fields/textFields/TextAreaField";

const EsporaUserDetailFields = ({ formData, onChange }) => {
  return (
    <div>
      <div className="user-detail-form-group mt-4">
        <div className="form-group">
          <TextField
            fieldLabel="Nombre"
            fieldName="name"
            fieldValue={formData.username}
            isReadOnly={true}
          />
        </div>
        <div className="form-group mt-3">
          <EmailField
            fieldLabel="Email"
            fieldName="email"
            fieldValue={formData.email}
            isReadOnly={true}
          />
        </div>
        <div className="form-group mt-3">
          <PhoneField
            fieldLabel="Número Telefónico"
            fieldName="phone_number"
            fieldValue={formData.phone_number}
            onChangeHandler={onChange}
          />
        </div>
        <div className="input-group mt-3">
          <TextAreaField
            fieldLabel="Acerca De Mí"
            fieldName="about_me"
            fieldValue={formData.about_me}
            onChangeHandler={onChange}
          />
        </div>
        <div className="form-group mt-3">
          <TextField
            fieldLabel="Licencia"
            fieldName="license"
            fieldValue={formData.license}
            onChangeHandler={onChange}
          />
        </div>
        <div className="form-group mt-3">
          <TextField
            fieldLabel="Dirección"
            fieldName="address"
            fieldValue={formData.address}
            onChangeHandler={onChange}
          />
        </div>
        {/* <div className="form-group mt-3">
          <DateTimeFieldsSection
            createdAt={formData.created_at}
            updatedAt={formData.updated_at}
          />
        </div> */}
      </div>
    </div>
  );
};

export default EsporaUserDetailFields;

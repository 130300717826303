import React, { useState } from "react";
import "../../../../styles/forms.css";
import { useNavigate } from "react-router-dom";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import {
  getFilteredCustomers,
  registerCustomer,
  deleteCustomer,
} from "../../../../store/partners/customers/thunks/customerThunks";
import CustomersListTable from "../../../../components/partners/customers/tables/CustomersListTable";

function CustomersListTablePage() {
  const [searchTerm, setSearchTerm] = useState({ term: "", page: "1" });

  const navigate = useNavigate();

  const { reload } = useDataAndReloadPage(
    [{ func: getFilteredCustomers, param: searchTerm }],
    [searchTerm]
  );

  const { handleOperation } = useOperationHandler({
    register: registerCustomer,
    delete: deleteCustomer,
  });

  const handleCustomerOperation = async (operation, fixedCostData) => {
    await handleOperation(operation, fixedCostData);

    reload();
  };

  const handleSearch = (term) => {
    const newTerm = Object.entries(term)
      .map(([key, value]) => (value ? `${key}=${value}` : ""))
      .filter(Boolean)
      .join("&");
    const updatedSearchTerm = { ...searchTerm, term: newTerm, page: "1" };
    setSearchTerm(updatedSearchTerm);
  };

  const handleRedirectToDetail = async (customerId) => {
    navigate(`/customers/detail/${customerId}`);
  };

  return (
    <div className="container custom-border-large">
      <div className="mt-4">
        <CustomersListTable
          onSearch={handleSearch}
          onRedirect={handleRedirectToDetail}
          onSubmitOperation={handleCustomerOperation}
        />
      </div>
    </div>
  );
}

export default CustomersListTablePage;

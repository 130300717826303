import React from "react";
import { useSelector } from "react-redux";

import ListTable from "../../common/tables/ListTable";
import { formatDateTime } from "../../../helpers/dateHelper";
import { formatNumber } from "../../../helpers/formatNumber";
import TitleSection from "../../common/forms/fieldSections/TitleSection";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";

const TransactionsListTable = ({ onRedirect }) => {
  const { transactions, isLoading } = useSelector(
    (state) => state.transactions
  );

  const columnsForTable = [
    {
      sortable: true,
      selector: (row) => row.transaction_number,
      name: "Numero",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.transaction_type),
      name: "Tipo",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.transaction_status),
      name: "Status",
    },
    {
      sortable: true,
      selector: (row) => `${formatNumber(row.total_value)} $`,
      name: "Valor",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row.created_at),
      name: "Fecha De Creacion",
    },
  ];

  return (
    <div className="container">
      <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
        <div className="container">
          <TitleSection title="Lista De Transacciones" />
        </div>
        {/* <div className="w-25 d-flex justify-content-end">
          <ButtonSection
            text={"Crear Nuevo Guardian"}
            color={"yellow"}
            onClick={handleToggleForm}
          />
        </div> */}
      </div>
      <div className="pt-2">
        <ListTable
          pagination
          data={transactions}
          isLoading={isLoading}
          onRedirect={onRedirect}
          columns={columnsForTable}
          emptyMessage="No Transacciones Disponibles"
        />
      </div>
    </div>
  );
};

export default TransactionsListTable;

import React from "react";
import TextField from "../../../common/forms/fields/textFields/TextField";
import NumberField from "../../../common/forms/fields/numberFields/NumberFields";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import DateTimeFieldsSection from "../../../common/forms/fieldSections/DateTimeFieldsSection";

function FixedCostFields({ formData, onChange, isUpdating }) {
  return (
    <div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Gasto"
          fieldName="expense_name"
          fieldValue={formData.expense_name}
          onChangeHandler={onChange}
        />
      </div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Valor Del Gasto"
          fieldName="expense_value"
          fieldValue={formData.expense_value}
          onChangeHandler={onChange}
        />
      </div>
      <div className="form-group d-flex justify-content-evenly mt-4">
        <div className="form-group">
          <NumberField
            fieldLabel="Cantidad De Sobres"
            fieldName="division_factor"
            fieldValue={formData.division_factor}
            onChangeHandler={onChange}
          />
        </div>

        <div className="form-group ms-2">
          <NumberField
            fieldLabel="Costo Por Sobre"
            fieldName="division_factor"
            fieldValue={formData.expense_value / formData.division_factor}
            onChangeHandler={onChange}
            isReadOnly={true}
          />
        </div>
      </div>
      {isUpdating && (
        <div>
          <DateTimeFieldsSection
            created_at={formData.created_at}
            updated_at={formData.updated_at}
          />
        </div>
      )}
      {/* Observations */}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Descripcion"
          fieldName="description"
          fieldValue={formData.description}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
}

export default FixedCostFields;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EsporaUserForm from "../../../components/auth/forms/EsporaUserForm";
import { register } from "../../../store/auth/authSlice";

const EsporaUserRegisterPage = () => {
  const [isEsporaUserCreated, setIsEsporaUserCreated] = useState(false);

  const { isLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEsporaUserCreated) {
      navigate("/auth/espora-users");
    }
  }, [isEsporaUserCreated, navigate]);

  const handleEsporaUserRegistration = async (esporaUserData) => {
    const action = await dispatch(register(esporaUserData));

    if (action.error) {
      alert("Hubo Un Problema Registrando El Usuario");
      return;
    }

    alert("Espora user registrado exitosamente.");
    setIsEsporaUserCreated(true);
  };

  return (
    <div className="container">
      <EsporaUserForm
        onSubmitForm={handleEsporaUserRegistration}
        isLoading={isLoading}
      />
    </div>
  );
};

export default EsporaUserRegisterPage;

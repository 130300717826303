import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getEsporaUsers,
  deleteEsporaUser,
} from "../../../store/auth/authSlice";
import useDataAndReloadPage from "../../../helpers/hooks/useDataAndReloadPage";
import EsporaUsersListTable from "../../../components/auth/table/EsporaUsersListTable";
import ButtonSection from "../../../components/common/forms/fieldSections/ButtonSection";
import TitleSection from "../../../components/common/forms/fieldSections/TitleSection";

function EsporaUsersListTablePage() {
  const { users, errorMessage, isLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { reload } = useDataAndReloadPage(
    [{ func: getEsporaUsers }],
    [errorMessage]
  );

  const handleDeleteUser = async (user) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const action = await dispatch(deleteEsporaUser(user["id"]));

      if (action.error) {
        alert("Hubo Un Problema Eliminando El Usuario");
        return;
      }

      alert("User deleted");
      reload();
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
        <div className="container">
          <TitleSection title="Usuarios Espora" />
        </div>
        <div className="w-25 d-flex justify-content-end">
          <ButtonSection
            text="Registrar Usuario"
            color="yellow"
            onClick={() => navigate("/auth/register")}
          />
        </div>
      </div>
      <div>
        <EsporaUsersListTable
          onDelete={handleDeleteUser}
          users={users}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default EsporaUsersListTablePage;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateGuardian,
  getGuardian,
} from "../../../../store/partners/guardians/thunks/guardianThunks";
import { getSeed } from "../../../../store/seeds/seeds/thunks/seedThunks";
import { getFilteredTransactions } from "../../../../store/transactions/thunks/transactionsThunks";
import { getFilteredSeeds } from "../../../../store/seeds/seeds/thunks/seedThunks";
import GuardianDetails from "../../../../components/partners/guardians/forms/GuardianDetails";

const GuardianDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getFilteredTransactions(`guardian__id=${slug}`)),
        dispatch(
          getFilteredSeeds({ term: `guardian_id=${slug}&current_weight=1` })
        ),
        dispatch(getGuardian(slug)),
      ]);
    };

    fetchData();
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleGuardianUpdate = async (updatedGuardianData) => {
    const updatedGuardianWithSlug = { ...updatedGuardianData, slug };
    const action = await dispatch(updateGuardian(updatedGuardianWithSlug));

    if (action.error) {
      alert("Ocurrio Un Error Actualizando El Guardian");
      return;
    }

    alert("Guardian Actualizado.");
  };

  const handleRedirectToSeedDetail = async (seedData) => {
    const action = await dispatch(getSeed(seedData.id));

    if (action?.errors) {
      alert("Algo Salio Mal");
      return;
    }

    navigate(`/seeds/detail/${seedData.id}`);
  };

  const handleRedirectToTransactionDetail = async (trasactionData) => {
    navigate(`/transactions/detail/${trasactionData.id}/`);
  };

  return (
    <div className="container">
      <GuardianDetails
        onSubmitForm={handleGuardianUpdate}
        handleRedirectToSeedDetail={handleRedirectToSeedDetail}
        handleRedirectToTransactionDetail={handleRedirectToTransactionDetail}
      />
    </div>
  );
};

export default GuardianDetailPage;

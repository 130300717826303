import React from "react";

/**
 * Component to render a styled button with customizable color and text.
 * @param {Object} props - The component properties.
 * @param {string} props.text - The text to be displayed on the button.
 * @param {string} props.color - The color of the button (e.g., "green", "blue").
 * @param {Function} props.onClick - Function to handle button click events.
 * @param {string} props.className - Additional CSS class for styling.
 * @returns {JSX.Element} - JSX element representing the component.
 */
function ButtonSection({ text, color, onClick, className, type }) {
  // Define color classes for different button colors
  const colorClasses = {
    green: "btn btn-success",
    grey: "btn btn-secondary",
    blue: "btn btn-primary",
    red: "btn btn-danger",
    yellow: "btn btn-warning",
    lightblue: "btn btn-info",
    default: "btn btn-light",
  };

  // Get the appropriate color class or use the default if not found
  const buttonClass = colorClasses[color] || colorClasses.default;

  return (
    <div>
      <button
        className={`${
          className
            ? className
            : `${buttonClass} rounded-pill custom-border-small shadow`
        }`}
        type={type ? type : "submit"}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
}

export default ButtonSection;

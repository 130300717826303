import React from "react";
import AltitudeSelect from "../../../../common/forms/fields/selectionFields/AltitudeSelect";
import ClimateSelect from "../../../../common/forms/fields/selectionFields/ClimateSelect";
import FiltersListTable from "../../../../common/tables/filters/filtersListTable";
import TextField from "../../../../common/forms/fields/textFields/TextField";
import CheckField from "../../../../common/forms/fields/selectionFields/CheckField";

function GuardianFiltersListTable({ onFilter }) {
  const filters = [
    {
      key: "have_rut",
      component: ({ selectedValue, onChange }) => (
        <CheckField
          fieldLabel="Cuenta con RUT"
          fieldName="have_rut"
          onChangeHandler={(e) => onChange(e)}
          isChecked={selectedValue}
          required
        />
      ),
      initialValue: false,
    },
    {
      key: "altitude_range",
      component: AltitudeSelect,
      initialValue: "",
    },
    {
      key: "climate",
      component: ClimateSelect,
      initialValue: "",
    },
    {
      key: "name",
      component: ({ selectedValue, onChange }) => (
        <TextField
          fieldKey="name"
          fieldName="name"
          fieldValue={selectedValue}
          fieldLabel="Buscar Por Nombre"
          onChangeHandler={(e) => onChange(e)}
        />
      ),
      initialValue: "",
    },
  ];

  const handleOnFilter = (values) => {
    onFilter(values);
  };

  return (
    <div>
      <FiltersListTable onFilter={handleOnFilter} filters={filters} />
    </div>
  );
}

export default GuardianFiltersListTable;

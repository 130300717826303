import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TransactionDetailFields from "./TransactionDetailFields";
import TransactionBodyListTable from "../tables/TransactionBodyListTable";
import BackButton from "../../../components/common/navigation/buttons/BackButton";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";
import TitleSection from "../../common/forms/fieldSections/TitleSection";
import TransactionStatusSelection from "../../common/forms/fieldSections/TransactionStatusSelection";
import { formatNumber } from "../../../helpers/formatNumber";

const TransactionDetails = ({ onSubmitForm }) => {
  const { user } = useSelector((state) => state.auth);
  const { transaction, isLoading } = useSelector((state) => state.transactions);

  const initialFormData = {
    created_by: transaction?.created_by,
    modified_by: transaction?.modified_by,
    customer: transaction?.customer,
    delivery_date: transaction?.delivery_date,
    delivery_status: transaction?.delivery_status,
    guardian: transaction?.guardian,
    payment_date: transaction?.payment_date,
    payment_status: transaction?.payment_status,
    total_value: transaction?.total_value,
    transaction_status: transaction?.transaction_status,
    associated_account: transaction?.associated_account,
    transaction_type: transaction?.transaction_type,
    observations: transaction?.observations,
    created_at: transaction?.created_at,
    updated_at: transaction?.updated_at,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [transactionStatus, setTransactionStatus] = useState([]);

  useEffect(() => {
    getTransactionStatusOptions();
    setFormData(initialFormData);
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, transaction]);

  const handleInputChange = (e) => {
    let value;
    let additionalUpdates = {};

    if (e.target.name === "delivery_date") {
      value = e.target.value.toLowerCase();
      additionalUpdates = { delivery_status: "realizado" };
    } else if (e.target.name === "payment_date") {
      value = e.target.value.toLowerCase();
      additionalUpdates = { payment_status: "realizado" };
    } else {
      value =
        e.target.type === "number"
          ? parseFloat(e.target.value)
          : e.target.value.toLowerCase();
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
      ...additionalUpdates, // Agrega las actualizaciones adicionales si las hay
    }));
  };

  const getTransactionStatusOptions = () => {
    const result = ["abierto"];

    if (
      (formData?.delivery_status === "realizado" &&
        formData?.payment_status === "realizado" &&
        formData?.associated_account !== "") ||
      formData?.transaction_type === "ajuste"
    ) {
      result.push("cerrado");
    }

    setTransactionStatus(result);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
      modified_by: user.id,
      created_by: formData?.created_by.id,
      guardian: formData?.guardian?.id,
      customer: formData?.customer?.id,
    };

    onSubmitForm(formDataToSend);
  };

  return (
    <div className="container custom-border-large shadow mt-5">
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection
            title={`Transacccion ${transaction?.transaction_number}`}
          />
        </div>
        <div>
          <BackButton />
        </div>
      </div>

      <form className="p-5 pt-0" onSubmit={onSubmit}>
        <div className="d-flex justify-content-center">
          <ButtonSection text="Actualizar" color="green" />
        </div>
        <div className="w-25">
          <TransactionStatusSelection
            selectedTransactionStatus={formData?.transaction_status}
            onChange={handleInputChange}
            transactionStatus={transactionStatus}
          />
        </div>
        <div>
          <TransactionDetailFields
            formData={formData}
            onChange={handleInputChange}
          />
        </div>
      </form>
      <hr />
      <div className="p-5 pt-0">
        <TransactionBodyListTable
          transactions={transaction?.transaction_bodies}
          isLoading={isLoading}
        />
      </div>
      <hr />
      <div className="p-5">
        <h5>
          Subtotal De Transaccion ({transaction?.transaction_bodies?.length}{" "}
          items): $
          {transaction?.transaction_bodies?.length > 0
            ? formatNumber(
                transaction?.transaction_bodies?.reduce(
                  (suma, obj) => suma + Number(obj.total_value),
                  0
                )
              )
            : 0}
        </h5>
      </div>
    </div>
  );
};

export default TransactionDetails;

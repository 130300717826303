import React from "react";

import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import InfoSection from "../../../common/forms/fieldSections/InfoSection";
import ListTable from "../../../common/tables/ListTable";
import { formatNumber } from "../../../../helpers/formatNumber";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";

function TransactionPurchaseOverview({
  formData,
  onCreate,
  onChange,
  allSpecies,
}) {
  const dataSeedToTable = formData["seeds"].map((seed) => ({
    ...seed,
    seed_specie: allSpecies.find((specie) => specie.id === seed.seed_specie),
  }));

  const columns = [
    {
      selector: (row) =>
        capitalizeFirstLetterOfWords(row.seed_specie.seed_specie_name),
      name: "Especie",
    },
    {
      selector: (row) => `${formatNumber(row.seed_entry_weight)} g`,
      name: "Peso A Ingresar",
    },
    {
      selector: (row) => `${formatNumber(row.seed_cost_per_gram)} $`,
      name: "Costo Por Gramo",
    },
    {
      selector: (row) => `${formatNumber(row.total_cost)} $`,
      name: "Costo Total",
    },
  ];
  return (
    <div className="container custom-border-medium shadow">
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection title="Detalles de la Compra" />
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Informacion Del Usuario" />
        <div className="d-flex justify-content-between">
          <div>
            <InfoSection title="Usuario Espora:" data={formData?.user?.email} />
          </div>
        </div>
      </div>
      <div>
        <SubtitlesSection subTitle="Informacion Del Guardian" />
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <InfoSection
                title="Guardian:"
                data={capitalizeFirstLetterOfWords(formData?.guardian?.name)}
              />
            </div>
            <div>
              <InfoSection title="Vereda:" data={formData?.guardian?.vereda} />
            </div>
            <div>
              <InfoSection
                title="Municipio:"
                data={formData?.guardian?.municipality}
              />
            </div>
          </div>
          <div>
            <div>
              <InfoSection
                title="Documento:"
                data={formData.guardian.document}
              />
            </div>
            <div>
              <InfoSection title="Email:" data={formData.guardian.email} />
            </div>
            <div>
              <InfoSection
                title="Número de Teléfono:"
                data={formData.guardian.phone_number}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Detalles de las Semillas" />
        <div>
          <ListTable
            data={dataSeedToTable}
            columns={columns}
            emptyMessage="No Hay Semillas Seleccionadas"
          />
        </div>
        <div className="mt-4">
          <h5>
            Subtotal De Transaccion ({formData.seeds.length} items): $
            {formData.seeds.length > 0
              ? formatNumber(
                  formData.seeds.reduce(
                    (suma, obj) => suma + Number(obj.total_cost),
                    0
                  )
                )
              : 0}
          </h5>
        </div>
      </div>
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="observations"
          fieldValue={formData?.observations}
          onChangeHandler={onChange}
        />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <ButtonSection
          text="Crear Transaccion"
          color="green"
          onClick={onCreate}
        />
      </div>
    </div>
  );
}

export default TransactionPurchaseOverview;

import React from "react";
import "../../../../styles/forms.css";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import {
  getAllSeedSpecies,
  registerSeedSpecie,
  updateSeedSpecie,
  deleteSeedSpecie,
} from "../../../../store/seeds/seedSpecie/thunks/seedSpecieThunks";
import { getAllSeedGroups } from "../../../../store/seeds/seedGroup/thunks/seedGroupThunks";
import SeedSpeciesListTable from "../../../../components/seeds/seedSpecies/tables/SeedSpeciesListTable";

const SeedSpecieListTablePage = () => {
  const { reload } = useDataAndReloadPage(
    [getAllSeedSpecies, getAllSeedGroups],
    []
  );

  const { handleOperation } = useOperationHandler({
    register: registerSeedSpecie,
    update: updateSeedSpecie,
    delete: deleteSeedSpecie,
  });

  const handleSeedSpecieOperation = async (operation, fixedCostData) => {
    await handleOperation(operation, fixedCostData);

    reload();
  };

  return (
    <div className="custom-border-large">
      <SeedSpeciesListTable onSeedSpecieOperation={handleSeedSpecieOperation} />
    </div>
  );
};

export default SeedSpecieListTablePage;

import React, { useState } from "react";

const PasswordField = ({
  fieldLabel,
  fieldKey,
  fieldValue,
  fieldName,
  isReadOnly,
  onChangeHandler,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div key={fieldKey ? fieldKey : null}>
      <div className="form-group position-relative">
        <label className="form-label mt-2">{fieldLabel}: </label>
        <input
          className="form-control"
          type={showPassword ? "text" : "password"}
          placeholder="Password*"
          name={fieldName ? fieldName : null}
          value={fieldValue ? fieldValue : ""}
          readOnly={isReadOnly ? true : false}
          onChange={onChangeHandler ? onChangeHandler : null}
          minLength="6"
          required
        />
      </div>
      <button
        type="button"
        className="toggle-password btn btn-link mt-3"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? "Hide" : "Show"}
      </button>
    </div>
  );
};

export default PasswordField;

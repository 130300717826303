import { createSlice } from "@reduxjs/toolkit";
import * as seedPresentationThunks from "./thunks/seedPresentationThunks";

// Initial state of the seed slice
const initialState = {
  seedPresentations: [],
  seedpresentation: {},
  count: null,
  next: null,
  previous: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

/**
 * Redux slice for handling seed-related actions.
 */
const seedPresentationSlice = createSlice({
  name: "seedPresentation",
  initialState,
  reducers: {
    /**
     * Reset the seed slice to its initial state.
     * @param {Object} state - Current state of the seed slice.
     */
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Thunks related to seed actions
      .addCase(
        seedPresentationThunks.registerSeedPresentation.pending,
        (state) => {
          state.isLoading = true;
          state.errorMessage = "";
          state.isSuccess = false;
        }
      )
      .addCase(
        seedPresentationThunks.registerSeedPresentation.fulfilled,
        (state) => {
          state.isLoading = false;
          state.isSuccess = true;
        }
      )
      .addCase(
        seedPresentationThunks.registerSeedPresentation.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(
        seedPresentationThunks.getAllSeedPresentations.pending,
        (state) => {
          state.isLoading = true;
          state.errorMessage = "";
          state.isSuccess = false;
          state.isError = false;
        }
      )
      .addCase(
        seedPresentationThunks.getAllSeedPresentations.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.seedPresentations = action.payload;
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
        }
      )
      .addCase(
        seedPresentationThunks.getAllSeedPresentations.rejected,
        (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.errorMessage = action.payload;
        }
      )
      .addCase(
        seedPresentationThunks.updateSeedPresentation.pending,
        (state) => {
          state.isLoading = true;
          state.errorMessage = "";
        }
      )
      .addCase(
        seedPresentationThunks.updateSeedPresentation.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.seed = action.payload;
          state.isSuccess = true;
        }
      )
      .addCase(
        seedPresentationThunks.updateSeedPresentation.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.errorMessage = action.payload;
        }
      )
      .addCase(
        seedPresentationThunks.deleteSeedPresentation.pending,
        (state) => {
          state.isLoading = true;
          state.hasError = false;
        }
      )
      .addCase(
        seedPresentationThunks.deleteSeedPresentation.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
        }
      )
      .addCase(
        seedPresentationThunks.deleteSeedPresentation.rejected,
        (state, action) => {
          state.isLoading = false;
          state.hasError = true;
          state.errorMessage = action.payload;
        }
      );
  },
});

// Export the actions from the reducers
export const { reset } = seedPresentationSlice.actions;

// Export the reducer
export default seedPresentationSlice.reducer;

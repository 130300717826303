import { createAsyncThunk } from "@reduxjs/toolkit";
import transactionsService from "../transactionsApiService";

const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

export const getTransaction = createAsyncThunk(
  "transactions/getTransaction",
  async (transactionId, thunkAPI) => {
    try {
      let transaction = await transactionsService.getTransaction(transactionId);
      return transaction;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const registerPurchaseTransaction = createAsyncThunk(
  "transactions/registerPurchaseTransaction",
  async (transactionData, thunkAPI) => {
    try {
      return await transactionsService.registerPurchaseTransaction(
        transactionData
      );
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const registerSaleTransaction = createAsyncThunk(
  "transactions/registerSaleTransaction",
  async (transactionData, thunkAPI) => {
    try {
      return await transactionsService.registerSaleTransaction(transactionData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const registerAdjustmentTransaction = createAsyncThunk(
  "transactions/registerAdjustmentTransaction",
  async (transactionData, thunkAPI) => {
    try {
      return await transactionsService.registerAdjustmentTransaction(
        transactionData
      );
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const updateTransaction = createAsyncThunk(
  "transactions/updateTransaction",
  async (transactionData, thunkAPI) => {
    try {
      let transactionUpdated = await transactionsService.updateTransaction(
        transactionData
      );

      return transactionUpdated;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getFilteredTransactions = createAsyncThunk(
  "transactions/getFilteredTransactions",
  async (dataSearch, thunkAPI) => {
    try {
      return await transactionsService.getFilteredTransactions(dataSearch);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

import axios from "axios";
import Cookies from "js-cookie";

// Define API base URL
const API_BASE_URL = "http://127.0.0.1:8000/api";

// Centralized headers for API requests
const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

/**
 * QualityTest API Service for handling QualityTest-related operations.
 */
const qualityTestService = {
  /**
   * Registers a new QualityTest.
   * @param {Object} qualityTestData - Data for registering a new QualityTest.
   * @returns {Promise} A promise that resolves to the registered qualityTest data.
   */
  registerQualityTest: async (qualityTestData) => {
    const response = await axios.post(
      `${API_BASE_URL}/qualitytests/create/`,
      qualityTestData,
      headers
    );

    return response.data;
  },

  /**
   * Retrieves data for a specific QualityTest by slug.
   * @param {string} qualityTestSlug - The unique identifier (slug) of the QualityTest.
   * @returns {Promise} A promise that resolves to the QualityTest's data.
   */
  getQualityTest: async (qualityTestSlug) => {
    const response = await axios.get(
      `${API_BASE_URL}/qualitytests/details/${qualityTestSlug}/`,
      headers
    );
    return response.data;
  },

  /**
   * Retrieves a filtered list of QualityTests with pagination.
   * @param {Object} dataSearch - Filters and page number for the search.
   * @returns {Promise} A promise that resolves to the filtered list of QualityTests.
   */
  getFilteredQualityTests: async (dataSearch) => {
    const url = `${API_BASE_URL}/qualitytests/list?${dataSearch.term}&p=${dataSearch.page}`;
    const response = await axios.get(url, headers);

    return response.data;
  },

  //   /**
  //    * Updates the data of an existing QualityTest.
  //    * @param {Object} qualityTestData - Updated data for the QualityTest.
  //    * @returns {Promise} A promise that resolves to the updated QualityTest data.
  //    */
  //   updateQualityTest: async (qualityTestData) => {
  //     const url = `${API_BASE_URL}/qualitytests/update/${qualityTestData.slug}/`;
  //     const response = await axios.patch(url, qualityTestData, headers);
  //     return response.data;
  //   },
};

export default qualityTestService;

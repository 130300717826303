import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import QualityTestCreatePage from "../containers/pages/qualityTests/QualityTestCreatePage";

/**
 * Defines the routes related to semillas within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain semilla-related routes.
 * @returns {JSX.Element} The rendered semilla routes.
 */
const QualityTestRoutes = () => (
  <Routes>
    {/* Route for creating a new semilla (requires authentication) */}
    <Route
      path="create/:slug"
      element={
        <PrivateRoute>
          <QualityTestCreatePage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing details of a specific guardian (requires authentication) */}
    {/* <Route
      path="detail/:slug"
      element={
        <PrivateRoute>
          <SeedDetailsPage />
        </PrivateRoute>
      }
    /> */}

    {/* Route for viewing a list of semillas (requires authentication) */}
    {/* <Route
      path="list"
      element={
        <PrivateRoute>
          <SeedListTablePage />
        </PrivateRoute>
      }
    /> */}
  </Routes>
);

export default QualityTestRoutes;

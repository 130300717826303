import React, { useEffect } from "react";
import "../../../styles/forms.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getFilteredTransactions } from "../../../store/transactions/thunks/transactionsThunks";

import TransactionsListTable from "../../../components/transactions/tables/TransactionsListTable";
import LoadingSpinner from "../../../components/common/forms/fieldSections/LoadingSpinner";

function TransactionsListTablePage() {
  const { isLoading } = useSelector((state) => state.transactions);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFilteredTransactions());
    // to do: fix this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectToDetail = async (selectedTransaction) => {
    navigate(`/transactions/detail/${selectedTransaction.id}`);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container custom-border-large">
          <div className="mt-4">
            <TransactionsListTable onRedirect={handleRedirectToDetail} />
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionsListTablePage;

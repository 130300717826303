import React from "react";
import GuardianFormFields from "./GuardianFormFields";
import DateTimeFieldsSection from "../../../common/forms/fieldSections/DateTimeFieldsSection";

const GuardianDetailFields = ({ formData, onChange }) => {
  return (
    <div>
      <GuardianFormFields formData={formData} onChange={onChange} />

      <div className="mt-4">
        <DateTimeFieldsSection
          created_at={formData.created_at}
          updated_at={formData.updated_at}
        />
      </div>
    </div>
  );
};

export default GuardianDetailFields;

import React from "react";

const TextAreaField = ({
  fieldLabel,
  fieldKey,
  fieldValue,
  fieldName,
  isReadOnly,
  onChangeHandler,
}) => {
  return (
    <div className="input-group mt-4" key={fieldKey ? fieldKey : null}>
      <label className="input-group-text fw-lighter">
        {fieldLabel.toUpperCase()}:{" "}
      </label>
      <textarea
        className="form-control fst-italic"
        name={fieldName ? fieldName : null}
        value={fieldValue ? fieldValue : ""}
        readOnly={isReadOnly ? true : false}
        onChange={onChangeHandler ? onChangeHandler : null}
      />
    </div>
  );
};

export default TextAreaField;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ListTable from "../../common/tables/ListTable";
import { formatDateTime } from "../../../helpers/dateHelper";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";
import { getCustomersOwing } from "../../../store/partners/customers/thunks/customerThunks";

const CustomersOwingTable = () => {
  const { customers } = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCustomersOwing());
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (customerData) => {
    navigate(`/customers/detail/${customerData.id}`);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.name),
      name: "Nombre",
    },
    {
      sortable: true,
      selector: (row) => row.transaction_number,
      name: "Numero De Transaccion",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row.transaction_created_date),
      name: "Fecha Cracion Transaccion",
    },
    {
      sortable: true,
      selector: (row) => `${row.total_transaction_value} $`,
      name: "Valor De Transaccion",
    },
  ];
  return (
    <div>
      <ListTable
        pagination
        data={customers}
        columns={columns}
        onRedirect={handleRedirect}
        keyField="transaction_number"
        emptyMessage="No Tienes Deudas De Ningun Cliente"
      />
    </div>
  );
};

export default CustomersOwingTable;

import React from "react";

import SeedForm from "../../../../components/seeds/seeds/forms/SeedForm";
import useUUID from "../../../../helpers/hooks/useUUID";

function SeedRegistrationPage({ onDataSubmit }) {
  const { generateUUID } = useUUID();

  const handleSeedRegistration = (seedData) => {
    onDataSubmit({ ...seedData, id: generateUUID() });
  };

  return (
    <div>
      <SeedForm onSubmitForm={handleSeedRegistration} />
    </div>
  );
}

export default SeedRegistrationPage;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useDataAndReloadPage = (getDataFunctions, dependencies = []) => {
  const dispatch = useDispatch();
  const [reloadPage, setReloadPage] = useState(0);

  useEffect(() => {
    getDataFunctions.forEach((getDataFunction) => {
      if (typeof getDataFunction === "function") {
        dispatch(getDataFunction());
      } else if (
        typeof getDataFunction === "object" &&
        getDataFunction.hasOwnProperty("func")
      ) {
        const { func, param } = getDataFunction;
        dispatch(func(param));
      }
    });
    setReloadPage(0);
  // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPage, ...dependencies]);

  const reload = () => {
    setReloadPage((prevReload) => prevReload + 1);
  };

  return { reload };
};

export default useDataAndReloadPage;

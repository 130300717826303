import React from "react";
import DataTable from "react-data-table-component";
import ListTable from "../../common/tables/ListTable";
import { formatNumber } from "../../../helpers/formatNumber";
import { formatDateTime } from "../../../helpers/dateHelper";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";

const SecondaryTransactionListTable = ({
  isLoading,
  onRedirect,
  transactions,
  showExpandableRows,
}) => {
  const columns = [
    {
      sortable: true,
      selector: (row) => row?.transaction_number,
      name: "Numero",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row?.created_at),
      name: "Fecha De Creacion",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.transaction_type),
      name: "Tipo",
    },
    {
      sortable: true,
      selector: (row) =>
        `${formatNumber(
          row?.transaction_bodies?.reduce((suma, body) => {
            if (
              body.quantity_grams &&
              !isNaN(parseFloat(body.quantity_grams))
            ) {
              suma += parseFloat(body.quantity_grams);
            }
            return suma;
          }, 0)
        )} g`,
      name: "gramos",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.transaction_status),
      name: "Status De Transaccion",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.payment_status),
      name: "Status Del Pago",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.delivery_status),
      name: "Status De Entrega",
    },
  ];

  const expandableRows = (row, index) => {
    return (
      <div className="container border border-4 rounded-3 shadow w-50">
        <DataTable
          theme="solarized"
          columns={[
            {
              name: "Especie",
              selector: (row) => row?.seed?.seed_specie?.seed_specie_name,
            },
            { name: "Gramos", selector: (row) => row.quantity_grams },
            { name: "Valor", selector: (row) => row.total_value },
          ]}
          data={row.data.transaction_bodies}
          highlightOnHover
        />
      </div>
    );
  };
  return (
    <div>
      <ListTable
        pagination
        columns={columns}
        data={transactions}
        isLoading={isLoading}
        onRedirect={onRedirect}
        expandableRowsComponent={showExpandableRows ? expandableRows : false}
        emptyMessage="El Guardian No Tiene Transacciones Registradas"
      />
    </div>
  );
};

export default SecondaryTransactionListTable;

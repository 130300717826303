import React from "react";
import NumberField from "../../../common/forms/fields/numberFields/NumberFields";
import DecimalField from "../../../common/forms/fields/numberFields/DecimalField";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import SeedSpecieSelection from "../../../common/forms/fieldSections/SeedSpecieSelection";
import DateTimeFieldsSection from "../../../common/forms/fieldSections/DateTimeFieldsSection";

function SeedPresentationFields({
  formData,
  onChange,
  isUpdating,
  handleSeedSpecieChange,
}) {
  const calculatePresentationCost = () => {
    return formData.seed_specie
      ? formData.seed_specie?.average_cost_per_gram * formData.weight +
          formData.fixed_cost_calculated
      : "";
  };

  return (
    <div>
      <div className="form-group mt-4">
        <SeedSpecieSelection
          selectedOption={formData.seed_specie}
          handleChange={handleSeedSpecieChange}
          isReadOnly={isUpdating}
        />
      </div>
      <div className="form-group d-flex justify-content-evenly mt-4">
        <div className="form-group">
          <NumberField
            fieldLabel="Porcentaje De Ganancia"
            fieldName="percentage_profit"
            fieldValue={formData.percentage_profit}
            onChangeHandler={onChange}
          />
        </div>
        <div className="form-group ms-2">
          <DecimalField
            fieldLabel="Peso De La Presentacion"
            fieldName="weight"
            fieldValue={formData.weight}
            onChangeHandler={onChange}
          />
        </div>
      </div>

      <div>
        <div className="form-group mt-4 w-50">
          <div className="form-group">
            <NumberField
              fieldLabel="Costo Presentacion"
              fieldName="presentation_cost"
              fieldValue={calculatePresentationCost()}
              isReadOnly
            />
          </div>
          <div className="form-group mt-4">
            <NumberField
              fieldLabel="Precio Sugerido"
              fieldValue={Number(
                calculatePresentationCost() *
                  (1 + formData.percentage_profit / 100)
              )}
              isReadOnly
            />
          </div>
        </div>
        <div className="form-group d-flex justify-content-evenly mt-4">
          <div>
            <NumberField
              fieldLabel="Presio Asignado"
              fieldName="assigned_sale_value"
              fieldValue={formData.assigned_sale_value}
              onChangeHandler={onChange}
            />
          </div>
          <div className="ms-2">
            <NumberField
              fieldLabel="Ganancia"
              fieldValue={
                formData.assigned_sale_value - calculatePresentationCost()
              }
              isReadOnly
            />
          </div>
        </div>
        {isUpdating && (
          <div>
            <DateTimeFieldsSection
              created_at={formData.created_at}
              updated_at={formData.updated_at}
            />
          </div>
        )}
      </div>
      {/* Observations */}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Descripcion"
          fieldName="description"
          fieldValue={formData.description}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
}

export default SeedPresentationFields;

import React from "react";
import { capitalizeFirstLetterOfWords } from "../../../../../helpers/stringsHelpers";

function TestTypeSelect({ selectedTestType, onChange }) {
  let testTypeOptions = [
    "vigor",
    "purity",
    "viability",
    "sanitation",
    "germination",
  ];

  if (!selectedTestType) {
    testTypeOptions = ["", "vigor", "viability", "germination"];
  }

  return (
    <div className="form-group">
      <label className="form-label fw-lighter">Tipo De Prueba</label>
      <select
        className="form-select fst-italic"
        name="test_type"
        value={selectedTestType}
        onChange={onChange}
        disabled={selectedTestType ? true : false}
      >
        <option value="" disabled></option>
        {testTypeOptions.map((testType, index) => (
          <option key={index} value={testType}>
            {capitalizeFirstLetterOfWords(testType)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default TestTypeSelect;

import axios from "axios";
import Cookies from "js-cookie";

// Define API base URL
const API_BASE_URL = "http://127.0.0.1:8000/api";

// Centralized headers for API requests
const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

/**
 * Seed API Service for handling Seed-related operations.
 */
const seedPresentationService = {
  /**
   * Retrieves a list of all Seed Presentations.
   * @returns {Promise} A promise that resolves to the list of Seed Presentations.
   */
  getAllSeedPresentations: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/seeds/seed-presentations/list/`,
      headers
    );
    return response.data;
  },

  /**
   * Registers a new Seed Presentation.
   * @param {Object} seedPresentationData - Data for registering a new Seed Presentation.
   * @returns {Promise} A promise that resolves to the registered Seed Presentation data.
   */
  registerSeedPresentation: async (seedPresentationData) => {
    const response = await axios.post(
      `${API_BASE_URL}/seeds/seed-presentations/create/`,
      seedPresentationData,
      headers
    );

    return response.data;
  },

  /**
   * Updates the data of an existing Seed Presentation.
   * @param {Object} seedPresentationData - Updated data for the Seed Presentation.
   * @returns {Promise} A promise that resolves to the updated Seed Presentation data.
   */
  updateSeedPresentation: async (seedPresentationData) => {
    const url = `${API_BASE_URL}/seeds/seed-presentations/update/${seedPresentationData.id}/`;
    const response = await axios.patch(url, seedPresentationData, headers);
    return response.data;
  },

  /**
   * Deletes a Seed Presentation by ID.
   * @param {Object} seedPresentationData - Data specifying the Seed Presentation to be deleted.
   * @returns {Promise} A promise that resolves when the Seed Presentation is successfully deleted.
   */
  deleteSeedPresentation: async (seedPresentationData) => {
    const url = `${API_BASE_URL}/seeds/seed-presentations/delete/${seedPresentationData.id}/`;
    const response = await axios.delete(url, headers);
    return response.data;
  },
};

export default seedPresentationService;

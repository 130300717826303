import React from "react";

import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";

const TransactionAdjustmentAside = ({
  data,
  onDeleteItem,
  openTransactionOverviewModal,
}) => {
  const columns = [
    { selector: (row) => row.seed_code, name: "Codigo" },
    { selector: (row) => `${row.seed_current_weight} g`, name: "Peso Actual" },
    { selector: (row) => `${row.newWeight} g`, name: "Peso Nuevo" },
    {
      name: "Diff",
      selector: (row) =>
        `${(row.seed_current_weight - row.newWeight).toFixed(2)} g`,
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection title="Detalles" />
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Informacion Creador" />
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="fw-lighter">Usuario Espora: </h6>
            <p className="fst-italic">{data?.user?.email}</p>
          </div>
        </div>
      </div>

      <div>
        <SubtitlesSection subTitle="Semillas Seleccionadas" />
      </div>
      <div>
        <ListTable
          data={data.seeds}
          columns={columns}
          onDelete={onDeleteItem}
          emptyMessage="No Hay Semillas Seleccionadas"
        />
      </div>

      <hr />
      <div className="d-flex justify-content-center mt-2">
        <div className="mt-2">
          <ButtonSection
            text="Crear Transaccion"
            color="blue"
            onClick={openTransactionOverviewModal}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionAdjustmentAside;

import React from "react";
import "../../../styles/forms.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { registerPurchaseTransaction } from "../../../store/transactions/thunks/transactionsThunks";
import TransactionPurchaseForm from "../../../components/transactions/transactionPurchase/forms/TransactionPurchaseForm";

function TransactionCreatePurchasePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateTrasaction = async (transactionData) => {
    const action = await dispatch(registerPurchaseTransaction(transactionData));

    if (action.error) {
      alert("Hubo Un Error Creando La Transaccion");
      return;
    }

    alert("La Transaccion Se Creo Exitosamente.");

    navigate(`/transactions/create/`);
  };

  return (
    <div>
      <TransactionPurchaseForm onSubmitForm={handleCreateTrasaction} />
    </div>
  );
}

export default TransactionCreatePurchasePage;

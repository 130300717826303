import React from "react";
import ListTable from "../../../common/tables/ListTable";
import { formatNumber } from "../../../../helpers/formatNumber";

const SecondarySeedPresentationsListTable = ({ data, isLoading }) => {
  const columns = [
    {
      selector: (row) => `${formatNumber(row?.weight)} $`,
      name: "Presentacion",
    },
    {
      selector: (row) => row?.presentation_availability,
      name: "Disponible",
    },
    {
      name: "Costo",
      selector: (row) =>
        `${formatNumber(
          data?.seed_cost_per_gram * row?.weight +
            data?.fixed_cost_per_presentation
        )} $`,
    },
    {
      selector: (row) => `${formatNumber(row?.assigned_sale_value)} $`,
      name: "Precio Asignado",
    },
    {
      name: "Ganancia",
      selector: (row) =>
        `${formatNumber(
          row?.assigned_sale_value -
            (data?.seed_cost_per_gram * row?.weight +
              data?.fixed_cost_per_presentation)
        )} $`,
    },
  ];
  return (
    <div className="container">
      <div className="pt-2">
        <ListTable
          pagination
          columns={columns}
          isLoading={isLoading}
          data={data?.seed_specie?.seed_presentations}
          emptyMessage="No Hay Semillas Disponibles"
        />
      </div>
    </div>
  );
};

export default SecondarySeedPresentationsListTable;

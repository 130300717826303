import React from "react";

const PhoneField = ({
  fieldLabel,
  fieldKey,
  fieldValue,
  fieldName,
  isReadOnly,
  onChangeHandler,
}) => {
  return (
    <div key={fieldKey ? fieldKey : null}>
      <label className="form-label fw-lighter">{fieldLabel}: </label>
      <input
        className="form-control fst-italic"
        type="tel"
        name={fieldName ? fieldName : null}
        value={fieldValue ? fieldValue : ""}
        readOnly={isReadOnly ? true : false}
        onChange={onChangeHandler ? onChangeHandler : null}
      />
    </div>
  );
};

export default PhoneField;

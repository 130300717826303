import { useDispatch } from "react-redux";

const useOperationHandler = (operationFunctions) => {
  const dispatch = useDispatch();

  const handleOperation = async (operation, data) => {
    const operationFunction = operationFunctions[operation];
    if (operationFunction) {
      const action = await dispatch(operationFunction(data));

      if (action.error) {
        alert("Algo Salio Mal");
        return action;
      }

      if (operation === "register") {
        alert("Se Registro Exitosamente");
        return action;
      }

      if (operation === "delete") {
        alert("Se Elimino Exitosamente");
        return action;
      }

      if (operation === "update") {
        alert("Se Actualizo Exitosamente");
        return action;
      }
    }
  };

  return { handleOperation };
};

export default useOperationHandler;

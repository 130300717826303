import { createSlice } from "@reduxjs/toolkit";
import * as guardianThunks from "./thunks/guardianThunks";

// Initial state of the guardian slice
const initialState = {
  guardians: [],
  guardian: {},
  hasError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

/**
 * Redux slice for handling guardian-related actions.
 */
const guardianSlice = createSlice({
  name: "guardian",
  initialState,
  reducers: {
    /**
     * Reset the guardian slice to its initial state.
     * @param {Object} state - Current state of the guardian slice.
     */
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    // Thunks related to guardian actions
    builder
      // Case for pending registration
      .addCase(guardianThunks.registerGuardian.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.hasError = false;
        state.isSuccess = false;
      })
      // Case for successful registration
      .addCase(guardianThunks.registerGuardian.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
        state.isSuccess = true;
      })
      // Case for failed registration
      .addCase(guardianThunks.registerGuardian.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      })
      // Case for pending retrieval of a Guardian
      .addCase(guardianThunks.getGuardian.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of a Guardian
      .addCase(guardianThunks.getGuardian.fulfilled, (state, action) => {
        state.isLoading = false;
        state.guardian = action.payload;
        state.isSuccess = true;
      })
      // Case for failed retrieval of a Guardian
      .addCase(guardianThunks.getGuardian.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      })
      // Case for pending update of a Guardian
      .addCase(guardianThunks.updateGuardian.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.hasError = false;
        state.isSuccess = false;
      })
      // Case for successful update of a Guardian
      .addCase(guardianThunks.updateGuardian.fulfilled, (state, action) => {
        state.isLoading = false;
        state.guardian = action.payload;
        state.isSuccess = true;
      })
      // Case for failed update of a Guardian
      .addCase(guardianThunks.updateGuardian.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      // Case for pending retrieval of all Guardians
      .addCase(guardianThunks.getAllGuardians.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of all Guardians
      .addCase(guardianThunks.getAllGuardians.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.guardians = action.payload;
      })
      // Case for failed retrieval of all Guardians
      .addCase(guardianThunks.getAllGuardians.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })

      .addCase(guardianThunks.getGuardiansOwed.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of all Guardians
      .addCase(guardianThunks.getGuardiansOwed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.guardians = action.payload;
      })
      // Case for failed retrieval of all Guardians
      .addCase(guardianThunks.getGuardiansOwed.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      // Case for pending retrieval of filtered Guardians
      .addCase(guardianThunks.getFilteredGuardians.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of filtered Guardians
      .addCase(
        guardianThunks.getFilteredGuardians.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.guardians = action.payload;
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
        }
      )
      // Case for failed retrieval of filtered Guardians
      .addCase(
        guardianThunks.getFilteredGuardians.rejected,
        (state, action) => {
          state.isLoading = false;
          state.hasError = true;
        }
      )
      // Case for pending deletion of a Guardian
      .addCase(guardianThunks.deleteGuardian.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      // Case for successful deletion of a Guardian
      .addCase(guardianThunks.deleteGuardian.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      // Case for failed deletion of a Guardian
      .addCase(guardianThunks.deleteGuardian.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

// Export the actions from the reducers
export const { reset } = guardianSlice.actions;

// Export the reducer
export default guardianSlice.reducer;

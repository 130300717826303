import React from "react";
import "../../../../styles/forms.css";
import { CloseIcon } from "../../../../helpers/Iconos";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";

function GenericAsideForm({
  title,
  onCancel,
  formData,
  onSubmitForm,
  formFieldsComponent,
  isUpdating,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();

    const modifiedData = {
      ...formData,
      seed_specie: formData?.seed_specie?.id,
    };

    onSubmitForm(modifiedData);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div>
      <div className="d-flex justify-content-between bg-light text-dark z-3">
        <div className="container">
          <TitleSection title={title} />
        </div>
        <div className="mt-1">
          <ButtonSection
            text={CloseIcon}
            className="btn btn-primary rounded-circle p-2 lh-1"
            onClick={handleCancel}
            type="button"
          />
        </div>
      </div>
      <form className="p-4" onSubmit={handleSubmit}>
        <div className="mb-3">
          <div>{formFieldsComponent}</div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <ButtonSection
            text={`${isUpdating ? "Actualizar" : "Registrar"}`}
            color="green"
          />
        </div>
      </form>
    </div>
  );
}

export default GenericAsideForm;

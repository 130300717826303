import { createAsyncThunk } from "@reduxjs/toolkit";
import qualityTestService from "../qualityTestApiService";

/**
 * Utility function to handle errors in asynchronous functions.
 * @param {Error} error - Captured error object.
 * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
 * @returns {Promise} - Rejected promise with the error message.
 */
const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

/**
 * Thunk for registering a new qualityTest.
 * @type {AsyncThunk}
 */
export const registerQualityTest = createAsyncThunk(
  "qualityTest/register",
  /**
   * Asynchronous function that performs the registration of a new qualityTest.
   * @param {Object} qualityTestData - Data of the qualityTest to be registered.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the data of the registered qualityTest or rejected with an error message.
   */
  async (qualityTestData, thunkAPI) => {
    try {
      return await qualityTestService.registerQualityTest(qualityTestData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk to retrieve information about a single qualityTest.
 * @type {AsyncThunk}
 */
export const getQualityTest = createAsyncThunk(
  "qualityTest/singleQualityTest",
  /**
   * Asynchronous function that fetches information about a single qualityTest.
   * @param {Object} qualityTestData - Data identifying the qualityTest to be retrieved.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the qualityTest information or rejected with an error message.
   */
  async (qualityTestData, thunkAPI) => {
    try {
      let qualityTest = await qualityTestService.getQualityTest(
        qualityTestData
      );
      return qualityTest;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

// /**
//  * Thunk to update the data of an existing qualityTest.
//  * @type {AsyncThunk}
//  */
// export const updateQualityTest = createAsyncThunk(
//   "qualityTest/updateQualityTest",
//   /**
//    * Asynchronous function that updates the data of an existing qualityTest.
//    * @param {Object} qualityTestData - Data of the qualityTest to be updated.
//    * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
//    * @returns {Promise} - Resolved promise with the updated qualityTest data or rejected with an error message.
//    */
//   async (qualityTestData, thunkAPI) => {
//     try {
//       let qualityTest = await qualityTestService.updateQualityTest(
//         qualityTestData
//       );
//       return qualityTest.data;
//     } catch (error) {
//       return handleError(error, thunkAPI);
//     }
//   }
// );

/**
 * Thunk to get a filtered list of qualityTests.
 * @type {AsyncThunk}
 */
export const getFilteredQualityTests = createAsyncThunk(
  "qualityTest/listFilteredQualityTests",
  /**
   * Asynchronous function that retrieves a filtered list of qualityTests.
   * @param {Object} dataSearch - Data specifying the filtering criteria.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the filtered list of qualityTests or rejected with an error message.
   */
  async (dataSearch, thunkAPI) => {
    try {
      return await qualityTestService.getFilteredQualityTests(dataSearch);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/auth/authSlice";
import { Navigate, Link } from "react-router-dom";
import "../../../styles/navbar.css";

function Navbar() {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    return <Navigate to="/login" />;
  };

  return (
    <div className="container-fluid navbar-custom shadow sticky-top p-0 z-1">
      <div className="topbar">
        <div className="d-flex align-items-center justify-content-between">
          {/* Logo */}
          <div className="d-flex align-items-center">
            <div className="logo-topbar m-3">
              <h3>
                <Link className="nav-link" to="/">
                  Espora
                </Link>
              </h3>
            </div>
          </div>

          <ul className="topbar-menu d-flex align-items-center gap-3">
            <li className="dropdown">
              {/*
                to do href
              */}
              <a
                className="nav-link btn btn-outline-success"
                data-bs-toggle="dropdown"
                href="##"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="d-lg-flex flex-column gap-1 d-none">
                  <h5 className="my-0 m-4">{user?.username}</h5>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                {/* Dropdown Header */}
                <div className="dropdown-header noti-title">
                  <p className="fs-5 fw-light">{`Welcome, ${user?.username}`}</p>
                </div>

                {/* Dropdown Menu Items */}
                <Link to="/auth/my-account" className="dropdown-item">
                  <i className="mdi mdi-account-circle me-1"></i>
                  <span>My Account</span>
                </Link>
                {/*
                  to do:
                */}
                <a href="##" className="dropdown-item">
                  <i className="mdi mdi-account-edit me-1"></i>
                  <span>Settings</span>
                </a>

                <Link to="/auth/security-details" className="dropdown-item">
                  <i className="mdi mdi-lifebuoy me-1"></i>
                  <span>Security</span>
                </Link>

                <Link to="/auth/espora-users" className="dropdown-item">
                  <i className="mdi mdi-lock-outline me-1"></i>
                  <span>Espora Users</span>
                </Link>

                <Link
                  to="/login"
                  className="dropdown-item"
                  onClick={handleLogout}
                >
                  <i className="mdi mdi-logout me-1"></i>
                  <span>Logout</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import SeedRegistrationPage from "../containers/pages/seeds/seeds/SeedRegistrationPage";
import SeedListTablePage from "../containers/pages/seeds/seeds/SeedListTablePage";
import SeedDetailsPage from "../containers/pages/seeds/seeds/SeedDetailsPage";
/**
 * Defines the routes related to semillas within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain semilla-related routes.
 * @returns {JSX.Element} The rendered semilla routes.
 */
const SeedRoutes = () => (
  <Routes>
    {/* Route for creating a new semilla (requires authentication) */}
    <Route
      path="create"
      element={
        <PrivateRoute>
          <SeedRegistrationPage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing details of a specific guardian (requires authentication) */}
    <Route
      path="detail/:slug"
      element={
        <PrivateRoute>
          <SeedDetailsPage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing a list of semillas (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <SeedListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default SeedRoutes;

import React from "react";

function PartnerTypeField({ selectedType, onChange, required }) {
  const options = ["natural", "juridica"];

  return (
    <div className="form-group">
      <label className="form-label fw-lighter mt-3">Tipo De Persona:</label>
      <select
        className="form-select fst-italic"
        value={selectedType}
        name="partner_type"
        onChange={onChange}
        required={required ? true : false}
      >
        <option value="" disabled></option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default PartnerTypeField;

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateSeed,
  getSeed,
} from "../../../../store/seeds/seeds/thunks/seedThunks";
import { registerQualityTest } from "../../../../store/qualityTests/thunks/qualityTestThunks";
import {
  getTransaction,
  getFilteredTransactions,
} from "../../../../store/transactions/thunks/transactionsThunks";
import { getFixedCostPerPresentation } from "../../../../store/costManagement/fixedCost/thunks/fixedCostThunks";
import SeedDetails from "../../../../components/seeds/seeds/forms/SeedDetails";

function SeedDetailsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getSeed(slug)),
        dispatch(getFixedCostPerPresentation()),
        dispatch(getFilteredTransactions(`seed__id=${slug}`)),
      ]);
    };

    fetchData();
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleSeedUpdate = async (seedData) => {
    const updatedSeedData = { ...seedData, slug };
    const action = await dispatch(updateSeed(updatedSeedData));

    if (action.error) {
      alert("Algo Salio Mal Actualizando");
      return;
    }
    alert("Semilla Actualizada.");
  };

  const handleOperation = async (operation, TestData) => {
    const operationFunction = operation === "register" && registerQualityTest;
    const action = await dispatch(operationFunction(TestData));
    // reload();
    return action;
  };

  const handleRedirectToTransactionDetail = async (trasactionData) => {
    const action = await dispatch(getTransaction(trasactionData.id));

    if (action?.errors) {
      alert("Algo Salio Mal");
      return;
    }

    navigate(`/transactions/detail/${trasactionData.id}/`);
  };

  return (
    <div className="container">
      <SeedDetails
        onSubmitForm={handleSeedUpdate}
        onSubmitOperation={handleOperation}
        handleRedirectToTransactionDetail={handleRedirectToTransactionDetail}
      />
    </div>
  );
}

export default SeedDetailsPage;

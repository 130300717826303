import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getFilteredSeeds } from "../../../store/seeds/seeds/thunks/seedThunks";
import { getAllGuardians } from "../../../store/partners/guardians/thunks/guardianThunks";
import { getAllCustomers } from "../../../store/partners/customers/thunks/customerThunks";
import { getAllSeedSpecies } from "../../../store/seeds/seedSpecie/thunks/seedSpecieThunks";

import TitleSection from "../../../components/common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../components/common/forms/fieldSections/ButtonSection";

function TransactionsCreatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectToCreateSaleTransaction = async () => {
    await Promise.all([
      dispatch(getAllCustomers()),
      dispatch(getAllSeedSpecies()),
      dispatch(
        getFilteredSeeds({
          term: `current_weight=1&seed_usage=semilla`,
        })
      ),
    ]);

    navigate("/transactions/create/sale");
  };

  const redirectToCreatePurchaseTransaction = async () => {
    await Promise.all([
      dispatch(getAllGuardians()),
      dispatch(getAllSeedSpecies()),
    ]);

    navigate("/transactions/create/purchase");
  };

  const redirectToCreateAdjustmentTransaction = async () => {
    await Promise.all([
      dispatch(
        getFilteredSeeds({
          term: `current_weight=1`,
        })
      ),
      dispatch(getAllSeedSpecies()),
    ]);

    navigate("/transactions/create/adjustment_inventory");
  };

  return (
    <div className="container">
      <div className="container custom-border-large shadow mt-5 w-50">
        <div className="d-flex justify-content-between p-3 bg-light text-dark">
          <div className="container">
            <TitleSection title="Selecciona Operacion A Crear" />
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center mt-2">
            <ButtonSection
              text="Transaccion Venta"
              color="green"
              onClick={() => redirectToCreateSaleTransaction()}
            />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection
              text="Transaccion Compra"
              color="green"
              onClick={() => redirectToCreatePurchaseTransaction()}
            />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection
              text="ajuste inventario"
              color="green"
              onClick={() => redirectToCreateAdjustmentTransaction()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionsCreatePage;

import { createSlice } from "@reduxjs/toolkit";
import * as transactionsThunks from "./thunks/transactionsThunks";

const initialState = {
  transactions: [],
  transaction: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        transactionsThunks.registerPurchaseTransaction.pending,
        (state) => {
          state.isLoading = true;
          state.errorMessage = "";
          state.isSuccess = false;
        }
      )
      .addCase(
        transactionsThunks.registerPurchaseTransaction.fulfilled,
        (state) => {
          state.isLoading = false;
          state.isSuccess = true;
        }
      )
      .addCase(
        transactionsThunks.registerPurchaseTransaction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(transactionsThunks.registerSaleTransaction.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(
        transactionsThunks.registerSaleTransaction.fulfilled,
        (state) => {
          state.isLoading = false;
          state.isSuccess = true;
        }
      )
      .addCase(
        transactionsThunks.registerSaleTransaction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(transactionsThunks.getTransaction.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(transactionsThunks.getTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transaction = action.payload;
        state.isSuccess = true;
      })
      .addCase(transactionsThunks.getTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(transactionsThunks.getFilteredTransactions.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(
        transactionsThunks.getFilteredTransactions.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.transactions = action.payload;
          state.isSuccess = true;
        }
      )
      .addCase(
        transactionsThunks.getFilteredTransactions.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(transactionsThunks.updateTransaction.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(
        transactionsThunks.updateTransaction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.transaction = action.payload;
          state.isSuccess = true;
        }
      )
      .addCase(
        transactionsThunks.updateTransaction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.errorMessage = action.payload;
        }
      );
  },
});

// Export the actions from the reducers
export const { reset } = transactionsSlice.actions;

// Export the reducer
export default transactionsSlice.reducer;

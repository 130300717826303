import React, { useState } from "react";
import "../../../../styles/forms.css";
import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";
import FixedCostFields from "./FixedCostFields";
import ErrorMessages from "../../../common/forms/fieldSections/ErrorMessages";

function FixedCostForm({ onCancel, fixedCost, errorMessage, onSubmitForm }) {
  const initialFormData = {
    id: fixedCost?.id,
    created_at: fixedCost?.created_at,
    updated_at: fixedCost?.updated_at,
    expense_name: fixedCost?.expense_name || "",
    description: fixedCost?.description,
    expense_value: fixedCost?.expense_value || "",
    division_factor: fixedCost?.division_factor || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const isUpdating = formData.id ? true : false;

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number"
        ? parseFloat(e.target.value)
        : e.target.value.toLowerCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <GenericAsideForm
      title={`${isUpdating ? "Actualizar" : "Registrar"} Gasto Fijo`}
      onCancel={handleCancel}
      formData={formData}
      errorMessage={errorMessage}
      onSubmitForm={onSubmitForm}
      isUpdating={isUpdating}
      formFieldsComponent={
        <>
          {/* Fixed Cost Information Form */}
          <FixedCostFields
            formData={formData}
            onChange={handleInputChange}
            isUpdating={isUpdating}
          />
          <ErrorMessages errorMessage={errorMessage} />
        </>
      }
    />
  );
}

export default FixedCostForm;

import React from "react";
import "../../../../styles/forms.css";
import { useSelector } from "react-redux";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import {
  getAllFixedCosts,
  registerFixedCost,
  updateFixedCost,
  deleteFixedCost,
  getFixedCostPerPresentation,
} from "../../../../store/costManagement/fixedCost/thunks/fixedCostThunks";
import FixedCostListTable from "../../../../components/costManagement/fixedCost/tables/FixedCostListTable";

function FixedCostListTablePage() {
  const {
    isLoading,
    errorMessage,
    fixedCosts,
    isSuccess,
    isError,
    fixedCostPerPresentation,
  } = useSelector((state) => state.fixedCost);

  const { reload } = useDataAndReloadPage(
    [getAllFixedCosts, getFixedCostPerPresentation],
    [errorMessage]
  );

  const { handleOperation } = useOperationHandler({
    register: registerFixedCost,
    update: updateFixedCost,
    delete: deleteFixedCost,
  });

  const handleFixedCostOperation = async (operation, fixedCostData) => {
    await handleOperation(operation, fixedCostData);
    if (isSuccess) {
      reload();
    }
  };

  return (
    <div className="custom-border-large">
      <FixedCostListTable
        isError={isError}
        isLoading={isLoading}
        fixedCosts={fixedCosts}
        errorMessage={errorMessage}
        fixedCostPerPresentation={fixedCostPerPresentation}
        onfixedCostOperation={handleFixedCostOperation}
      />
    </div>
  );
}

export default FixedCostListTablePage;

import { createAsyncThunk } from "@reduxjs/toolkit";
import fixedCostService from "../fixedCostApiService";

const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

export const getAllFixedCosts = createAsyncThunk(
  "seed/listFixedCosts",
  async (_, thunkAPI) => {
    try {
      return await fixedCostService.getAllFixedCosts();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getFixedCostPerPresentation = createAsyncThunk(
  "seed/getFixedCostPerPresentation",
  async (_, thunkAPI) => {
    try {
      return await fixedCostService.getFixedCostPerPresentation();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const registerFixedCost = createAsyncThunk(
  "seed/registerFixedCosts",
  async (fixedCostData, thunkAPI) => {
    try {
      return await fixedCostService.registerFixedCost(fixedCostData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const updateFixedCost = createAsyncThunk(
  "seed/updateFixedCost",
  async (fixedCostData, thunkAPI) => {
    try {
      let fixedCost = await fixedCostService.updateFixedCost(fixedCostData);
      return fixedCost.data;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const deleteFixedCost = createAsyncThunk(
  "seed/deleteFixedCost",
  async (fixedCostData, thunkAPI) => {
    try {
      return await fixedCostService.deleteFixedCost(fixedCostData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

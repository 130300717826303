import React from "react";
import "../../../styles/forms.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { registerSaleTransaction } from "../../../store/transactions/thunks/transactionsThunks";
import TransactionSaleForm from "../../../components/transactions/transactionSale/forms/TransactionSaleForm";

function TransactionCreateSalePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateTrasaction = async (transactionData) => {
    const action = await dispatch(registerSaleTransaction(transactionData));

    if (action.errors) {
      alert("Hubo Un Error Creando La Transaccion");
    }

    alert("La Transaccion Se Creo Exitosamente.");

    navigate(`/transactions/create/`);
  };

  return (
    <div>
      <TransactionSaleForm onSubmitForm={handleCreateTrasaction} />
    </div>
  );
}

export default TransactionCreateSalePage;

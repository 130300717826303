import React from "react";
import ListTable from "../../../common/tables/ListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const SecondarySeedsListTable = ({ onRedirect, seeds, isLoading }) => {
  const columns = [
    { sortable: true, selector: (row) => row?.seed_code, name: "Codigo" },
    {
      sortable: true,
      selector: (row) =>
        capitalizeFirstLetterOfWords(row?.seed_specie?.seed_specie_name),
      name: "Especie",
    },
    {
      sortable: true,
      selector: (row) => row?.guardian?.altitude_range,
      name: "Rango Altitudinal",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row?.seed_usage),
      name: "Uso De Semilla",
    },
    {
      sortable: true,
      selector: (row) => `${row?.seed_current_weight} g`,
      name: "Peso Disponible",
    },
    {
      sortable: true,
      selector: (row) => row?.seed_specie?.average_seeds_per_gram,
      name: "Semilla Por Gramo",
    },
  ];
  return (
    <div className="container">
      <div className="pt-2">
        <ListTable
          pagination
          data={seeds}
          columns={columns}
          isLoading={isLoading}
          onRedirect={onRedirect}
          emptyMessage="No Hay Semillas Disponibles"
        />
      </div>
    </div>
  );
};

export default SecondarySeedsListTable;

import axios from "axios";
import Cookies from "js-cookie";

// Define API base URL
const API_BASE_URL = "http://127.0.0.1:8000/api";

// Centralized headers for API requests
const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

const fixedCostService = {
  getAllFixedCosts: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/costs/fixed-costs/list/`,
      headers
    );
    return response.data;
  },

  getFixedCostPerPresentation: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/costs/fixed-costs/fixed-cost-per-presentation/`,
      headers
    );
    return response.data;
  },

  registerFixedCost: async (fixedCostData) => {
    const response = await axios.post(
      `${API_BASE_URL}/costs/fixed-costs/create/`,
      fixedCostData,
      headers
    );

    return response.data;
  },

  updateFixedCost: async (fixedCostData) => {
    const url = `${API_BASE_URL}/costs/fixed-costs/update/${fixedCostData.id}/`;
    const response = await axios.patch(url, fixedCostData, headers);
    return response.data;
  },

  deleteFixedCost: async (fixedCostData) => {
    const url = `${API_BASE_URL}/costs/fixed-costs/delete/${fixedCostData.id}/`;
    const response = await axios.delete(url, headers);
    return response.data;
  },
};

export default fixedCostService;

import React from "react";
import { formatNumber } from "../../../../helpers/formatNumber";

const TransactionsReports = ({ transactions }) => {
  const filterTransactions = (type, status) => {
    return transactions.filter((transaction) => {
      return (
        transaction.transaction_type === type &&
        transaction.payment_status === status
      );
    });
  };

  const calculateTotal = (filteredTransactions) => {
    return formatNumber(
      filteredTransactions.reduce((accumulator, transaction) => {
        return accumulator + parseFloat(transaction.total_value);
      }, 0)
    );
  };

  const saleTransactions = filterTransactions("venta", undefined);
  const pendingSales = filterTransactions("venta", "pendiente");
  const purchaseTransactions = filterTransactions("compra", undefined);
  const pendingPurchases = filterTransactions("compra", "pendiente");

  return (
    <div>
      <div className="d-flex">
        <div className="p-2 g-col-6">
          <div>
            <h6 className="fw-lighter">Transacciones De Venta Realizadas</h6>
            <p>{saleTransactions.length}</p>
          </div>
          <div className="d-flex justify-content-around">
            <div>
              <h6 className="fw-lighter">Total De Las Ventas</h6>
              <p>{calculateTotal(saleTransactions)} $</p>
            </div>
            <div className="ms-4">
              <h6 className="fw-lighter">Pagos Recibidos</h6>
              <p>
                {calculateTotal(
                  saleTransactions.filter(
                    (transaction) => transaction.payment_status !== "pendiente"
                  )
                )}{" "}
                $
              </p>
            </div>
            <div className="ms-4">
              <h6 className="fw-lighter">Pagos Por Recibir</h6>
              <p>{calculateTotal(pendingSales)} $</p>
            </div>
          </div>
        </div>
        <div className="d-flex ms-4" style={{ height: "150px" }}>
          <div className="vr"></div>
        </div>
        <div className="ms-4 p-2 g-col-6">
          <div>
            <h6 className="fw-lighter">Transacciones De Compra Realizadas</h6>
            <p>{purchaseTransactions.length}</p>
          </div>
          <div className="d-flex justify-content-around">
            <div>
              <h6 className="fw-lighter">Total Por Las Compras</h6>
              <p>{calculateTotal(purchaseTransactions)} $</p>
            </div>
            <div className="ms-4">
              <h6 className="fw-lighter">Pagos Realizados</h6>
              <p>
                {calculateTotal(
                  purchaseTransactions.filter(
                    (transaction) => transaction.payment_status !== "pendiente"
                  )
                )}{" "}
                $
              </p>
            </div>
            <div className="ms-4">
              <h6 className="fw-lighter">Total Costos Por Pagar</h6>
              <p>{calculateTotal(pendingPurchases)} $</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
    </div>
  );
};

export default TransactionsReports;

import React from "react";

import ListTable from "../../../common/tables/ListTable";
import { formatNumber } from "../../../../helpers/formatNumber";
import { formatDateTime } from "../../../../helpers/dateHelper";
import InfoSection from "../../../common/forms/fieldSections/InfoSection";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const TransactionSaleOverview = ({
  formData,
  onCreate,
  onChange,
  allSpecies,
}) => {
  const dataSeedToTable = formData["seeds"].map((seed) => ({
    ...seed,
    seed_specie: allSpecies.find((specie) => specie.id === seed.seed_specie.id),
  }));

  const columns = [
    { selector: (row) => row.seed_code, name: "Codigo" },
    {
      selector: (row) =>
        capitalizeFirstLetterOfWords(row.seed_specie.seed_specie_name),
      name: "Especie",
    },
    {
      selector: (row) => `${row.selectedPresentation.weight} g`,
      name: "Presentacion",
    },
    { selector: (row) => row.numberOfSobres, name: "Numero De Sobres" },
    {
      selector: (row) =>
        `${formatNumber(
          row.selectedPresentation.assigned_sale_value * row.numberOfSobres
        )} $`,
      name: "Valor",
    },
  ];

  return (
    <div className="container custom-border-medium shadow">
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection title="Detalles de la Venta" />
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Informacion Del Usuario" />
        <div className="d-flex justify-content-between">
          <div>
            <InfoSection title="Usuario Espora:" data={formData.user.email} />
          </div>
        </div>
      </div>
      <div>
        <SubtitlesSection subTitle="Informacion Del Cliente" />
        <div className="d-flex justify-content-between">
          <div>
            <div>
              <InfoSection title="Cliente:" data={formData.customer.name} />
            </div>
            <div>
              <InfoSection
                title="Dirección:"
                data={formData.customer.address}
              />
            </div>
            <div>
              <InfoSection
                title="Fecha de Creación:"
                data={formatDateTime(formData.customer.created_at)}
              />
            </div>
          </div>
          <div>
            <div>
              <InfoSection
                title="Documento:"
                data={formData.customer.document}
              />
            </div>
            <div>
              <InfoSection title="Email:" data={formData.customer.email} />
            </div>
            <div>
              <InfoSection
                title="Número de Teléfono:"
                data={formData.customer.phone_number}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Detalles de las Semillas" />
        <div>
          <ListTable
            data={dataSeedToTable}
            columns={columns}
            emptyMessage="No Hay Semillas Seleccionadas"
          />
        </div>
        <div className="mt-4">
          <h5>
            Subtotal De Transaccion ({formData.seeds.length} items): $
            {formData.seeds.length > 0
              ? formatNumber(
                  formData.seeds.reduce(
                    (suma, obj) =>
                      suma +
                      obj.selectedPresentation.assigned_sale_value *
                        obj.numberOfSobres,
                    0
                  )
                )
              : 0}
          </h5>
        </div>
      </div>
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="observations"
          fieldValue={formData?.observations}
          onChangeHandler={onChange}
        />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <ButtonSection
          text="Crear Transaccion"
          color="green"
          onClick={onCreate}
        />
      </div>
    </div>
  );
};

export default TransactionSaleOverview;

import { createSlice } from "@reduxjs/toolkit";
import * as seedSpecieThunks from "./thunks/seedSpecieThunks";

// Initial state of the seed slice
const initialState = {
  seedSpecies: [],
  seedSpecie: {},
  count: null,
  next: null,
  previous: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

/**
 * Redux slice for handling seed-related actions.
 */
const seedSpecieSlice = createSlice({
  name: "seedSpecie",
  initialState,
  reducers: {
    /**
     * Reset the seed slice to its initial state.
     * @param {Object} state - Current state of the seed slice.
     */
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Thunks related to seed actions
      .addCase(seedSpecieThunks.registerSeedSpecie.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(seedSpecieThunks.registerSeedSpecie.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(
        seedSpecieThunks.registerSeedSpecie.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(seedSpecieThunks.getAllSeedSpecies.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(
        seedSpecieThunks.getAllSeedSpecies.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.seedSpecies = action.payload;
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
        }
      )
      .addCase(seedSpecieThunks.getAllSeedSpecies.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedSpecieThunks.updateSeedSpecie.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(seedSpecieThunks.updateSeedSpecie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seed = action.payload;
        state.isSuccess = true;
      })
      .addCase(seedSpecieThunks.updateSeedSpecie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedSpecieThunks.deleteSeedSpecie.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(seedSpecieThunks.deleteSeedSpecie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(seedSpecieThunks.deleteSeedSpecie.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      });
  },
});

// Export the actions from the reducers
export const { reset } = seedSpecieSlice.actions;

// Export the reducer
export default seedSpecieSlice.reducer;

import React from "react";
import EmailField from "../../common/forms/fields/textFields/EmailField";
import PasswordField from "../../common/forms/fields/numberFields/PasswordField";

/**
 * Component to render form fields for Espora user login.
 * @param {Object} props - The component properties.
 * @param {Object} props.formData - The form data.
 * @param {Function} props.onChange - Function to handle form data changes.
 * @returns {JSX.Element} - JSX element representing the component.
 */
const EsporaUserLoginFields = ({ formData, onChange }) => {
  return (
    <div className="mb-3 text-start">
      <div className="form-icon-container">
        <EmailField
          fieldLabel="Email"
          fieldName="email"
          fieldValue={formData.email}
          onChangeHandler={onChange}
        />
      </div>
      <div className="form-group position-relative">
        <div className="form-icon-container">
          <PasswordField
            fieldLabel="Password"
            fieldName="password"
            fieldValue={formData.password}
            onChangeHandler={onChange}
          />
        </div>
      </div>
      <div className="flex-between-center mt-4 row">
        <div className="col-auto">
          <div className="mb-0 form-check">
            <input
              name="remember-me"
              type="checkbox"
              id="remember-me"
              className="form-check-input"
            />
            <label htmlFor="remember-me" className="mb-0 form-check-label">
              Remember me
            </label>
          </div>
        </div>
        <div className="col-auto">
          {/* to do: */}
          <a className="fs-9 fw-semi-bold" href="##">
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default EsporaUserLoginFields;

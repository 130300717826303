import React, { useState } from "react";

import GuardiansOwedTable from "./tables/GuardiansOwedTable";
import CustomersOwingTable from "./tables/CustomersOwingTable";
import TransactionsSummary from "./tables/transactionsSummary/TransactionsSummary";
import SeedsWithoutQualityTestsTable from "./tables/SeedsWithoutQualityTestsTable";
import DateSelector from "../common/forms/fields/selectionFields/DateSelector";

const DashboardContainer = () => {
  function getDates() {
    const today = new Date();
    const todayFormatted = formatDate(today);

    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const lastMonthFormatted = formatDate(lastMonth);

    return { today: todayFormatted, lastMonth: lastMonthFormatted };
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const { today, lastMonth } = getDates();

  const initialFormData = {
    dateFrom: lastMonth,
    dateUntil: today,
  };

  const [formData, setFormData] = useState(initialFormData);

  const onChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  return (
    <div>
      <div className="w-50">
        <h4 className="fw-lighter">Ingrese Rango De Fecha</h4>
        <div className="form-group d-flex mt-4">
          <div className="form-group w-50">
            <DateSelector
              fieldLabel="Fecha Desde"
              fieldName="dateFrom"
              fieldValue={formData?.dateFrom}
              onChangeHandler={onChange}
              // isReadOnly={isUpdating ? true : false}
            />
          </div>
          <div className="form-group ms-2 w-50">
            <DateSelector
              fieldLabel="Fecha Hasta"
              fieldName="dateUntil"
              fieldValue={formData?.dateUntil}
              onChangeHandler={onChange}
              // isReadOnly={isUpdating ? true : false}
            />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <TransactionsSummary formData={formData} />
      </div>
      <hr />

      <div className="d-flex justify-content-between p-3">
        <div className="w-50">
          <h4 className="fw-lighter">Guardianes Que Se Les Debe</h4>
          <GuardiansOwedTable />
        </div>
        <div className="w-50 ms-4">
          <h4 className="fw-lighter">Clientes Que Nos Deben</h4>
          <CustomersOwingTable />
        </div>
      </div>

      <hr />

      <div className="p-3">
        <h4 className="fw-lighter">
          Semillas Con Pruebas De Calidad Incompletas
        </h4>
        <SeedsWithoutQualityTestsTable />
      </div>
    </div>
  );
};

export default DashboardContainer;

export const formatNumber = (number) => {
  if (!number) {
    return 0;
  }
  const numberString = typeof number === "number" ? number.toString() : number;
  let parts = numberString.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return parts.join(",");
};

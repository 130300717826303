/**
 * Sorts an array of strings alphabetically.
 *
 * @param {string[]} array - The array of strings to be sorted.
 * @returns {string[]} The sorted array of strings.
 */
export function sortAlphabetically(array) {
  // Create a shallow copy of the array to avoid mutating the original
  const copiedArray = array.slice();

  // Sort the copied array alphabetically
  const sortedArray = copiedArray.sort((a, b) => a.localeCompare(b));

  return sortedArray;
}

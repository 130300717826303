import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ListTable from "../../common/tables/ListTable";
import { formatDateTime } from "../../../helpers/dateHelper";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";
import { getGuardiansOwed } from "../../../store/partners/guardians/thunks/guardianThunks";

const GuardiansOwedTable = () => {
  const { guardians } = useSelector((state) => state.guardian);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getGuardiansOwed());
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (guardianData) => {
    navigate(`/guardians/detail/${guardianData.id}`);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.name),
      name: "Nombre",
    },
    {
      sortable: true,
      selector: (row) => row.transaction_number,
      name: "Numero De Transaccion",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row.transaction_created_date),
      name: "Fecha Cracion Transaccion",
    },
    {
      sortable: true,
      selector: (row) => `${row.total_transaction_value} $`,
      name: "Valor De Transaccion",
    },
  ];

  return (
    <div>
      <ListTable
        pagination
        data={guardians}
        columns={columns}
        onRedirect={handleRedirect}
        keyField="transaction_number"
        emptyMessage="No Tienes Deudas A Ningun Guardian"
      />
    </div>
  );
};

export default GuardiansOwedTable;

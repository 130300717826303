import React, { useState, useEffect } from "react";
import "../../../../styles/asideForm.css";
import { InfoIcon, DeleteIcon } from "../../../../helpers/Iconos";
import ListTable from "../../../common/tables/ListTable";
import FixedCostForm from "../forms/FixedCostForm";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import { formatNumber } from "../../../../helpers/formatNumber";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

function FixedCostListTable({
  isError,
  isLoading,
  fixedCosts,
  errorMessage,
  onfixedCostOperation,
  fixedCostPerPresentation,
}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFixedCost, setEditedFixedCost] = useState({});

  useEffect(() => {
    if (!isEditing) {
      setEditedFixedCost({});
    }
  }, [isEditing, isFormOpen, isError]);

  const handleRedirect = (selectedFixedCost) => {
    setIsEditing(true);
    setIsFormOpen(!isFormOpen);
    setEditedFixedCost(selectedFixedCost);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setIsEditing(false);
  };

  const handleCancelForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
  };

  const handleDelete = (fixedCostData) => {
    onfixedCostOperation("delete", fixedCostData);
  };

  const handleRegisterFixedCost = async (fixedCostData) => {
    const operationType = isEditing ? "update" : "register";

    await onfixedCostOperation(operationType, fixedCostData);

    setIsEditing(false);
    setEditedFixedCost({});
    if (!isError) {
      setIsFormOpen(false);
    }
  };

  const columns = [
    {
      selector: (row) => capitalizeFirstLetterOfWords(row.expense_name),
      name: "Gasto Fijo",
    },
    {
      selector: (row) => `${formatNumber(row.expense_value)} $`,
      name: "Valor",
    },
    {
      selector: (row) => formatNumber(row.division_factor),
      name: "Sobre Necesarios",
    },
    {
      name: "Cargo Por Sobre",
      selector: (row) =>
        `${formatNumber(row.expense_value / row.division_factor)} $`,
    },
    {
      cell: (row) => (
        <>
          <div>
            <ButtonSection
              text={InfoIcon}
              color="blue"
              onClick={() => handleRedirect(row)}
            />
          </div>
          <div className="ms-2">
            <ButtonSection
              text={DeleteIcon}
              color="grey"
              onClick={() => handleDelete(row)}
            />
          </div>
        </>
      ),
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="container">
      <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
        <div className="container">
          <TitleSection title="Gastos Fijos" />
          <div>
            <p>
              el costo actual para cada sobre es:{" "}
              {formatNumber(fixedCostPerPresentation)}
            </p>
          </div>
        </div>
        <div className="w-25 d-flex justify-content-end">
          <ButtonSection
            text={isFormOpen ? "Atras" : "Registrar Gasto"}
            color={isEditing ? "grey" : "yellow"}
            onClick={handleToggleForm}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="w-100">
          <ListTable
            columns={columns}
            data={fixedCosts}
            isLoading={isLoading}
            emptyMessage={`No Hay Gastos Registrados`}
          />
          <hr />
        </div>
        {isFormOpen && (
          <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
            <FixedCostForm
              onSubmitForm={handleRegisterFixedCost}
              fixedCost={editedFixedCost}
              errorMessage={errorMessage}
              onCancel={handleCancelForm}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FixedCostListTable;

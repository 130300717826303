import React from "react";
import InfoSection from "../fieldSections/InfoSection";

const GuardianDetails = ({ selectedGuardian }) => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <InfoSection title="Guardian" data={selectedGuardian?.name} />
        <InfoSection title="Vereda" data={selectedGuardian?.vereda} />
        <InfoSection title="Municipio" data={selectedGuardian?.municipality} />
        <InfoSection title="Clima" data={selectedGuardian?.climate} />
        <InfoSection
          title="Rango Altitudinal"
          data={selectedGuardian?.altitude_range}
        />
      </div>
      <hr />
    </div>
  );
};

export default GuardianDetails;

import React from "react";
import DashboardContainer from "../../../components/dashboard/DashboardContainer";

function DashboardPage() {
  return (
    <div>
      <DashboardContainer />
    </div>
  );
}

export default DashboardPage;

import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import SeedSpecieListTablePage from "../containers/pages/seeds/seedSpecies/SeedSpecieListTablePage";

/**
 * Defines the routes related to seed groups within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain seed-group-related routes.
 * @returns {JSX.Element} The rendered seed group routes.
 */
const SeedSpecieRoutes = () => (
  <Routes>
    {/* Route for viewing a list of groups of seeds (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <SeedSpecieListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default SeedSpecieRoutes;

import React from "react";
import FiltersListTable from "../../../../common/tables/filters/filtersListTable";
import TextField from "../../../../common/forms/fields/textFields/TextField";

/**
 * The CustomerFiltersListTable component displays a table with filters for customers.
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.onFilter - Callback function to handle the application of filters.
 * @returns {JSX.Element} - The rendered CustomerFiltersListTable component.
 */
function CustomerFiltersListTable({ onFilter }) {
  // Define filter options with corresponding initial values and components
  const filters = [
    {
      key: "name",
      component: ({ selectedValue, onChange }) => (
        <TextField
          fieldKey="name"
          fieldName="name"
          fieldValue={selectedValue}
          fieldLabel="Buscar Por Nombre"
          onChangeHandler={(e) => onChange(e)}
        />
      ),
      initialValue: "",
    },
  ];

  /**
   * Callback function to handle the application of filters.
   *
   * @param {Object} values - The values of applied filters.
   */
  const handleOnFilter = (values) => {
    onFilter(values);
  };

  return (
    <div>
      {/* Render the FiltersListTable with customer filters */}
      <FiltersListTable onFilter={handleOnFilter} filters={filters} />
    </div>
  );
}

export default CustomerFiltersListTable;

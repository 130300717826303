import React from "react";
import TextField from "../../common/forms/fields/textFields/TextField";
import EmailField from "../../common/forms/fields/textFields/EmailField";
import PasswordField from "../../common/forms/fields/numberFields/PasswordField";

const EsporaUserFormFields = ({ formData, onChange }) => {
  return (
    <div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Name"
          fieldName="username"
          fieldValue={formData.username}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group mt-4">
        <EmailField
          fieldLabel="Email"
          fieldName="email"
          fieldValue={formData.email}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group">
        <PasswordField
          fieldLabel="Password"
          fieldName="password"
          fieldValue={formData.password}
          onChangeHandler={onChange}
        />
      </div>

      <div className="form-group">
        <PasswordField
          fieldLabel="Confirmacion Password"
          fieldName="rePassword"
          fieldValue={formData.rePassword}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
};

export default EsporaUserFormFields;

import { createAsyncThunk } from "@reduxjs/toolkit";
import seedPresentationService from "../seedPresentationApiService";

/**
 * Utility function to handle errors in asynchronous functions.
 * @param {Error} error - Captured error object.
 * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
 * @returns {Promise} - Rejected promise with the error message.
 */
const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

/**
 * Thunk to get a list of all seed presentations.
 * @type {AsyncThunk}
 */
export const getAllSeedPresentations = createAsyncThunk(
  "seed/listSeedPresentations",
  /**
   * Asynchronous function that retrieves a list of all seed presentations.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the list of seed presentations or rejected with an error message.
   */
  async (_, thunkAPI) => {
    try {
      return await seedPresentationService.getAllSeedPresentations();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk for registering a new seed presentation.
 * @type {AsyncThunk}
 */
export const registerSeedPresentation = createAsyncThunk(
  "seed/registerSeedPresentations",
  /**
   * Asynchronous function that registers a new seed presentation.
   * @param {Object} seedPresentationData - Data of the seed presentation to be registered.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the data of the registered seed presentation or rejected with an error message.
   */
  async (seedPresentationData, thunkAPI) => {
    try {
      return await seedPresentationService.registerSeedPresentation(
        seedPresentationData
      );
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk to update the data of an existing seed presentation.
 * @type {AsyncThunk}
 */
export const updateSeedPresentation = createAsyncThunk(
  "seed/updateSeedPresentation",
  /**
   * Asynchronous function that updates the data of an existing seed presentation.
   * @param {Object} seedPresentationData - Data of the seed presentation to be updated.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the updated seed presentation data or rejected with an error message.
   */
  async (seedPresentationData, thunkAPI) => {
    try {
      let seedPresentation =
        await seedPresentationService.updateSeedPresentation(
          seedPresentationData
        );
      return seedPresentation.data;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk to delete a seed presentation.
 * @type {AsyncThunk}
 */
export const deleteSeedPresentation = createAsyncThunk(
  "seed/deleteSeedPresentation",
  /**
   * Asynchronous function that deletes a seed presentation.
   * @param {Object} seedPresentationData - Data of the seed presentation to be deleted.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise if the deletion is successful or rejected with an error message.
   */
  async (seedPresentationData, thunkAPI) => {
    try {
      return await seedPresentationService.deleteSeedPresentation(
        seedPresentationData
      );
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

import { createSlice } from "@reduxjs/toolkit";
import * as customerThunks from "./thunks/customerThunks";

// Initial state of the customer slice
const initialState = {
  customers: [],
  customer: {},
  hasError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

/**
 * Redux slice for handling customer-related actions.
 */
const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    // Thunks related to customer actions
    builder
      // Case for pending registration
      .addCase(customerThunks.registerCustomer.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.hasError = false;
        state.isSuccess = false;
      })
      // Case for successful registration
      .addCase(customerThunks.registerCustomer.fulfilled, (state) => {
        state.isLoading = false;
        state.hasError = false;
        state.isSuccess = true;
      })
      // Case for failed registration
      .addCase(customerThunks.registerCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      })
      // Case for pending retrieval of a customer
      .addCase(customerThunks.getCustomer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of a customer
      .addCase(customerThunks.getCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customer = action.payload;
        state.isSuccess = true;
      })
      // Case for failed retrieval of a customer
      .addCase(customerThunks.getCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      })
      // Case for pending update of a customer
      .addCase(customerThunks.updateCustomer.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.hasError = false;
        state.isSuccess = false;
      })
      // Case for successful update of a customer
      .addCase(customerThunks.updateCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customer = action.payload;
        state.isSuccess = true;
      })
      // Case for failed update of a customer
      .addCase(customerThunks.updateCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      // Case for pending retrieval of all customers
      .addCase(customerThunks.getAllCustomers.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of all customers
      .addCase(customerThunks.getAllCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      // Case for failed retrieval of all customers
      .addCase(customerThunks.getCustomersOwing.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      .addCase(customerThunks.getCustomersOwing.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of all customers
      .addCase(customerThunks.getCustomersOwing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      // Case for failed retrieval of all customers
      .addCase(customerThunks.getAllCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      })
      // Case for pending retrieval of filtered customers
      .addCase(customerThunks.getFilteredCustomers.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      // Case for successful retrieval of filtered customers
      .addCase(
        customerThunks.getFilteredCustomers.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.customers = action.payload;
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
        }
      )
      // Case for failed retrieval of filtered customers
      .addCase(
        customerThunks.getFilteredCustomers.rejected,
        (state, action) => {
          state.isLoading = false;
          state.hasError = true;
        }
      )
      // Case for pending deletion of a customer
      .addCase(customerThunks.deleteCustomer.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      // Case for successful deletion of a customer
      .addCase(customerThunks.deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      // Case for failed deletion of a customer
      .addCase(customerThunks.deleteCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

// Export the actions from the reducers
export const { reset } = customerSlice.actions;

// Export the reducer
export default customerSlice.reducer;

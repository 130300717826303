import React from "react";
import TestTypeSelect from "../../common/forms/fields/selectionFields/TestTypeSelect";
import TextAreaField from "../../common/forms/fields/textFields/TextAreaField";
import NumberField from "../../common/forms/fields/numberFields/NumberFields";
import CheckField from "../../common/forms/fields/selectionFields/CheckField";
import DecimalField from "../../common/forms/fields/numberFields/DecimalField";
import DateSelector from "../../common/forms/fields/selectionFields/DateSelector";

function QualityTestFormFields({ formData, onChange, isUpdating }) {
  return (
    <div>
      <div className="form-group mt-4 w-50">
        <TestTypeSelect
          selectedTestType={formData.test_type}
          onChange={onChange}
        />
      </div>
      {/* vigor or viability or germination Test Fields*/}
      {formData.test_type === "vigor" ||
      formData.test_type === "germination" ||
      formData.test_type === "viability" ? (
        <div>
          <div className="form-group d-flex mt-4">
            <div className="form-group">
              <NumberField
                fieldLabel="Semillas Usadas"
                fieldName="used_seeds_count"
                fieldValue={formData.used_seeds_count}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group ms-2">
              <NumberField
                fieldLabel="Porsentage De Germinacion"
                fieldName="germination_percentage"
                fieldValue={
                  isUpdating
                    ? formData.germination_percentage
                    : (formData.germinated_seed_count /
                        formData.used_seeds_count) *
                      100
                }
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="form-group d-flex mt-4">
            <div className="form-group">
              <NumberField
                fieldLabel="Recuento De Semillas Germinadas"
                fieldName="germinated_seed_count"
                fieldValue={formData.germinated_seed_count}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group ms-2">
              <DecimalField
                fieldLabel="Recuento De Semillas No Germinadas"
                fieldName="non_germinated_seed_count"
                fieldValue={
                  isUpdating
                    ? formData.non_germinated_seed_count
                    : formData.used_seeds_count - formData.germinated_seed_count
                }
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
      ) : null}
      {/* sanitation Test Fields*/}
      {formData.test_type === "sanitation" && (
        <div>
          <div className="form-group align-self-center mt-4">
            <CheckField
              fieldLabel="Se Realizo Prueba De Patogenos?"
              fieldName="pathogen_test"
              fieldValue={formData.pathogen_test}
              onChangeHandler={!isUpdating && onChange}
              isChecked={formData.pathogen_test}
              required
              isReadOnly={isUpdating ? true : false}
            />
          </div>
          <div className="form-group align-self-center mt-4">
            <CheckField
              fieldLabel="Patogenos Presentes?"
              fieldName="pathogens_present"
              fieldValue={formData.pathogens_present}
              onChangeHandler={!isUpdating && onChange}
              isChecked={formData.pathogens_present}
              required
              isReadOnly={isUpdating ? true : false}
            />
          </div>
          <div className="form-group d-flex mt-4">
            <div className="form-group align-self-center">
              <CheckField
                fieldLabel="Presencia De Hongos?"
                fieldName="fungi_present"
                fieldValue={formData.fungi_present}
                onChangeHandler={!isUpdating && onChange}
                isChecked={formData.fungi_present}
                required
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group align-self-center">
              <CheckField
                fieldLabel="Presencia De Bacterias?"
                fieldName="bacteria_present"
                fieldValue={formData.bacteria_present}
                onChangeHandler={!isUpdating && onChange}
                isChecked={formData.bacteria_present}
                required
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group align-self-center">
              <CheckField
                fieldLabel="Presencia De Insectos?"
                fieldName="insects_present"
                fieldValue={formData.insects_present}
                onChangeHandler={!isUpdating && onChange}
                isChecked={formData.insects_present}
                required
                isReadOnly={isUpdating ? true : false}
              />
            </div>
          </div>
          <div className="form-group w-25 mt-4">
            <NumberField
              fieldLabel="Resultados De Sanidad"
              fieldName="sanitization_result"
              fieldValue={formData.sanitization_result}
              onChangeHandler={!isUpdating && onChange}
              isReadOnly={isUpdating ? true : false}
            />
          </div>
        </div>
      )}
      {/* purity Test Fields*/}
      {formData.test_type === "purity" && (
        <div>
          <div className="form-group align-self-center mt-4">
            <CheckField
              fieldLabel="Se Realizo Limpieza?"
              fieldName="cleaning_performed"
              fieldValue={formData.cleaning_performed}
              onChangeHandler={onChange}
              isChecked={formData.cleaning_performed}
              required
              isReadOnly={isUpdating ? true : false}
            />
          </div>
          <div className="form-group d-flex mt-4">
            <div className="form-group">
              <DecimalField
                fieldLabel="Peso Total De Impurezas Del Codigo"
                fieldName="total_impurity_weight_for_seed"
                fieldValue={formData.total_impurity_weight_for_seed}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group ms-2">
              <DecimalField
                fieldLabel="Peso Total De Entrada Del Codigo"
                fieldName="total_seed_entry_weight"
                fieldValue={formData?.seed?.seed_entry_weight}
                isReadOnly={true}
              />
            </div>
          </div>
          <hr />
          <div className="form-group w-25">
            <DecimalField
              fieldLabel="Peso De La Prueba"
              fieldName="test_sample_weight"
              fieldValue={formData.test_sample_weight}
              onChangeHandler={onChange}
              isReadOnly={isUpdating ? true : false}
            />
          </div>
          <div className="form-group d-flex mt-4">
            <div className="form-group">
              <DecimalField
                fieldLabel="Peso De Purezas De La Prueba"
                fieldName="test_purity_sample_weight"
                fieldValue={formData.test_purity_sample_weight}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div className="form-group ms-2">
              <DecimalField
                fieldLabel="Peso De Impurezas De Prueba"
                fieldName="total_impurity_sample_weight"
                fieldValue={
                  isUpdating
                    ? formData.total_impurity_sample_weight
                    : formData.test_sample_weight -
                      formData.test_purity_sample_weight
                }
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="form-group mt-4 w-25">
            <DecimalField
              fieldLabel="Pocentage De Purezas"
              fieldName="purity_percentage"
              fieldValue={(isUpdating
                ? formData.purity_percentage
                : (formData.test_purity_sample_weight /
                    formData.test_sample_weight) *
                  100
              ).toFixed(2)}
              isReadOnly={true}
            />
          </div>
        </div>
      )}
      {formData.test_type !== "" && (
        <div>
          <div className="form-group d-flex mt-4">
            <div class="form-group w-50">
              <DateSelector
                fieldLabel="Fecha Creacion Del Test"
                fieldName="test_creation_date"
                fieldValue={formData.test_creation_date}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
            <div class="form-group ms-2 w-50">
              <DateSelector
                fieldLabel="Fecha Evaluacion Del Test"
                fieldName="test_evaluation_date"
                fieldValue={formData.test_evaluation_date}
                onChangeHandler={onChange}
                isReadOnly={isUpdating ? true : false}
              />
            </div>
          </div>
          <div className="input-group mt-4">
            <TextAreaField
              fieldLabel="Observaciones"
              fieldName="observations"
              fieldValue={formData.observations}
              onChangeHandler={onChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QualityTestFormFields;

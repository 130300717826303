import React from "react";
import { sortAlphabetically } from "../../../../../helpers/sortHelper";

function ClimateSelect({ selectedClimate, onChange, required }) {
  const climateOptions = ["frio", "templado", "calido"];

  const sortedClimateOptions = sortAlphabetically(climateOptions);

  return (
    <div className="form-group">
      <label className="form-label fw-lighter mt-3">Clima:</label>
      <select
        className="form-select fst-italic"
        value={selectedClimate}
        name="climate"
        onChange={onChange}
        required={required ? true : false}
      >
        <option value="" disabled></option>
        {sortedClimateOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ClimateSelect;

import { createAsyncThunk } from "@reduxjs/toolkit";
import seedSpecieService from "../seedSpecieApiService";

/**
 * Utility function to handle errors in asynchronous functions.
 * @param {Error} error - Captured error object.
 * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
 * @returns {Promise} - Rejected promise with the error message.
 */
const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

/**
 * Thunk to get a list of all seed species.
 * @type {AsyncThunk}
 */
export const getAllSeedSpecies = createAsyncThunk(
  "seed/listSeedSpecies",
  /**
   * Asynchronous function that retrieves a list of all seed species.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the list of seed species or rejected with an error message.
   */
  async (_, thunkAPI) => {
    try {
      return await seedSpecieService.getAllSeedSpecies();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk for registering a new seed specie.
 * @type {AsyncThunk}
 */
export const registerSeedSpecie = createAsyncThunk(
  "seed/registerSeedSpecies",
  /**
   * Asynchronous function that registers a new seed specie.
   * @param {Object} seedSpecieData - Data of the seed specie to be registered.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the data of the registered seed specie or rejected with an error message.
   */
  async (seedSpecieData, thunkAPI) => {
    try {
      return await seedSpecieService.registerSeedSpecie(seedSpecieData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk to update the data of an existing seed specie.
 * @type {AsyncThunk}
 */
export const updateSeedSpecie = createAsyncThunk(
  "seed/updateSeedSpecie",
  /**
   * Asynchronous function that updates the data of an existing seed specie.
   * @param {Object} seedSpecieData - Data of the seed specie to be updated.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise with the updated seed specie data or rejected with an error message.
   */
  async (seedSpecieData, thunkAPI) => {
    try {
      let seedSpecie = await seedSpecieService.updateSeedSpecie(seedSpecieData);
      return seedSpecie.data;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

/**
 * Thunk to delete a seed specie.
 * @type {AsyncThunk}
 */
export const deleteSeedSpecie = createAsyncThunk(
  "seed/deleteSeedSpecie",
  /**
   * Asynchronous function that deletes a seed specie.
   * @param {Object} seedSpecieData - Data of the seed specie to be deleted.
   * @param {Object} thunkAPI - Object providing additional functionalities for the Thunk middleware.
   * @returns {Promise} - Resolved promise if the deletion is successful or rejected with an error message.
   */
  async (seedSpecieData, thunkAPI) => {
    try {
      return await seedSpecieService.deleteSeedSpecie(seedSpecieData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

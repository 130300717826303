import React from "react";
import { sortAlphabetically } from "../../../../../helpers/sortHelper";
import { capitalizeFirstLetterOfWords } from "../../../../../helpers/stringsHelpers";

function SeedLocationSelect({ selectedLocation, onChange }) {
  const seedLocationOptions = [
    "bandeja de semillas",
    "refrigerador",
    "caneca 1",
    "caneca 2",
    "caneca 3",
    "caneca 4",
  ];

  const sortedSeedLocations = sortAlphabetically(seedLocationOptions);

  return (
    <div className="form-group ms-2">
      <label className="form-label fw-lighter">Ubicacion De Semilla</label>
      <select
        className="form-select fst-italic"
        name="seed_location_stuck"
        value={selectedLocation}
        onChange={onChange}
      >
        <option value="" disabled></option>
        {sortedSeedLocations.map((location, index) => (
          <option key={index} value={location}>
            {capitalizeFirstLetterOfWords(location)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SeedLocationSelect;

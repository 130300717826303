import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonSection from "../../forms/fieldSections/ButtonSection";

/**
 * BackButton Component
 *
 * This component represents a back button that uses React Router's navigation.
 *
 * @returns {JSX.Element} - JSX element representing the BackButton component.
 */
const BackButton = () => {
  const navigate = useNavigate();

  /**
   * Handle the click event to navigate back.
   */
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {/* Render a button for going back */}
      <ButtonSection text="Atras" color="grey" onClick={handleGoBack} />
    </div>
  );
};

export default BackButton;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";

/**
 * The root component of the application.
 * Responsible for setting up the React Router and rendering the main application routes.
 *
 * @returns {JSX.Element} The JSX element representing the root application component.
 */
function App() {
  /**
   * The `Router` component from `react-router-dom` is used to provide navigation capabilities to the application.
   * It wraps the main content of the application and enables the use of routing features.
   *
   * The `AppRoutes` component contains the main routes and views of the application.
   * It is responsible for rendering different components based on the current route.
   * The `AppRoutes` component is defined in the "./routes/AppRoutes" file.
   *
   * @returns {JSX.Element} The JSX element representing the root of the application.
   */
  return (
    <Router>
      {/* The `AppRoutes` component contains the main routes and views of the application. */}
      <AppRoutes />
    </Router>
  );
}

/**
 * The `App` component is exported as the default export.
 * It represents the root of the React application and sets up the main routing structure.
 */
export default App;

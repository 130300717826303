import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CustomerForm from "../forms/CustomerForm";
import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import CustomerFiltersListTable from "../tables/filters/CustomerFiltersListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

function CustomersListTable({ onSearch, onSubmitOperation, onRedirect }) {
  const { isLoading, customers } = useSelector((state) => state.customer);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {}, [isFormOpen]);

  const handleRedirect = (selectedCostumer) => {
    onRedirect(selectedCostumer.id);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleDelete = (customerData) => {
    const confirmation = window.confirm(
      "¿Seguro Que Quiere Eliminar El Comprador?"
    );
    if (confirmation) {
      onSubmitOperation("delete", customerData.subid);
    }
  };

  const handleRegister = async (customerData) => {
    await onSubmitOperation("register", customerData);

    setIsFormOpen(false);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.name),
      name: "Nombre",
    },
    { sortable: true, selector: (row) => row.email, name: "Email" },
    {
      sortable: true,
      selector: (row) => row.phone_number,
      name: "Numero Telefonico",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.address),
      name: "Direccion",
    },
  ];

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
            <div className="container">
              <TitleSection title="Compradores" />
            </div>
            <div className="w-25 d-flex justify-content-end">
              <ButtonSection
                text={"Crear Nuevo Cliente"}
                color={"yellow"}
                onClick={handleToggleForm}
              />
            </div>
          </div>
          <div>
            <CustomerFiltersListTable onFilter={onSearch} />
          </div>
          <div className="mt-4 d-flex">
            <ListTable
              data={customers}
              columns={columns}
              isLoading={isLoading}
              onDelete={handleDelete}
              onRedirect={handleRedirect}
              emptyMessage="No hay Compradores Registrados"
            />
            {isFormOpen && (
              <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
                <CustomerForm
                  isLoading={isLoading}
                  onCancel={handleToggleForm}
                  onSubmitForm={handleRegister}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomersListTable;

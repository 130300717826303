import React, { useState, useEffect } from "react";

import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";
import SeedPresentationFields from "./SeedPresentationFields";

function SeedPresentationForm({
  onCancel,
  isLoading,
  onSubmitForm,
  seedPresentation,
  fixedCostsCalculated,
}) {
  const isUpdating = Object.keys(seedPresentation).length > 0;

  const initialFormData = {
    id: seedPresentation?.id,
    created_at: seedPresentation?.created_at,
    updated_at: seedPresentation?.updated_at,
    assigned_sale_value: seedPresentation?.assigned_sale_value,
    seed_specie: seedPresentation?.seed_specie,
    weight: seedPresentation?.weight,
    percentage_profit: seedPresentation?.percentage_profit || "",
    fixed_cost_calculated: fixedCostsCalculated,
    description: seedPresentation?.description || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {}, [isUpdating]);

  const handleSeedSpecieChange = (selectedSeedSpecie) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seed_specie: selectedSeedSpecie,
    }));
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number"
        ? parseFloat(e.target.value)
        : e.target.value.toLowerCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));
  };

  return (
    <GenericAsideForm
      onCancel={onCancel}
      formData={formData}
      isLoading={isLoading}
      isUpdating={isUpdating}
      onSubmitForm={onSubmitForm}
      title={`${isUpdating ? "Actualizar" : "Registrar"} Presentacion`}
      formFieldsComponent={
        <SeedPresentationFields
          formData={formData}
          isUpdating={isUpdating}
          onChange={handleInputChange}
          handleSeedSpecieChange={handleSeedSpecieChange}
        />
      }
    />
  );
}

export default SeedPresentationForm;

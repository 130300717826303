import React from "react";
import "../../../styles/modal.css";

import { CloseIcon } from "../../../helpers/Iconos";

const Modal = ({ showModal, closeModal, children }) => {
  return (
    showModal && (
      <div className="custom-modal">
        <div className="modal-content">
          <span className="close-btn" onClick={closeModal}>
            {CloseIcon}
          </span>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextField from "../fields/textFields/TextField";
import SelectField from "../fields/selectionFields/SelectField";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const SeedSpecieSelection = ({ isReadOnly, handleChange, selectedOption }) => {
  const { seedSpecies } = useSelector((state) => state.seedSpecie);

  const [filteredSeedSpecies, setFilteredSeedSpecies] = useState(seedSpecies);
  const [searchTermSeedSpecie, setSearchTermSeedSpecie] = useState("");

  const onSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTermSeedSpecie(term);

    const filtered = seedSpecies.filter((seedSpecie) =>
      seedSpecie.seed_specie_name.toLowerCase().includes(term)
    );

    setFilteredSeedSpecies(filtered);
  };

  const getSeedSpecieName = () => {
    if (typeof selectedOption === "string") {
      return capitalizeFirstLetterOfWords(
        seedSpecies.find((specie) => specie.id === selectedOption)
          .seed_specie_name
      );
    }

    return capitalizeFirstLetterOfWords(selectedOption?.seed_specie_name);
  };

  return (
    <div className="form-group d-flex mt-4">
      {isReadOnly ? (
        <div className="w-50">
          <TextField
            fieldLabel="Especie"
            fieldValue={getSeedSpecieName()}
            isReadOnly={true}
          />
        </div>
      ) : (
        <>
          <div className="w-50">
            <SelectField
              label="Selecciona Una Especie"
              labelKey="seed_specie_name"
              handleChange={handleChange}
              optionsToShow={filteredSeedSpecies}
              selectedOption={
                typeof selectedOption == "string"
                  ? selectedOption
                  : selectedOption?.seed_specie_name
              }
            />
          </div>
          <div className="w-50 mt-4 ms-4">
            <TextField
              fieldLabel="Buscar"
              fieldValue={searchTermSeedSpecie}
              onChangeHandler={onSearchChange}
              placeholder={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SeedSpecieSelection;

import React from "react";

import ListTable from "../../common/tables/ListTable";
import { formatNumber } from "../../../helpers/formatNumber";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";

const TransactionBodyListTable = ({ isLoading, transactions }) => {
  const columnsForTable = [
    { selector: (row) => row?.seed?.seed_code, name: "Codigo" },
    {
      selector: (row) =>
        capitalizeFirstLetterOfWords(row?.seed.seed_specie?.seed_specie_name),
      name: "Especie",
    },
    { selector: (row) => `${row.quantity_grams} g`, name: "Gramos" },
    { selector: (row) => `${formatNumber(row.total_value)} $`, name: "Valor" },
  ];
  return (
    <div>
      <div className="bg-primary bg-gradient text-white px-3 rounded">
        <div>
          <p className="fs-5 fw-light">Transacciones Hijas</p>
        </div>
      </div>
      <div className="mt-2">
        <ListTable
          data={transactions}
          columns={columnsForTable}
          isLoading={isLoading}
          emptyMessage={"No Existen Transacciones Hijas"}
        />
      </div>
    </div>
  );
};

export default TransactionBodyListTable;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TransactionPurchaseBody from "./TransactionPurchaseBody";
import TransactionPurchaseHeader from "./TransactionPurchaseHeader";
import BackButton from "../../../common/navigation/buttons/BackButton";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";

const TransactionPurchaseForm = ({ onSubmitForm }) => {
  const { user } = useSelector((state) => state.auth);
  const { seedSpecies } = useSelector((state) => state.seedSpecie);

  const initialFormData = {
    user: user,
    guardian: "",
    seeds: [],
    observations: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  // MODALS LOGIC
  const [showSeedModal, setShowSeedModal] = useState(false);
  const [showTransactionOverviewModal, setShowTransactionOverviewModal] =
    useState(false);

  // SEED LOGIC
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editedSelectedSeed, setEditedSelectedSeed] = useState({});

  useEffect(() => {}, [formData]);

  // MODAL LOGIC
  const openSeedModal = () => setShowSeedModal(true);

  const closeSeedModal = () => setShowSeedModal(false);

  const openTransactionOverviewModal = () => {
    if (!formData.guardian) {
      alert("Debes Seleccionar Un Guardia");
      return;
    }

    if (formData.seeds.length < 1) {
      alert("Debes Agregar Semillas");
      return;
    }

    setShowTransactionOverviewModal(true);
  };

  const closeTransactionOverviewModal = () =>
    setShowTransactionOverviewModal(false);

  const handleGuardianChange = (selectedGuardian) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      guardian: selectedGuardian,
    }));
  };

  // SEED LOGIC
  const handleSeedDataSubmit = (seedData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: [...prevFormData.seeds, seedData],
    }));
    closeSeedModal();
  };

  const onUpdatedSeed = (editedSelectedSeed) => {
    const updatedSeedIndex = formData.seeds.findIndex(
      (seed) => seed.id === editedSelectedSeed.id
    );

    if (-1 !== updatedSeedIndex) {
      const updatedSeeds = [...formData.seeds];
      updatedSeeds[updatedSeedIndex] = {
        ...editedSelectedSeed,
        seed_specie: editedSelectedSeed.seed_specie.id,
      };

      setFormData((prevState) => ({
        ...prevState,
        seeds: updatedSeeds,
      }));
    } else {
      console.error("Elemento no encontrado para actualizar.");
    }

    setIsFormOpen(false);
  };

  const onDelete = (selectedSeed) => {
    const updatedSeeds = formData.seeds.filter(
      (seed) => seed.id !== selectedSeed.id
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: updatedSeeds,
    }));
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number"
        ? parseFloat(e.target.value)
        : e.target.value.toLowerCase();
    if (e.target.name === "observations") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: value,
      }));
      return;
    }

    setEditedSelectedSeed((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));
  };

  const handleCancelForm = () => {
    setIsFormOpen(false);
  };

  const onRedirect = (selectedSeed) => {
    setIsFormOpen(!isFormOpen);
    setEditedSelectedSeed(selectedSeed);
  };

  const handleCreateTransaction = () => {
    closeTransactionOverviewModal();

    onSubmitForm(formData);
  };

  return (
    <div className="container custom-border-medium shadow">
      <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
        <div className="container">
          <TitleSection title="Creacion De Transaccion Para La Compra De Semillas" />
        </div>
        <div>
          <BackButton />
        </div>
      </div>
      <TransactionPurchaseHeader
        formData={formData}
        openSeedModal={openSeedModal}
        handleGuardianChange={handleGuardianChange}
        openTransactionOverviewModal={openTransactionOverviewModal}
      />
      <TransactionPurchaseBody
        formData={formData}
        onDelete={onDelete}
        isFormOpen={isFormOpen}
        onRedirect={onRedirect}
        allSpecies={seedSpecies}
        showSeedModal={showSeedModal}
        onUpdatedSeed={onUpdatedSeed}
        closeSeedModal={closeSeedModal}
        seedToEdit={editedSelectedSeed}
        handleCancelForm={handleCancelForm}
        handleInputChange={handleInputChange}
        handleSeedDataSubmit={handleSeedDataSubmit}
        handleCreateTransaction={handleCreateTransaction}
        showTransactionOverviewModal={showTransactionOverviewModal}
        closeTransactionOverviewModal={closeTransactionOverviewModal}
      />
    </div>
  );
};

export default TransactionPurchaseForm;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ListTable from "../../common/tables/ListTable";
import { formatDateTime } from "../../../helpers/dateHelper";
import { getSeedsWithoutQualityTests } from "../../../store/seeds/seeds/thunks/seedThunks";
import { capitalizeFirstLetterOfWords } from "../../../helpers/stringsHelpers";

const SeedsWithoutQualityTestsTable = () => {
  const { seeds } = useSelector((state) => state.seed);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSeedsWithoutQualityTests());
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (seedData) => {
    navigate(`/seeds/detail/${seedData.id}`);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => row?.seed_code,
      name: "Codigo",
    },
    {
      sortable: true,
      selector: (row) =>
        capitalizeFirstLetterOfWords(row?.seed_specie?.seed_specie_name),
      name: "Especie",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row?.created_at),
      name: "Fecha Ingreso Semilla",
    },
    {
      cell: (row) => {
        const missingTests = [];

        if (row?.missing_quality_tests?.find((test) => test === "purity")) {
          missingTests.push("Pureza");
        }
        if (row?.missing_quality_tests?.find((test) => test === "sanitation")) {
          missingTests.push("Sanidad");
        }
        if (
          row?.missing_quality_tests?.some((test) =>
            ["vigor", "viability", "germination"].includes(test)
          )
        ) {
          missingTests.push("Vigor/Viabilidad/Germinacion");
        }

        return missingTests.join(", ");
      },
      name: "Pruebas Faltantes",
    },
  ];

  return (
    <div>
      <ListTable
        pagination
        data={seeds}
        columns={columns}
        onRedirect={handleRedirect}
        // keyField="id"
        emptyMessage="Todas Las Semillas Cuentan Con Pruebas Completas"
      />
    </div>
  );
};

export default SeedsWithoutQualityTestsTable;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import PasswordField from "../../common/forms/fields/numberFields/PasswordField";
import BackButton from "../../common/navigation/buttons/BackButton";
import TitleSection from "../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";
import LoadingSpinner from "../../common/forms/fieldSections/LoadingSpinner";
import TextField from "../../common/forms/fields/textFields/TextField";
import EmailField from "../../common/forms/fields/textFields/EmailField";

import "../../../styles/forms.css";

function UserSecurityForm({ onUpdate }) {
  const { loading, user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    username: user.username,
    email: user.email,
  });

  const [updatePassword, setUpdatePassword] = useState(false);

  const { currentPassword, newPassword, confirmNewPassword, username, email } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onTogglePasswordUpdate = () => {
    setUpdatePassword(!updatePassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (updatePassword && newPassword !== confirmNewPassword) {
      alert("Las Contraseñas No Coinciden ");
      return;
    }

    onUpdate({
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmNewPassword,
    });
  };

  return (
    <div className="container custom-border-large shadow mt-5 w-50">
      <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
        <div className="container">
          <TitleSection title="Informacion De Seguridad" />
        </div>
        <div>
          <BackButton />
        </div>
      </div>
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection
              text={updatePassword ? "Cancel" : "Actualizar Password"}
              color={updatePassword ? "red" : "blue"}
              onClick={onTogglePasswordUpdate}
            />
          </div>

          <form onSubmit={onSubmit}>
            {updatePassword && (
              <div className="container">
                <div className="form-group">
                  <PasswordField
                    fieldLabel="Password Actual"
                    fieldName="currentPassword"
                    fieldValue={currentPassword}
                    onChangeHandler={onChange}
                  />
                </div>

                <div className="form-group">
                  <PasswordField
                    fieldLabel="Nueva Password"
                    fieldName="newPassword"
                    fieldValue={newPassword}
                    onChangeHandler={onChange}
                  />
                </div>

                <div className="form-group">
                  <PasswordField
                    fieldLabel="Confirmacion Password"
                    fieldName="confirmNewPassword"
                    fieldValue={confirmNewPassword}
                    onChangeHandler={onChange}
                  />
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <ButtonSection text="Actualizar" color="green" />
                  </div>
                </div>

                <hr />
              </div>
            )}

            <div className="form-group mt-4">
              <TextField
                fieldLabel="Username"
                fieldName="username"
                fieldValue={username}
                readOnly
              />
            </div>

            <div className="form-group mt-3">
              <EmailField
                fieldLabel="Email"
                fieldName="email"
                fieldValue={email}
                readOnly
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default UserSecurityForm;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TransactionsReports from "./TransactionsReports";
import UndeliveredTransactionsTable from "./UndeliveredTransactionsTable";
import { getFilteredTransactions } from "../../../../store/transactions/thunks/transactionsThunks";

const TransactionsSummary = ({ formData }) => {
  const { transactions } = useSelector(
    (state) => state.transactions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getFilteredTransactions(
        `created_at=${formData?.dateFrom},${formData?.dateUntil}`
      )
    );
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div>
      <div>
        <TransactionsReports transactions={transactions} />
      </div>
      <div className="p-3">
        <h4 className="fw-lighter">Transacciones Pendientes Por Entregar</h4>
        <UndeliveredTransactionsTable transactions={transactions} />
      </div>
    </div>
  );
};

export default TransactionsSummary;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SeedDetailFields from "./SeedDetailFields";
import BackButton from "../../../common/navigation/buttons/BackButton";
import QualityTestForm from "../../../qualityTests/forms/QualityTestForm";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import NumberField from "../../../common/forms/fields/numberFields/NumberFields";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import QualityTestListTable from "../../../qualityTests/tables/QualityTestListTable";
import SecondaryTransactionListTable from "../../../transactions/tables/SecondaryTransactionListTable";
import SeedUsageSelect from "../../../common/forms/fields/selectionFields/SeedUsageSelect";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";
import SecondarySeedPresentationsListTable from "../../../seeds/seedPresentations/tables/SecondarySeedPresentationsListTable";

const SeedDetails = ({
  onSubmitForm,
  onSubmitOperation,
  handleRedirectToTransactionDetail,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { transactions } = useSelector((state) => state.transactions);
  const { isLoading, seed } = useSelector((state) => state.seed);
  const { fixedCostPerPresentation } = useSelector((state) => state.fixedCost);

  const initialFormData = {
    guardian: seed?.guardian,
    seed_specie: seed?.seed_specie,
    seed_code: seed?.seed_code,
    seed_location_stuck: seed?.seed_location_stuck || "",
    seed_entry_weight: seed?.seed_entry_weight || "",
    seed_current_weight: seed?.seed_current_weight || "",
    seeds_per_gram: seed?.seeds_per_gram || "",
    climate: seed?.guardian?.climate || "",
    altitude_range: seed?.guardian?.altitude_range || "",
    seed_usage: seed?.seed_usage || "pendiente",
    seed_cost_per_gram: seed?.seed_cost_per_gram || "",
    seed_observations: seed?.seed_observations || "",
    fixed_cost_per_presentation: fixedCostPerPresentation,
    created_at: seed?.created_at,
    updated_at: seed?.updated_at,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isQualityFormOpen, setIsQualityFormOpen] = useState(false);
  const [quality_test, setQualityTest] = useState(null);
  const [seedUsages, setSeedUsages] = useState([]);

  useEffect(() => {
    getSeedUsagesOptions();
    setFormData(initialFormData);
    calculatePresentationAvailability(initialFormData);
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQualityFormOpen, seed]);

  const handleQualityTestRedirect = (quality_test) => {
    setQualityTest(quality_test);
    handleToggleQualityForm();
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number"
        ? parseFloat(e.target.value)
        : e.target.value.toLowerCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = {
      ...formData,
      guardian: formData.guardian.id,
      seed_specie: formData.seed_specie.id,
    };
    onSubmitForm(formDataToSend);
  };

  const onCloseForm = () => {
    setQualityTest(null);
    handleToggleQualityForm();
  };

  const handleOpenRegisterForm = (testType) => {
    setQualityTest({ test_type: testType });
    handleToggleQualityForm();
  };

  const handleToggleQualityForm = () => {
    setIsQualityFormOpen(!isQualityFormOpen);
  };

  const handleRegisterTest = async (TestData) => {
    const action = await onSubmitOperation("register", TestData);

    if (!action.errors) {
      alert("La Prueba De Calidad Fue Creada Con Exito.");
      setIsQualityFormOpen(false);
    }
  };

  function calculatePresentationAvailability(seedData) {
    const { seed_current_weight } = seedData;
    const seed_presentations = seedData?.seed_specie?.seed_presentations;

    if (seed_presentations && seed_current_weight) {
      const updatedPresentations = seed_presentations.map((presentation) => {
        const weight = presentation?.weight || 1;
        const availability = Math.floor(seed_current_weight / weight);

        const updatedPresentation = {
          ...presentation,
          presentation_availability: Math.max(0, availability),
        };

        return updatedPresentation;
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        seed_specie: {
          ...prevFormData?.seed_specie,
          seed_presentations: updatedPresentations,
        },
      }));
    }
  }

  const getSeedUsagesOptions = () => {
    const result = ["pendiente"];

    const qualityTests = seed.quality_tests;

    let testsPerformed = {};

    qualityTests?.forEach((test) => {
      let test_info = {};
      test_info["testType"] = test["test_type"];
      test_info["evaluateDate"] = test["test_evaluation_date"];
      if (test_info["testType"] === "purity") {
        test_info["result"] = test["purity_percentage"];
        test_info["passed"] = test["purity_percentage"] >= 95 ? true : false;
      } else if (test_info["testType"] === "sanitation") {
        test_info["result"] = test["sanitization_result"];
        test_info["passed"] = test["sanitization_result"] >= 50 ? true : false;
      } else {
        test_info["result"] = test["germination_percentage"];
        test_info["passed"] =
          test["germination_percentage"] >= 50 ? true : false;
      }

      if (!testsPerformed[test["test_type"]]) {
        testsPerformed[test["test_type"]] = test_info;
      } else {
        if (
          testsPerformed[test["test_type"]]["evaluateDate"] <
          test_info["evaluateDate"]
        ) {
          testsPerformed[test["test_type"]] = test_info;
        }
      }
    });

    if (Object.keys(testsPerformed).length > 2) {
      result.push("descartar");
      result.push("retorno");

      if (validateTests(testsPerformed)) result.push("semilla");
    }

    setSeedUsages(result);
  };

  const validateTests = (tests) => {
    const allTestsPassed = Object.values(tests).every(function (value) {
      return value["passed"] === true;
    });

    return allTestsPassed;
  };

  const formatTransactions = () => {
    const transactionsArray = transactions?.map((transaction) => {
      const transactionBody = transaction?.transaction_bodies?.find(
        (body) => body.seed === seed.id
      );

      if (transactionBody) {
        return {
          ...transaction,
          transaction_quantity_grams: transactionBody?.quantity_grams,
        };
      }

      return transaction;
    });

    const sortedTransactionsArray = [
      ...transactionsArray.filter((item) => item.transaction_type === "compra"),
      ...transactionsArray.filter((item) => item.transaction_type !== "compra"),
    ];

    return sortedTransactionsArray;
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container custom-border-large shadow mt-5">
          <div className="d-flex justify-content-between bg-light text-dark">
            <div className="container">
              <TitleSection title={`Semilla ${seed?.seed_code}`} />
            </div>
            <div>
              <BackButton />
            </div>
          </div>

          <form className="p-5 pt-0" onSubmit={onSubmit}>
            <div className="d-flex justify-content-center">
              <ButtonSection text="Actualizar" color="green" />
            </div>
            <div className="mt-4 w-25">
              <SeedUsageSelect
                selectedSeedUsage={formData.seed_usage}
                onChange={handleInputChange}
                seedUsages={seedUsages}
              />
            </div>
            <div>
              <SeedDetailFields
                formData={formData}
                transactions={formatTransactions()}
                onChange={handleInputChange}
                filteredGuardians={formData?.guardian?.name}
              />
            </div>
          </form>
          <hr />
          <div className="p-5">
            <div>
              <SubtitlesSection subTitle="Semilas Disponibles" />
            </div>
            <div className="form-group">
              <div className="d-flex">
                {/* Current Weight Field */}
                <div className="form-group w-25">
                  <NumberField
                    fieldLabel="Peso Disponible"
                    fieldName="seed_current_weight"
                    fieldValue={formData.seed_current_weight}
                    isReadOnly={true}
                  />
                </div>
              </div>

              {/* ListTable for Seed Presentations */}
              <div>
                <SecondarySeedPresentationsListTable
                  data={formData}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <hr />
            <div>
              <div>
                <SubtitlesSection
                  subTitle="Historial De La Semilla"
                  className="mb-4"
                />
              </div>
              <div>
                <SecondaryTransactionListTable
                  onRedirect={handleRedirectToTransactionDetail}
                  transactions={formatTransactions()}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <hr />
            <div>
              <div>
                <SubtitlesSection subTitle="Pruebas De Calidad" />
              </div>
              <div className="mt-2">
                <QualityTestListTable
                  isLoading={isLoading}
                  qualityTests={seed?.quality_tests}
                  onRedirect={handleQualityTestRedirect}
                  onOpenRegisterForm={handleOpenRegisterForm}
                />
              </div>
            </div>
            {isQualityFormOpen && (
              <div
                className={`aside-form ${isQualityFormOpen ? "" : "closed"}`}
              >
                <QualityTestForm
                  isLoading={isLoading}
                  onCancel={onCloseForm}
                  onSubmitForm={handleRegisterTest}
                  seed={seed}
                  user={user}
                  quality_test={quality_test}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SeedDetails;

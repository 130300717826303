import React from "react";

const TransactionStatusSelection = ({
  selectedTransactionStatus,
  onChange,
  transactionStatus,
}) => {
  return (
    <div className="form-group">
      <label className="form-label fw-lighter mt-3">
        Status De La Transaccion:
      </label>
      <select
        className="form-select fst-italic"
        name="transaction_status"
        value={selectedTransactionStatus}
        onChange={onChange}
      >
        {transactionStatus.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TransactionStatusSelection;

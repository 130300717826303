import React from "react";

/**
 * ErrorMessages Component
 *
 * This component renders error messages based on the provided error or errorMessage prop.
 *
 * @param {Object} props - The component properties.
 * @param {string} props.error - Single error message to be displayed.
 * @param {Object} props.errorMessage - Object containing multiple error messages.
 * @returns {JSX.Element | null} - JSX element representing the ErrorMessages component or null if no errors.
 */
const ErrorMessages = ({ errorMessage }) => {
  if (typeof errorMessage === "string") {
    // Display a single error message
    return <p className="text-danger">{errorMessage}</p>;
  }

  if (typeof errorMessage === "object") {
    // Display multiple error messages
    return (
      <div className="mt-3">
        {Object.keys(errorMessage).map((key) =>
          errorMessage[key].map((message, index) => (
            <div key={index} className="alert alert-danger" role="alert">
              {`${message}`}
            </div>
          ))
        )}
      </div>
    );
  }

  // Return null if no errors are present
  return null;
};

export default ErrorMessages;

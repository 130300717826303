import React from "react";
import NumberField from "../../../common/forms/fields/numberFields/NumberFields";
import DecimalField from "../../../common/forms/fields/numberFields/DecimalField";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import SeedSpecieDetails from "../../../common/forms/itemDetails/SeedSpecieDetails";
import SeedSpecieSelection from "../../../common/forms/fieldSections/SeedSpecieSelection";
import SeedLocationSelect from "../../../common/forms/fields/selectionFields/SeedLocationSelect";

const SeedFormFields = ({
  formData,
  onChange,
  handleSeedSpecieChange,
  isUpdating,
}) => {
  return (
    <div>
      {isUpdating ? (
        <SeedSpecieDetails selectedSeedSpecie={formData?.seed_specie} />
      ) : (
        <div>
          <SeedSpecieSelection
            isReadOnly={isUpdating}
            selectedOption={formData?.seed_specie}
            handleChange={handleSeedSpecieChange}
          />
        </div>
      )}
      {/* Seed Characteristics */}
      <div className="form-group d-flex justify-content-evenly mt-4">
        <div className="form-group w-25">
          <SeedLocationSelect
            selectedLocation={formData.seed_location_stuck}
            onChange={onChange}
          />
        </div>
        <div className="form-group w-25">
          <DecimalField
            fieldLabel="Peso De Entrada"
            fieldName="seed_entry_weight"
            fieldValue={formData.seed_entry_weight}
            onChangeHandler={!isUpdating && onChange}
            isReadOnly={isUpdating ? true : false}
          />
        </div>
      </div>
      {/* Pricing Information */}
      <div className="form-group d-flex justify-content-evenly mt-4">
        {!isUpdating && (
          <div className="form-group w-25">
            <DecimalField
              fieldLabel="Costo Total"
              fieldName="total_cost"
              fieldValue={formData.total_cost}
              onChangeHandler={!isUpdating && onChange}
              isReadOnly={isUpdating ? true : false}
            />
          </div>
        )}
        <div className="form-group w-25">
          <NumberField
            fieldLabel="Semillas Por Gramo"
            fieldName="seeds_per_gram"
            fieldValue={formData.seeds_per_gram}
            onChangeHandler={!isUpdating && onChange}
            isReadOnly={isUpdating ? true : false}
          />
        </div>
        <div className="form-group w-25">
          <DecimalField
            fieldLabel="Costo Por Gramo"
            fieldName="seed_cost_per_gram"
            fieldValue={
              isUpdating
                ? formData.seed_cost_per_gram
                : (formData.total_cost / formData.seeds_per_gram).toFixed(2)
            }
            isReadOnly={true}
          />
        </div>
      </div>
      {/* Observations */}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="seed_observations"
          fieldValue={formData.seed_observations}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
};

export default SeedFormFields;

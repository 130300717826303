import React from "react";
import TextField from "../../../common/forms/fields/textFields/TextField";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";

const SeedGroupFields = ({ formData, onChange, onRegister, isUpdating }) => {
  return (
    <div>
      <div className="form-group">
        <TextField
          fieldLabel="Nombre Del Grupo"
          fieldName="seed_group_name"
          fieldValue={formData.seed_group_name}
          onChangeHandler={onChange}
        />
      </div>
      <div className="mt-4 w-100 d-flex justify-content-center">
        <div>
          <ButtonSection
            text={isUpdating ? "Actualizar" : "Registrar"}
            color="green"
            onClick={onRegister}
          />
        </div>
      </div>
    </div>
  );
};

export default SeedGroupFields;

import React from "react";
import { sortAlphabetically } from "../../../../../helpers/sortHelper";

function SeedUsageSelect({ selectedSeedUsage, onChange, seedUsages }) {
  const sortedSeedUsages = sortAlphabetically(seedUsages);

  return (
    <div className="form-group">
      <label className="form-label fw-lighter mt-3">Uso De Semilla:</label>
      <select
        className="form-select fst-italic"
        name="seed_usage"
        value={selectedSeedUsage}
        onChange={onChange}
      >
        {sortedSeedUsages?.map((usage, index) => (
          <option key={index} value={usage}>
            {usage}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SeedUsageSelect;

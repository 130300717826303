import { createSlice } from "@reduxjs/toolkit";
import * as qualityTestThunks from "./thunks/qualityTestThunks";

// Initial state of the qualityTest slice
const initialState = {
  qualityTests: [],
  qualityTest: {},
  count: null,
  next: null,
  previous: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

/**
 * Redux slice for handling qualityTest-related actions.
 */
const qualityTestSlice = createSlice({
  name: "qualityTest",
  initialState,
  reducers: {
    /**
     * Reset the qualityTest slice to its initial state.
     * @param {Object} state - Current state of the qualityTest slice.
     */
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Thunks related to qualityTest actions
      .addCase(qualityTestThunks.registerQualityTest.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(qualityTestThunks.registerQualityTest.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(
        qualityTestThunks.registerQualityTest.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      )
      .addCase(qualityTestThunks.getQualityTest.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(qualityTestThunks.getQualityTest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.qualityTest = action.payload;
        state.isSuccess = true;
      })
      .addCase(qualityTestThunks.getQualityTest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      // .addCase(qualityTestThunks.updateQualityTest.pending, (state) => {
      //   state.isLoading = true;
      //   state.errorMessage = "";
      // })
      // .addCase(
      //   qualityTestThunks.updateQualityTest.fulfilled,
      //   (state, action) => {
      //     state.isLoading = false;
      //     state.qualityTest = action.payload;
      //     state.isSuccess = true;
      //   }
      // )
      // .addCase(
      //   qualityTestThunks.updateQualityTest.rejected,
      //   (state, action) => {
      //     state.isLoading = false;
      //     state.isError = true;
      //     state.isSuccess = false;
      //     state.errorMessage = action.payload;
      //   }
      // )
      .addCase(qualityTestThunks.getFilteredQualityTests.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(
        qualityTestThunks.getFilteredQualityTests.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.qualityTests = action.payload;
          state.count = action.payload.count;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
        }
      )
      .addCase(
        qualityTestThunks.getFilteredQualityTests.rejected,
        (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.errorMessage = action.payload;
        }
      );
  },
});

// Export the actions from the reducers
export const { reset } = qualityTestSlice.actions;

// Export the reducer
export default qualityTestSlice.reducer;

import React from "react";

import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import InfoSection from "../../../common/forms/fieldSections/InfoSection";
import ListTable from "../../../common/tables/ListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";

const TransactionAdjustmentOverview = ({
  formData,
  onCreate,
  onChange,
  allSpecies,
}) => {
  const dataSeedToTable = formData["seeds"].map((seed) => ({
    ...seed,
    seed_specie: allSpecies.find((specie) => specie.id === seed.seed_specie.id),
  }));

  const columns = [
    { selector: (row) => row.seed_code, name: "Codigo" },
    {
      selector: (row) =>
        capitalizeFirstLetterOfWords(row.seed_specie.seed_specie_name),
      name: "Especie",
    },
    {
      selector: (row) => `${row.seed_current_weight} g`,
      name: "Peso Anterior",
    },
    { selector: (row) => `${row.newWeight} g`, name: "Peso Nuevo" },
    {
      selector: (row) =>
        `${(row.seed_current_weight - row.newWeight).toFixed(2)} g`,
      name: "Ajuste Del Peso",
    },
  ];

  return (
    <div className="container custom-border-medium shadow">
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection title="Detalles del Ajuste De Inventario" />
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Informacion Del Usuario" />
        <div className="d-flex justify-content-between">
          <div>
            <InfoSection title="Usuario Espora:" data={formData.user.email} />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <SubtitlesSection subTitle="Detalles de las Semillas" />
        <div>
          <ListTable
            data={dataSeedToTable}
            columns={columns}
            emptyMessage="No Hay Semillas Seleccionadas"
          />
        </div>
        {/* <div className="mt-4">
          <h5>
            Subtotal De Transaccion ({formData.seeds.length} items): $
            {formData.seeds.length > 0
              ? formatNumber(
                  formData.seeds.reduce(
                    (suma, obj) =>
                      suma +
                      obj.selectedPresentation.assigned_sale_value *
                        obj.numberOfSobres,
                    0
                  )
                )
              : 0}
          </h5>
        </div> */}
      </div>
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="observations"
          fieldValue={formData?.observations}
          onChangeHandler={onChange}
        />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <ButtonSection
          text="Crear Transaccion"
          color="green"
          onClick={onCreate}
        />
      </div>
    </div>
  );
};

export default TransactionAdjustmentOverview;

import React from "react";
import ListTable from "../../common/tables/ListTable";

function EsporaUsersListTable({ onDelete, onRedirect, users, loading }) {
  // Define columns for the user table
  const columns = [
    { key: "username", label: "Username" },
    { key: "email", label: "Email" },
    { key: "profile.phone_number", label: "Phone Number" },
    { key: "profile.address", label: "Address" },
  ];

  return (
    <div>
      <ListTable
        onRedirect={onRedirect}
        onDelete={onDelete}
        data={users.data}
        columns={columns}
        loading={loading}
        emptyMessage="No Hay Guardianes Disponibles"
      />
    </div>
  );
}

export default EsporaUsersListTable;

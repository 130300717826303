import React from "react";
import "../../../../../styles/forms.css";

const TextField = ({
  fieldKey,
  fieldName,
  fieldValue,
  isReadOnly,
  fieldLabel,
  onChangeHandler,
  placeholder = false,
  required,
}) => {
  return (
    <div key={fieldKey ? fieldKey : null}>
      {!placeholder && (
        <label className="form-label fw-lighter">{fieldLabel} </label>
      )}
      <input
        placeholder={placeholder ? fieldLabel : null}
        className="form-control fst-italic"
        type="text"
        name={fieldName ? fieldName : null}
        value={fieldValue ? fieldValue : ""}
        readOnly={isReadOnly ? true : false}
        onChange={onChangeHandler}
        required={required ? true : false}
      />
    </div>
  );
};

export default TextField;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import GuardianForm from "../forms/GuardianForm";
import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import GuardianFiltersListTable from "../tables/filters/GuardianFiltersListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

function GuardiansListTable({ onSearch, onSubmitOperation, onRedirect }) {
  const { isLoading, guardians } = useSelector((state) => state.guardian);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {}, [isFormOpen]);

  const handleRedirect = (selectedGuardian) => {
    onRedirect(selectedGuardian.id);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleDelete = (guardianData) => {
    const confirmation = window.confirm(
      "¿Seguro Que Quiere Eliminar El Guardian?"
    );
    if (confirmation) {
      onSubmitOperation("delete", guardianData.id);
    }
  };

  const handleRegister = async (guardianData) => {
    await onSubmitOperation("register", guardianData);

    setIsFormOpen(false);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.name),
      name: "Nombre",
    },
    { sortable: true, selector: (row) => row.email, name: "Email" },
    {
      sortable: true,
      selector: (row) => row.phone_number,
      name: "Numero Telefonico",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.vereda),
      name: "Vereda",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.municipality),
      name: "Municipio",
    },
  ];

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
            <div className="container">
              <TitleSection title="Guardianes" />
            </div>
            <div className="w-25 d-flex justify-content-end">
              <ButtonSection
                text={"Crear Nuevo Guardian"}
                color={"yellow"}
                onClick={handleToggleForm}
              />
            </div>
          </div>
          <div>
            <GuardianFiltersListTable onFilter={onSearch} />
          </div>
          <ListTable
            pagination
            data={guardians}
            columns={columns}
            isLoading={isLoading}
            onDelete={handleDelete}
            onRedirect={handleRedirect}
            emptyMessage="No hay Guardianes Registrados"
          />
          <div className="mt-4 d-flex">
            {isFormOpen && (
              <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
                <GuardianForm
                  onCancel={handleToggleForm}
                  onSubmitForm={handleRegister}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default GuardiansListTable;

import React, { useState } from "react";
import { arrowRight } from "../../../../helpers/Iconos";

function TransactionSaleSeedCard({ seed, onAddSeed }) {
  const [selectedPresentation, setSelectedPresentation] = useState("");
  const [numberOfSobres, setNumberOfSobres] = useState(0);

  const handlePresentationChange = (presentation) => {
    setSelectedPresentation(presentation);
  };

  const handleNumberOfSobresChange = (event) => {
    setNumberOfSobres(event.target.value);
  };

  const addSeed = (e) => {
    e.preventDefault();
    const presentationWeight = Number(selectedPresentation.weight);
    const currentWeight = Number(seed.seed_current_weight);

    const units = Math.floor(currentWeight / presentationWeight);

    if (!selectedPresentation) {
      alert("Selecciona una presentación antes de agregar la semilla.");
      return;
    }

    if (numberOfSobres <= 0) {
      alert("Ingresa una cantidad válida de sobres.");
      return;
    }

    if (numberOfSobres > units) {
      alert("No Tienes Suficientes Sobres.");
      return;
    }

    const seedInfo = {
      ...seed,
      selectedPresentation,
      numberOfSobres: parseInt(numberOfSobres, 10),
      presentationWeight: presentationWeight,
    };

    onAddSeed(seedInfo);
    setSelectedPresentation({});
    setNumberOfSobres(0);
  };

  return (
    <div className="container mt-2">
      <form className="card" onSubmit={addSeed}>
        <div className="card-body d-flex d-flex justify-content-between">
          <div className="w-50">
            <h4 className="card-title">{seed.seed_specie.seed_botanic_name}</h4>
            <p className="fst-italic">
              {seed.seed_specie.seed_group.seed_group_name}
            </p>
            <p className="fst-italic">{seed.seed_code}</p>
          </div>

          <div className="w-50">
            <h6 className="card-subtitle mb-2 text-muted">
              Presentaciones Disponibles
            </h6>
            <ul className="list-group">
              {seed.seed_specie.seed_presentations
                .filter(
                  ({ weight }) =>
                    Number(weight) <= Number(seed.seed_current_weight)
                )
                .sort((a, b) => Number(a.weight) - Number(b.weight))
                .map((presentation) => {
                  const presentationWeight = Number(presentation.weight);
                  const currentWeight = Number(seed.seed_current_weight);
                  const units = Math.floor(currentWeight / presentationWeight);

                  return (
                    <li
                      key={presentationWeight ?? undefined}
                      className="list-group-item"
                    >
                      <input
                        className="form-check-input me-1"
                        id={presentationWeight}
                        type="radio"
                        name="presentation"
                        value={presentation}
                        checked={
                          Number(selectedPresentation.weight) ===
                          presentationWeight
                        }
                        onChange={() => handlePresentationChange(presentation)}
                      />
                      <label
                        className="form-check-label fst-italic ms-2"
                        for={presentationWeight}
                      >
                        {presentationWeight} g {arrowRight} {units} unidades
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="form-group w-25 ms-4">
            <label>Sobres</label>
            <input
              type="number"
              className="form-control mt-2 w-50"
              placeholder="Número de Sobres"
              value={numberOfSobres}
              onChange={handleNumberOfSobresChange}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Semilla
        </button>
      </form>
    </div>
  );
}

export default TransactionSaleSeedCard;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "../fields/textFields/TextField";
import SelectField from "../fields/selectionFields/SelectField";

const GuardianSelection = ({ isReadOnly, handleChange, selectedOption }) => {
  const { guardians, isLoading } = useSelector((state) => state.guardian);

  const [filteredGuardians, setFilteredGuardians] = useState(guardians);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredGuardians(guardians);
    // to do:fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = guardians.filter((guardian) =>
      guardian.name.toLowerCase().includes(term)
    );

    setFilteredGuardians(filtered);
  };

  return (
    <div className="form-group d-flex mt-4">
      {isReadOnly ? (
        <div className="w-50">
          <TextField
            fieldLabel="Especie"
            fieldValue={selectedOption.name}
            isReadOnly={true}
          />
        </div>
      ) : (
        <>
          <div className="w-50">
            <SelectField
              label="Selecciona Un Guardian"
              labelKey="name"
              handleChange={handleChange}
              optionsToShow={filteredGuardians}
              selectedOption={selectedOption}
            />
          </div>
          <div className="w-50 mt-4 ms-4">
            <TextField
              fieldLabel="Buscar"
              fieldValue={searchTerm}
              onChangeHandler={onSearchChange}
              placeholder={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GuardianSelection;

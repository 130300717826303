import React from "react";
import GuardianSelection from "../../../common/forms/fieldSections/GuardianSelection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";

const TransactionPurchaseHeader = ({
  formData,
  openSeedModal,
  handleGuardianChange,
  openTransactionOverviewModal,
}) => {
  return (
    <div>
      <div className="col-sm-1 col-md-12">
        <GuardianSelection
          selectedOption={formData["guardian"]}
          handleChange={handleGuardianChange}
          isReadOnly={false}
        />
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="mt-4">
          <ButtonSection
            text="Agregar Semilla"
            color="green"
            onClick={openSeedModal}
            type="button"
          />
        </div>
        <div className="mt-4">
          <ButtonSection
            text="Crear Transaccion"
            color="blue"
            onClick={openTransactionOverviewModal}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionPurchaseHeader;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EsporaUserLogin from "../../../components/auth/forms/EsporaUserLogin";
import { login, getUser } from "../../../store/auth/authSlice";
import LoadingSpinner from "../../../components/common/forms/fieldSections/LoadingSpinner";

const EsporaUserLoginPage = () => {
  const { errorMessage, isLoading, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEsporaUserLogin = async (esporaUserData) => {
    const action = await dispatch(login(esporaUserData));

    if (!action.errors) {
      await dispatch(getUser());
    }
  };

  useEffect(() => {
    const redirectToHomePage = () => {
      if (isAuthenticated && !isLoading) {
        navigate("/");
      }
    };

    redirectToHomePage();
  }, [isAuthenticated, isLoading, navigate]);

  return (
    <div className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <EsporaUserLogin
          onSubmitForm={handleEsporaUserLogin}
          errorMessage={errorMessage}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default EsporaUserLoginPage;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../../styles/asideForm.css";

import { formatNumber } from "../../../../helpers/formatNumber";
import SeedPresentationsForm from "../forms/SeedPresentationsForm";
import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

function SeedPresentationListTable({ onSeedPresentationsOperation }) {
  const { seedPresentations } = useSelector((state) => state.seedPresentation);
  const { isLoading } = useSelector((state) => state.seedSpecie);

  const { fixedCostPerPresentation } = useSelector((state) => state.fixedCost);
  const { seedSpecies } = useSelector((state) => state.seedSpecie);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedSeedPresentations, setEditedSeedPresentations] = useState({});

  useEffect(() => {
    if (!isEditing) {
      setEditedSeedPresentations({});
    }
  }, [isEditing, isFormOpen]);

  const handleRedirect = (selectedSeedPresentations) => {
    setIsEditing(true);
    setIsFormOpen(!isFormOpen);
    setEditedSeedPresentations(selectedSeedPresentations);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setIsEditing(false);
  };

  const handleCancelForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
  };

  const handleDelete = (seedPresentationData) => {
    onSeedPresentationsOperation("delete", seedPresentationData);
  };

  const handleRegisterSeedPresentations = async (seedPresentationData) => {
    const operationType = isEditing ? "update" : "register";

    await onSeedPresentationsOperation(operationType, seedPresentationData);

    setIsEditing(false);
    setEditedSeedPresentations({});

    setIsFormOpen(false);
  };

  const presentationBySeedSpecie = {};
  seedPresentations.forEach((presentacion) => {
    const seedSpecieId = presentacion.seed_specie;
    const updatedPresentation = {
      ...presentacion,
      seed_specie: seedSpecies.find((specie) => specie.id === seedSpecieId),
    };

    if (!presentationBySeedSpecie[seedSpecieId]) {
      presentationBySeedSpecie[seedSpecieId] = [];
    }

    presentationBySeedSpecie[seedSpecieId].push(updatedPresentation);
  });

  const presentationsTablesBySeedSpecie = Object.keys(
    presentationBySeedSpecie
  ).map((seedSpecieId) => {
    const presentacions = presentationBySeedSpecie[seedSpecieId];
    const seedSpecie = seedSpecies.find((specie) => specie.id === seedSpecieId);

    const isLoadingbySpecie = isLoading;
    const emptyMessageForEspecie = `No hay presentaciones registradas para ${capitalizeFirstLetterOfWords(
      seedSpecie?.seed_specie_name
    )}`;

    const columns = [
      {
        sortable: true,
        selector: (row) => `${formatNumber(row.weight)} g`,
        name: "Peso De La Presentacion",
      },
      {
        sortable: true,
        selector: (row) => `${row.percentage_profit} %`,
        name: "Posentaje De Ganancia",
      },
      {
        sortable: true,
        selector: (row) =>
          `${formatNumber(
            row.seed_specie.average_cost_per_gram * row.weight +
              fixedCostPerPresentation
          )} $`,
        name: "Costo De Presentacion",
      },
      {
        sortable: true,
        selector: (row) => `${formatNumber(row.assigned_sale_value)} $`,
        name: "Precio Asignado",
      },
      {
        sortable: true,
        selector: (row) =>
          `${formatNumber(
            row.assigned_sale_value -
              (row.seed_specie.average_cost_per_gram * row.weight +
                fixedCostPerPresentation)
          )} $`,
        name: "Ganancia",
      },
    ];

    return (
      <div key={seedSpecieId} className="mt-4">
        <SubtitlesSection
          subTitle={`Presentaciones Para ${capitalizeFirstLetterOfWords(
            seedSpecie.seed_specie_name
          )}`}
        />
        <ListTable
          pagination
          data={presentacions}
          columns={columns}
          onDelete={handleDelete}
          onRedirect={handleRedirect}
          isLoading={isLoadingbySpecie}
          emptyMessage={emptyMessageForEspecie}
        />
        <hr />
      </div>
    );
  });

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between p-2 bg-light text-dark">
            <div className="container">
              <TitleSection title="Presentaciones Por Especie" />
            </div>
            <div className="w-25 d-flex justify-content-end">
              <ButtonSection
                text={isFormOpen ? "Atras" : "Registrar Presentacion"}
                color={isEditing ? "grey" : "yellow"}
                onClick={handleToggleForm}
              />
            </div>
          </div>
          <div className="mt-4 d-flex">
            <div className="w-100">{presentationsTablesBySeedSpecie}</div>

            {isFormOpen && (
              <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
                <SeedPresentationsForm
                  isLoading={isLoading}
                  onCancel={handleCancelForm}
                  seedPresentation={editedSeedPresentations}
                  onSubmitForm={handleRegisterSeedPresentations}
                  fixedCostsCalculated={fixedCostPerPresentation}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SeedPresentationListTable;

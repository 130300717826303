import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = "http://127.0.0.1:8000/api";

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

const guardianService = {
  registerGuardian: async (guardianData) => {
    const response = await axios.post(
      `${API_BASE_URL}/partners/guardians/create/`,
      guardianData,
      headers
    );

    return response.data;
  },

  getGuardian: async (id) => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/guardians/details/${id}/`,
      headers
    );
    return response.data;
  },

  getAllGuardians: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/guardians/list`,
      headers
    );
    return response.data;
  },

  getGuardiansOwed: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/guardians/list-guardians-owed`,
      headers
    );
    return response.data;
  },

  getFilteredGuardians: async (dataSearch) => {
    const url = `${API_BASE_URL}/partners/guardians/list?${dataSearch.term}&p=${dataSearch.page}`;
    const response = await axios.get(url, headers);

    return response.data;
  },

  updateGuardian: async (guardianData) => {
    const url = `${API_BASE_URL}/partners/guardians/update/${guardianData.slug}/`;

    const response = await axios.patch(url, guardianData, headers);
    return response.data;
  },

  deleteGuardian: async (id) => {
    const response = await axios.delete(
      `${API_BASE_URL}/partners/guardians/delete/${id}`,
      headers
    );
    return response.data;
  },
};

export default guardianService;

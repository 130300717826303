import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authApiService";

const initialState = {
  isAuthenticated: false,
  user: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  users: [],
};

export const register = createAsyncThunk(
  "users/register",
  async ({ username, email, password }, thunkAPI) => {
    const userData = JSON.stringify({
      email,
      username,
      password,
    });
    try {
      return await authService.register(userData);
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const getUser = createAsyncThunk("users/me", async (_, thunkAPI) => {
  try {
    let user = await authService.getUser();
    return user.data;
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const checkAuthentication = createAsyncThunk(
  "users/verify",
  async (_, thunkAPI) => {
    try {
      await authService.checkAuthentication();
      let user = await authService.getUser();
      return user.data;
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const logout = createAsyncThunk("users/logout", (_, thunkAPI) => {
  try {
    authService.logout();
  } catch (error) {
    const errorMessage =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const login = createAsyncThunk(
  "users/login",
  async ({ email, password }, thunkAPI) => {
    const userData = JSON.stringify({
      email,
      password,
    });
    try {
      return await authService.login(userData);
    } catch (error) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "users/updateProfile",
  async (userData, thunkAPI) => {
    try {
      let userProfile = await authService.updateProfile(userData);
      return userProfile.data.profile;
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Thunk to update user password
export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (userData, thunkAPI) => {
    try {
      let user = await authService.updateUserPassword(userData);
      return user.data;
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Thunk to get a list of users from Espora
export const getEsporaUsers = createAsyncThunk(
  "users/listEsporaUsers",
  async (thunkAPI) => {
    try {
      return await authService.getEsporaUsers();
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Thunk to delete a user from Espora
export const deleteEsporaUser = createAsyncThunk(
  "users/deleteEsporaUser",
  async (userData, thunkAPI) => {
    try {
      return await authService.deleteEsporaUser(userData);
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Define the Redux slice
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetRegistered: (state) => {
      state.user = null;
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.isAuthenticated = false;
      state.errorMessage = "";
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      // Case for failed user registration
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      // Case for pending user login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user login
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.isSuccess = true;
        state.isError = false;
      })
      // Case for failed user login
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      // Case for pending user data retrieval
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user data retrieval
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isSuccess = true;
      })
      // Case for failed user data retrieval
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      // Case for pending user logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user logout
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
        state.isAuthenticated = false;
      })
      // Case for failed user logout
      .addCase(logout.rejected, (state) => {
        state.isLoading = false;
      })
      // Case for pending user authentication check
      .addCase(checkAuthentication.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user authentication check
      .addCase(checkAuthentication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        state.isSuccess = true;
      })
      // Case for failed user authentication check
      .addCase(checkAuthentication.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      // Case for pending user profile update
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user profile update
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user.data.profile = action.payload;
        state.isAuthenticated = true;
        state.isSuccess = true;
      })
      // Case for failed user profile update
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      // Case for pending user password update
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful user password update
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.isSuccess = true;
        state.isError = false;
      })
      // Case for failed user password update
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      // Case for pending Espora users retrieval
      .addCase(getEsporaUsers.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful Espora users retrieval
      .addCase(getEsporaUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.isSuccess = true;
        state.users = action.payload;
      })
      // Case for failed Espora users retrieval
      .addCase(getEsporaUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      // Case for pending Espora user deletion
      .addCase(deleteEsporaUser.pending, (state) => {
        state.isLoading = true;
      })
      // Case for successful Espora user deletion
      .addCase(deleteEsporaUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.isSuccess = true;
      })
      // Case for failed Espora user deletion
      .addCase(deleteEsporaUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Export the actions from the reducers
export const { resetRegistered } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;

import React from "react";

import { capitalizeFirstLetterOfWords } from "../../../../../helpers/stringsHelpers";

const SelectField = ({
  label,
  labelKey,
  handleChange,
  optionsToShow,
  selectedOption,
}) => {
  return (
    <div className="form-floating">
      <select
        className="form-select ms-2 fst-italic"
        id={labelKey}
        value={selectedOption ? selectedOption.id : ""}
        onChange={(e) =>
          handleChange(
            optionsToShow.find((option) => option.id === e.target.value)
          )
        }
      >
        <option value="" disabled></option>
        {optionsToShow?.map((option) => (
          <option key={option.id} value={option.id}>
            {capitalizeFirstLetterOfWords(option[labelKey])}
          </option>
        ))}
      </select>
      <label htmlFor={labelKey}>{label}:</label>
    </div>
  );
};

export default SelectField;

import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = "http://127.0.0.1:8000/api";

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

const customerService = {
  registerCustomer: async (customerData) => {
    const response = await axios.post(
      `${API_BASE_URL}/partners/customers/create/`,
      customerData,
      headers
    );

    return response.data;
  },

  getCustomer: async (id) => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/customers/details/${id}/`,
      headers
    );
    return response.data;
  },

  getAllCustomers: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/customers/list`,
      headers
    );
    return response.data;
  },

  getCustomersOwing: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/partners/customers/list-customers-owing`,
      headers
    );

    return response.data;
  },

  getFilteredCustomers: async (dataSearch) => {
    const url = `${API_BASE_URL}/partners/customers/list?${dataSearch.term}&p=${dataSearch.page}`;
    const response = await axios.get(url, headers);

    return response.data;
  },

  updateCustomer: async (customerData) => {
    const url = `${API_BASE_URL}/partners/customers/update/${customerData.slug}/`;

    const response = await axios.patch(url, customerData, headers);
    return response.data;
  },

  deleteCustomer: async (id) => {
    const response = await axios.delete(
      `${API_BASE_URL}/partners/customers/delete/${id}`,
      headers
    );
    return response.data;
  },
};

export default customerService;

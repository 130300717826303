import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TransactionSaleBody from "./TransactionSaleBody";
import TransactionSaleAside from "./TransactionSaleAside";
import TransactionSaleHeader from "./TransactionSaleHeader";
import BackButton from "../../../common/navigation/buttons/BackButton";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

const TransactionSaleForm = ({ onSubmitForm }) => {
  const { user } = useSelector((state) => state.auth);
  const { seeds, isLoading } = useSelector((state) => state.seed);
  const { seedSpecies } = useSelector((state) => state.seedSpecie);

  const navigate = useNavigate();

  const initialFormData = {
    user: user,
    customer: "",
    seeds: [],
    seed_specie: {},
    observations: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  // SEED LOGIC
  const [seedsCopy, setSeedsCopy] = useState(seeds);
  const [seedsFiltered, setSeedsFiltered] = useState([]);

  // MODALS LOGIC
  const [showTransactionOverviewModal, setShowTransactionOverviewModal] =
    useState(false);

  useEffect(() => {}, [formData, seedsCopy]);

  // MODAL LOGIC
  const openTransactionOverviewModal = () => {
    if (!formData.customer) {
      alert("Debes Seleccionar Un Cliente");
      return;
    }

    if (formData.seeds.length < 1) {
      alert("Debes Agregar Semillas");
      return;
    }

    setShowTransactionOverviewModal(true);
  };

  const closeTransactionOverviewModal = () =>
    setShowTransactionOverviewModal(false);

  const handleCustomerChange = (selectedCustomer) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      customer: selectedCustomer,
    }));
  };

  const handleSeedSpecieChange = (selectedSeedSpecie) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seed_specie: { ...selectedSeedSpecie },
    }));
    onChangeSpecie(selectedSeedSpecie?.seed_specie_name);
  };

  const onChangeSpecie = (selectedSeedSpecie) => {
    const newSeedSelectedArray = seedsCopy.filter((seed) => {
      return seed?.seed_specie?.seed_specie_name === selectedSeedSpecie;
    });

    setSeedsFiltered(newSeedSelectedArray);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const calculateSeedUnits = (
    currentWeight,
    presentationWeight,
    numberOfSobres
  ) => {
    return Math.floor(currentWeight / presentationWeight) >= numberOfSobres;
  };

  const updateSeedArrays = (
    seedsCopy,
    updatedSeed,
    setSeedsCopy,
    setFormData
  ) => {
    const updatedAllSeedsCopy = seedsCopy.map((seed) =>
      seed.id === updatedSeed.id ? updatedSeed : seed
    );

    setSeedsCopy(updatedAllSeedsCopy);

    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: [...prevFormData.seeds, updatedSeed],
    }));
  };

  const handleAddSeed = (data) => {
    const presentationWeight = Number(data.selectedPresentation.weight);
    const currentWeight = Number(data.seed_current_weight);
    const units = calculateSeedUnits(
      currentWeight,
      presentationWeight,
      data.numberOfSobres
    );

    if (units) {
      const updatedSeed = {
        ...data,
        seed_current_weight: (
          currentWeight -
          presentationWeight * data.numberOfSobres
        ).toFixed(2),
      };

      updateSeedArrays(seedsCopy, updatedSeed, setSeedsCopy, setFormData);
    } else {
      alert("No hay suficientes semillas disponibles");
    }
  };

  const handleDeleteSeed = (seedToDelete) => {
    const newSeedSelectedArray = seedsCopy.filter((seed) => {
      return seed.id !== seedToDelete.id;
    });

    const updatedAllSeedsCopy = seedsCopy.map((seed) =>
      seed.id === seedToDelete.id
        ? {
            ...seed,
            seed_current_weight:
              Number(seed.seed_current_weight) +
              Number(seedToDelete.numberOfSobres) *
                Number(seedToDelete.presentationWeight),
          }
        : seed
    );

    setSeedsCopy(updatedAllSeedsCopy);

    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: newSeedSelectedArray,
    }));
  };

  const handleCreateTransaction = () => {
    closeTransactionOverviewModal();

    if (!formData.customer) {
      alert("Debes Seleccionar Un Cliente");
      return;
    }

    if (formData.seeds.length < 1) {
      alert("No Tienes Semillas Agregadas");
      return;
    }

    onSubmitForm(formData);
    alert("La Transaccion Se Creo Exitosamente.");

    navigate(`/transactions/create/`);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex justify-content-start">
          <div className="border border-2 rounded-4 shadow">
            <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
              <div className="container">
                <TitleSection title="Creacion De Transaccion Para La Venta De Semillas" />
              </div>
              <div>
                <BackButton />
              </div>
            </div>
            <div className="p-3">
              <TransactionSaleHeader
                formData={formData}
                handleCustomerChange={handleCustomerChange}
              />
              <hr />
              <TransactionSaleBody
                seeds={
                  Object.keys(formData?.seed_specie).length > 0
                    ? seedsFiltered
                    : seedsCopy
                }
                formData={formData}
                addSeed={handleAddSeed}
                allSpecies={seedSpecies}
                onChange={handleInputChange}
                handleSeedSpecieChange={handleSeedSpecieChange}
                showTransactionOverviewModal={showTransactionOverviewModal}
                closeTransactionOverviewModal={closeTransactionOverviewModal}
                handleCreateTransaction={handleCreateTransaction}
              />
            </div>
          </div>
          {!showTransactionOverviewModal && (
            <div className="aside-form w-25">
              <TransactionSaleAside
                data={formData}
                onSubmit={handleCreateTransaction}
                onDeleteItem={handleDeleteSeed}
                openTransactionOverviewModal={openTransactionOverviewModal}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionSaleForm;

import React, { useEffect } from "react";
import "../../../../styles/forms.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SeedsListTable from "../../../../components/seeds/seeds/tables/SeedsListTable";
import { getFilteredSeeds } from "../../../../store/seeds/seeds/thunks/seedThunks";

function SeedListTablePage() {
  //to do:
  //const [searchTerm, setSearchTerm] = useState({ term: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(getFilteredSeeds({ term: "" }))]);
    };

    fetchData();
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getAllSeedsByPage = async (newPage) => {
  //   const updatedSearchTerm = { ...searchTerm, page: newPage };
  //   setSearchTerm(updatedSearchTerm);
  // };

  // const handleSearch = (term) => {
  //   let newTerm = Object.keys(term)
  //     .map((key, index) =>
  //       index === 0 ? `${key}=${term[key]}` : `&${key}=${term[key]}`
  //     )
  //     .join("");

  //   const updatedSearchTerm = { ...searchTerm, term: newTerm };
  //   setSearchTerm(updatedSearchTerm);
  // };

  const handleRedirectToDetail = async (selectedSeed) => {
    navigate(`/seeds/detail/${selectedSeed.id}`);
  };

  return (
    <div className="container custom-border-large">
      <div className="mt-4">
        <SeedsListTable onRedirect={handleRedirectToDetail} />
      </div>
    </div>
  );
}

export default SeedListTablePage;

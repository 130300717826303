import React from "react";
import TextField from "../../../common/forms/fields/textFields/TextField";
import NumberField from "../../../common/forms/fields/numberFields/NumberFields";
import TextAreaField from "../../../common/forms/fields/textFields/TextAreaField";
import SeedGroupSelection from "../../../common/forms/fieldSections/SeedGroupSelection";
import DateTimeFieldsSection from "../../../common/forms/fieldSections/DateTimeFieldsSection";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const SeedSpecieFields = ({
  formData,
  onChange,
  isUpdating,
  handleSeedGroupChange,
}) => {
  return (
    <div>
      <div className="col-sm-1 col-md-12">
        <SeedGroupSelection
          isReadOnly={isUpdating}
          handleChange={handleSeedGroupChange}
          selectedSeedGroup={formData.seed_group}
        />
      </div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Nombre De La Especie"
          fieldName="seed_specie_name"
          fieldValue={capitalizeFirstLetterOfWords(formData?.seed_specie_name)}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group mt-4">
        <TextField
          fieldLabel="Nombre Botanico"
          fieldName="seed_botanic_name"
          fieldValue={capitalizeFirstLetterOfWords(formData?.seed_botanic_name)}
          onChangeHandler={onChange}
          required={true}
        />
      </div>
      <div className="form-group d-flex justify-content-evenly mt-4">
        <div className="form-group">
          <NumberField
            fieldLabel="Semillas Promedio Por Gramo"
            fieldName="average_seeds_per_gram"
            fieldValue={formData.average_seeds_per_gram}
            onChangeHandler={onChange}
            required={true}
          />
        </div>
        <div className="form-group ms-2">
          <NumberField
            fieldLabel="Costo Promedio Por Gramo"
            fieldName="average_cost_per_gram"
            fieldValue={formData.average_cost_per_gram}
            onChangeHandler={onChange}
            required={true}
          />
        </div>
      </div>
      <div className="form-group d-flex justify-content-evenly mt-4">
        <div className="form-group">
          <NumberField
            fieldLabel="Preservacion De Especie"
            fieldName="species_preservation"
            fieldValue={formData.species_preservation}
            onChangeHandler={onChange}
          />
        </div>
        <div className="form-group ms-2">
          <NumberField
            fieldLabel="Mantenimiento De Variedad"
            fieldName="variety_maintenance"
            fieldValue={formData.variety_maintenance}
            onChangeHandler={onChange}
          />
        </div>
      </div>
      {isUpdating && (
        <div>
          <DateTimeFieldsSection
            created_at={formData.created_at}
            updated_at={formData.updated_at}
          />
        </div>
      )}
      {/* Observations */}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Descripcion"
          fieldName="description"
          fieldValue={formData.description}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
};

export default SeedSpecieFields;

import { createAsyncThunk } from "@reduxjs/toolkit";
import guardianService from "../guardianApiService";

export const handleError = (error, thunkAPI) => {
  const errorMessage =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  return thunkAPI.rejectWithValue(errorMessage);
};

export const registerGuardian = createAsyncThunk(
  "guardian/register",

  async (guardianData, thunkAPI) => {
    try {
      return await guardianService.registerGuardian(guardianData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getGuardian = createAsyncThunk(
  "guardian/getGuardian",

  async (guardianData, thunkAPI) => {
    try {
      let guardian = await guardianService.getGuardian(guardianData);
      return guardian;
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const updateGuardian = createAsyncThunk(
  "guardian/updateGuardian",

  async (guardianData, thunkAPI) => {
    try {
      return await guardianService.updateGuardian(guardianData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getAllGuardians = createAsyncThunk(
  "guardian/listGuardians",

  async (thunkAPI) => {
    try {
      return await guardianService.getAllGuardians();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getGuardiansOwed = createAsyncThunk(
  "guardian/getGuardiansOwed",

  async (thunkAPI) => {
    try {
      return await guardianService.getGuardiansOwed();
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const getFilteredGuardians = createAsyncThunk(
  "guardian/listFilteredGuardians",

  async (dataSearch, thunkAPI) => {
    try {
      return await guardianService.getFilteredGuardians(dataSearch);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

export const deleteGuardian = createAsyncThunk(
  "users/deleteGuardian",

  async (guardianData, thunkAPI) => {
    try {
      return await guardianService.deleteGuardian(guardianData);
    } catch (error) {
      return handleError(error, thunkAPI);
    }
  }
);

import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = "http://127.0.0.1:8000/api";

const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

const seedService = {
  registerSeed: async (seedData) => {
    const response = await axios.post(
      `${API_BASE_URL}/seeds/create/`,
      seedData,
      headers
    );

    return response.data;
  },

  getSeed: async (id) => {
    const response = await axios.get(
      `${API_BASE_URL}/seeds/details/${id}/`,
      headers
    );
    return response.data;
  },

  getSeedsWithoutQualityTests: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/seeds/list-seeds-not-quality-tests/`,
      headers
    );
    return response.data;
  },

  getFilteredSeeds: async (dataSearch) => {
    const url = `${API_BASE_URL}/seeds/list?${dataSearch.term}`;
    const response = await axios.get(url, headers);

    return response.data;
  },

  updateSeed: async (seedData) => {
    const url = `${API_BASE_URL}/seeds/update/${seedData.slug}/`;
    const response = await axios.patch(url, seedData, headers);
    return response.data;
  },
};

export default seedService;

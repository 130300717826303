import React from "react";
import TextField from "../fields/textFields/TextField";
import { formatDateTime } from "../../../../helpers/dateHelper";

function DateTimeFieldsSection({ created_at, updated_at }) {
  const dateFields = [
    { label: "Creation Date", value: formatDateTime(created_at) || "" },
    { label: "Last Update Date", value: formatDateTime(updated_at) || "" },
  ];

  return (
    <div className="form-group d-flex justify-content-evenly mt-4">
      {dateFields.map((field, index) => (
        <TextField
          key={index}
          fieldKey={field.label}
          fieldLabel={field.label}
          fieldValue={field.value}
          isReadOnly={true}
        />
      ))}
    </div>
  );
}

export default DateTimeFieldsSection;

import React, { useState } from "react";
import EsporaUserLoginFields from "./EsporaUserLoginFields";
import ErrorMessages from "../../common/forms/fieldSections/ErrorMessages";
import LoadingSpinner from "../../common/forms/fieldSections/LoadingSpinner";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";

const EsporaUserLogin = ({ onSubmitForm, isLoading, errorMessage }) => {
  const initialFormData = {
    email: "",
    password: "",
    error: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSubmitForm({
      email: formData.email,
      password: formData.password,
    });
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center min-vh-100 py-5 row">
        <div className="col-xl-6 col-xxl-4 col-lg-6 col-md-8 col-sm-10 custom-border shadow">
          <div className="text-center mb-4">
            <h2>Bienvenido A Espora Tools</h2>
            <hr />
            <h3>Loggeate</h3>
            <p>Ingresa Con Tu Usuario</p>
          </div>
          <hr className="bg-200 mt-4 mb-4" />
          <form onSubmit={onSubmit}>
            <div>
              <EsporaUserLoginFields
                formData={formData}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <ErrorMessages
                error={formData.error}
                errorMessage={errorMessage}
              />
            </div>

            {isLoading ? (
              <div>
                <LoadingSpinner />
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-4">
                <ButtonSection text="Ingresar" color="blue" />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EsporaUserLogin;

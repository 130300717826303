import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import SeedPresentationListTablePage from "../containers/pages/seeds/seedPresentations/SeedPresentationListTablePage";

/**
 * Defines the routes related to seed groups within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain seed-group-related routes.
 * @returns {JSX.Element} The rendered seed group routes.
 */
const SeedPresentationRoutes = () => (
  <Routes>
    {/* Route for viewing a list of groups of seeds (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <SeedPresentationListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default SeedPresentationRoutes;

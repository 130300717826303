import axios from "axios";
import Cookies from "js-cookie";

// Define API base URL
const API_BASE_URL = "http://127.0.0.1:8000/api";

// Centralized headers for API requests
const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

/**
 * Seed API Service for handling Seed-related operations.
 */
const seedGroupService = {
  /**
   * Retrieves a list of all Seed Groups.
   * @returns {Promise} A promise that resolves to the list of Seed Groups.
   */
  getAllSeedGroups: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/seeds/seed-groups/list/`,
      headers
    );
    return response.data;
  },

  /**
   * Registers a new Seed Group.
   * @param {Object} seedGroupData - Data for registering a new Seed Group.
   * @returns {Promise} A promise that resolves to the registered Seed Group data.
   */
  registerSeedGroup: async (seedGroupData) => {
    const response = await axios.post(
      `${API_BASE_URL}/seeds/seed-groups/create/`,
      seedGroupData,
      headers
    );

    return response.data;
  },

  /**
   * Updates the data of an existing Seed Group.
   * @param {Object} seedGroupData - Updated data for the Seed Group.
   * @returns {Promise} A promise that resolves to the updated Seed Group data.
   */
  updateSeedGroup: async (seedGroupData) => {
    const url = `${API_BASE_URL}/seeds/seed-groups/update/${seedGroupData.id}/`;
    const response = await axios.patch(url, seedGroupData, headers);
    return response.data;
  },

  /**
   * Deletes a Seed Group by ID.
   * @param {Object} seedGroupData - Data specifying the Seed Group to be deleted.
   * @returns {Promise} A promise that resolves when the Seed Group is successfully deleted.
   */
  deleteSeedGroup: async (seedGroupData) => {
    const url = `${API_BASE_URL}/seeds/seed-groups/delete/${seedGroupData.id}/`;
    const response = await axios.delete(url, headers);
    return response.data;
  },
};

export default seedGroupService;

import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";

import TransactionsListTablePage from "../containers/pages/transactions/TransactionsListTablePage";
import TransactionsCreatePage from "../containers/pages/transactions/TransactionsCreatePage";
import TransactionsDetailsPage from "../containers/pages/transactions/TransactionsDetailsPage";
import TransactionCreatePurchasePage from "../containers/pages/transactions/TransactionCreatePurchasePage";
import TransactionCreateSalePage from "../containers/pages/transactions/TransactionCreateSalePage";
import TransactionCreateAdjustmentInventoryPage from "../containers/pages/transactions/TransactionCreateAdjustmentInventoryPage";

const TransactionRoutes = () => (
  <Routes>
    <Route
      path="detail/:slug"
      element={
        <PrivateRoute>
          <TransactionsDetailsPage />
        </PrivateRoute>
      }
    />

    <Route
      path="create/"
      element={
        <PrivateRoute>
          <TransactionsCreatePage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing a create of purchase transaction (requires authentication) */}
    <Route
      path="create/purchase"
      element={
        <PrivateRoute>
          <TransactionCreatePurchasePage />
        </PrivateRoute>
      }
    />
    {/* Route for viewing a create of sale transaction (requires authentication) */}
    <Route
      path="create/sale"
      element={
        <PrivateRoute>
          <TransactionCreateSalePage />
        </PrivateRoute>
      }
    />
    {/* Route for viewing a create of adjustment inventory transaction (requires authentication) */}
    <Route
      path="create/adjustment_inventory"
      element={
        <PrivateRoute>
          <TransactionCreateAdjustmentInventoryPage />
        </PrivateRoute>
      }
    />
    {/* Route for viewing a list of transactions (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <TransactionsListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default TransactionRoutes;

import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "../containers/pages/dashboard/DashboardPage";
import PrivateRoute from "../hocs/PrivateRoute";

const DashboardRoutes = () => (
  <Routes>
    <Route
      path=""
      element={
        <PrivateRoute>
          <DashboardPage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default DashboardRoutes;

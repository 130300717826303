import React, { useEffect } from "react";
import Toolbar from "../components/common/navigation/Toolbar";
import Navbar from "../components/common/navigation/Navbar";

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-2">
      {/* Render the top navigation bar */}
      <Navbar />

      {/* Main content container */}
      <div className="container-fluid">
        <div className="row">
          {/* Sidebar navigation */}
          <nav className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <div className="position-sticky ">
              {/* Render the toolbar within the sidebar */}
              <Toolbar />
            </div>
          </nav>

          {/* Main content area */}
          <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4">
            {/* Container for the main content with top margin */}
            <div className="p-4 mt-4">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;

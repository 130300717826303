import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import CustomerDetailPage from "../containers/pages/partners/customers/CustomerDetailPage";
import CustomersListTablePage from "../containers/pages/partners/customers/CustomersListTablePage";

/**
 * Defines the routes related to Customers within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain Customer-related routes.
 * @returns {JSX.Element} The rendered Customer routes.
 */
const CustomerRoutes = () => (
  <Routes>
    {/* Route for viewing details of a specific Customer (requires authentication) */}
    <Route
      path="detail/:slug"
      element={
        <PrivateRoute>
          <CustomerDetailPage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing a list of Customers (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <CustomersListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default CustomerRoutes;

import React from "react";

import SeedFormFields from "./SeedFormFields";
import GuardianDetails from "../../../common/forms/itemDetails/GuardianDetails";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import DateTimeFieldsSection from "../../../common/forms/fieldSections/DateTimeFieldsSection";

const SeedDetailFields = ({
  formData,
  onChange,
  filteredGuardians,
  filteredSeedSpecies,
  isEditingForm,
}) => {
  return (
    <div>
      {/* General Information Section */}
      {!isEditingForm && (
        <div className="mt-4">
          <SubtitlesSection subTitle="Informacion General" />
        </div>
      )}
      <GuardianDetails selectedGuardian={formData?.guardian} />
      <SeedFormFields
        isUpdating={true}
        formData={formData}
        onChange={onChange}
        filteredGuardians={filteredGuardians}
        filteredSeedSpecies={filteredSeedSpecies}
      />
      {!isEditingForm && (
        <>
          <hr />
          <div>
            <div className="mt-2">
              <SubtitlesSection subTitle="Fechas" />
            </div>
            <div>
              <DateTimeFieldsSection
                created_at={formData.created_at}
                updated_at={formData.updated_at}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SeedDetailFields;

import React, { useState } from "react";
import "../../../../styles/forms.css";
import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";
import GuardianFormFields from "./GuardianFormFields";
import ErrorMessages from "../../../common/forms/fieldSections/ErrorMessages";

const GuardianForm = ({ onSubmitForm, errorMessage, onCancel }) => {
  const initialFormData = {
    name: "",
    email: "",
    document: "",
    phone_number: "+57",
    vereda: "",
    municipality: "",
    climate: "",
    altitude_range: "",
    have_rut: true,
    partner_type: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitForm(formData);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <GenericAsideForm
      title="Register Guardian"
      onCancel={handleCancel}
      formData={formData}
      errorMessage={errorMessage}
      onSubmitForm={onSubmitForm}
      formFieldsComponent={
        <>
          <GuardianFormFields
            formData={formData}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
          />
          <ErrorMessages error={formData.error} errorMessage={errorMessage} />
        </>
      }
    />
  );
};

export default GuardianForm;

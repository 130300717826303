import React, { useState, useEffect } from "react";
import "../../../../styles/asideForm.css";
import { useSelector } from "react-redux";

import SeedSpecieForm from "../forms/SeedSpecieForm";
import ListTable from "../../../common/tables/ListTable";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

function SeedSpeciesListTable({ onSeedSpecieOperation }) {
  const { seedSpecies } = useSelector((state) => state.seedSpecie);
  const { seedGroups, isLoading } = useSelector((state) => state.seedGroup);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState({});

  useEffect(() => {
    if (!isEditing) {
      setEditedItem({});
    }
  }, [isEditing, isFormOpen]);

  const handleRedirect = (selectedItem) => {
    setIsEditing(true);
    setIsFormOpen(!isFormOpen);
    setEditedItem(selectedItem);
  };

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
    setIsEditing(false);
  };

  const handleCancelForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
  };

  const handleDelete = (itemData) => {
    onSeedSpecieOperation("delete", itemData);
  };

  const handleRegisterItem = async (itemData) => {
    const operationType = isEditing ? "update" : "register";

    await onSeedSpecieOperation(operationType, itemData);

    setIsEditing(false);
    setEditedItem({});

    setIsFormOpen(false);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.seed_specie_name),
      name: "Nombre De Especie",
    },
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.seed_botanic_name),
      name: "Nombre Botanico",
    },
    {
      sortable: true,
      selector: (row) => row.average_seeds_per_gram,
      name: "Semillas X Gramo (prom)",
    },
    {
      sortable: true,
      selector: (row) => `${row.average_cost_per_gram} $`,
      name: "Costo X Gramo (prom)",
    },
    {
      sortable: true,
      selector: (row) => row.species_preservation,
      name: "Preservacion Especie",
    },
    {
      sortable: true,
      selector: (row) => row.variety_maintenance,
      name: "Mantenimiento Variedad",
    },
  ];

  const seedSpecieBySeedGroup = {};
  seedSpecies.forEach((seedSpecie) => {
    const seedGroupId = seedSpecie.seed_group.id;

    if (!seedSpecieBySeedGroup[seedGroupId]) {
      seedSpecieBySeedGroup[seedGroupId] = [];
    }

    seedSpecieBySeedGroup[seedGroupId].push(seedSpecie);
  });

  const seedSpecieTablesBySeedGroup = Object.keys(seedSpecieBySeedGroup).map(
    (seedGroupId) => {
      const seedSpecies = seedSpecieBySeedGroup[seedGroupId];
      const seedGroup = seedGroups.find(
        (seedGroup) => seedGroup && seedGroup.id === seedGroupId
      );

      const isLoadingbySeedGroup = isLoading;
      const emptyMessageForSeedGroup = `No hay presentaciones registradas para ${capitalizeFirstLetterOfWords(
        seedGroup?.seed_group_name
      )}`;

      return (
        <div key={seedGroupId} className="mt-4">
          <SubtitlesSection
            subTitle={`Grupo ${capitalizeFirstLetterOfWords(
              seedGroup?.seed_group_name
            )}`}
          />
          <ListTable
            pagination
            data={seedSpecies}
            columns={columns}
            onDelete={handleDelete}
            onRedirect={handleRedirect}
            isLoading={isLoadingbySeedGroup}
            emptyMessage={emptyMessageForSeedGroup}
          />
          <hr />
        </div>
      );
    }
  );

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-between p-2 bg-light text-dark">
            <div className="container">
              <TitleSection title="Especies De Semilla" />
            </div>
            <div className="w-25 d-flex justify-content-end">
              <ButtonSection
                text={isFormOpen ? "Atras" : "Registrar Especie"}
                color={isEditing ? "grey" : "yellow"}
                onClick={handleToggleForm}
              />
            </div>
          </div>
          <div className="mt-4 d-flex">
            <div className="w-100">{seedSpecieTablesBySeedGroup}</div>

            {isFormOpen && (
              <div className={`aside-form ${isFormOpen ? "" : "closed"}`}>
                <SeedSpecieForm
                  onSubmitForm={handleRegisterItem}
                  selectedItem={editedItem}
                  seedGroups={seedGroups}
                  isLoading={isLoading}
                  onCancel={handleCancelForm}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SeedSpeciesListTable;

import React, { useState, useEffect } from "react";

import SeedSpecieFields from "./SeedSpecieFields";
import ListTable from "../../../common/tables/ListTable";
import { formatNumber } from "../../../../helpers/formatNumber";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import GenericAsideForm from "../../../common/forms/genericForms/GenericAsideForm";

const SeedSpecieForm = ({
  onCancel,
  isLoading,
  seedGroups,
  selectedItem,
  onSubmitForm,
}) => {
  const isUpdating = Object.keys(selectedItem).length > 0;

  const initialFormData = {
    id: selectedItem?.id,
    created_at: selectedItem?.created_at,
    updated_at: selectedItem?.updated_at,
    seed_group: selectedItem?.seed_group,
    description: selectedItem?.description,
    seed_specie_name: selectedItem?.seed_specie_name || "",
    seed_botanic_name: selectedItem?.seed_botanic_name || "",
    variety_maintenance: selectedItem?.variety_maintenance,
    species_preservation: selectedItem?.species_preservation,
    average_cost_per_gram: selectedItem?.average_cost_per_gram,
    average_seeds_per_gram: selectedItem?.average_seeds_per_gram,
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {}, [selectedItem]);

  const handleSeedGroupChange = (selectedSeedGroup) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seed_group: selectedSeedGroup.id,
    }));
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "number"
        ? parseFloat(e.target.value)
        : e.target.value.toLowerCase();
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: value,
    }));
  };

  const handleCancel = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitForm(formData);
  };

  const columns = [
    { selector: (row) => `${formatNumber(row.weight)} g`, name: "Peso" },
    { selector: (row) => `${row.percentage_profit} %`, name: "% Ganancia" },
    {
      selector: (row) => `${formatNumber(row.assigned_sale_value)} $`,
      name: "Precio Asignado",
    },
  ];

  return (
    <GenericAsideForm
      title={`${isUpdating ? "Actualizar" : "Registrar"} Especie De Semilla`}
      onCancel={handleCancel}
      isLoading={isLoading}
      formData={formData}
      onSubmitForm={onSubmitForm}
      isUpdating={isUpdating}
      formFieldsComponent={
        <>
          {/* Seed Specie Information Form */}
          <SeedSpecieFields
            formData={formData}
            onChange={handleInputChange}
            handleSeedGroupChange={handleSeedGroupChange}
            onSubmit={handleSubmit}
            isUpdating={isUpdating}
            seedGroups={seedGroups}
          />

          {isUpdating && (
            <>
              <div className="mt-4">
                <SubtitlesSection subTitle="Presentaciones Disponibles" />
                <ListTable
                  data={selectedItem?.seed_presentations}
                  columns={columns}
                  isLoading={isLoading}
                  emptyMessage="No Hay Presentaciones Disponibles"
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default SeedSpecieForm;

import React from "react";

import { formatDateTime } from "../../../helpers/dateHelper";
import SubtitlesSection from "../../common/forms/fieldSections/SubtitlesSection";
import DateSelector from "../../common/forms/fields/selectionFields/DateSelector";
import AccountSelection from "../../common/forms/fieldSections/AccountSelection";
import TextAreaField from "../../common/forms/fields/textFields/TextAreaField";

const TransactionDetailFields = ({ formData, onChange }) => {
  return (
    <div>
      {/* Informacion General */}
      <div className="mt-4">
        <SubtitlesSection subTitle="Informacion General" />
      </div>
      <div className="d-flex justify-content-around">
        <div>
          <h6 className="fw-lighter">Tipo De Transaccion</h6>
          <p className="fst-italic">{formData?.transaction_type}</p>
        </div>
        {formData?.transaction_type !== "ajuste" && (
          <>
            <div>
              <AccountSelection
                selectedTransactionAccount={formData?.associated_account}
                onChange={onChange}
              />
            </div>
            <div class="form-group">
              <DateSelector
                fieldLabel="Fecha Del Pago"
                fieldName="payment_date"
                fieldValue={formData.payment_date}
                onChangeHandler={onChange}
              />
              <p className="fst-italic fw-lighter mt-2">
                {formData?.payment_date ? "realizado" : "pendiente"}
              </p>
            </div>
            <div class="form-group">
              <DateSelector
                fieldLabel="Fecha De Entregado"
                fieldName="delivery_date"
                fieldValue={formData.delivery_date}
                onChangeHandler={onChange}
              />
              <p className="fst-italic fw-lighter mt-2">
                {formData?.payment_date ? "realizado" : "pendiente"}
              </p>
            </div>
          </>
        )}
      </div>
      <hr />
      <div>
        <div className="d-flex justify-content-around">
          <div>
            <h6 className="fw-lighter">Transaccion Creada Por: </h6>
            <p className="fst-italic">{formData?.created_by?.email}</p>
          </div>
          <div>
            <h6 className="fw-lighter">Fecha De Creacion: </h6>
            <p className="fst-italic">{formatDateTime(formData?.created_at)}</p>
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div>
            <h6 className="fw-lighter">Ultima Actualizacion Por: </h6>
            <p className="fst-italic">{formData?.modified_by?.email}</p>
          </div>
          <div>
            <h6 className="fw-lighter">Fecha De Actualizacion: </h6>
            <p className="fst-italic">{formatDateTime(formData?.updated_at)}</p>
          </div>
        </div>
      </div>

      {/* Informacion Del Partner */}
      {formData?.transaction_type !== "ajuste" && (
        <>
          <hr />
          <div className="mt-4">
            <SubtitlesSection subTitle="Informacion Del Partner" />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="fw-lighter">
                {formData.guardian ? "Guardian" : "Cliente"}
              </h6>
              <p className="fst-italic">
                {formData.guardian
                  ? formData?.guardian?.name
                  : formData?.customer?.name}
              </p>
            </div>
            <div>
              <h6 className="fw-lighter">Email</h6>
              <p className="fst-italic">
                {formData.guardian
                  ? formData?.guardian?.email
                  : formData?.customer?.email}
              </p>
            </div>
            <div>
              <h6 className="fw-lighter">Documento</h6>
              <p className="fst-italic">
                {formData.guardian
                  ? formData?.guardian?.document
                  : formData?.customer?.document}
              </p>
            </div>
            <div>
              <h6 className="fw-lighter">Numero Telefonico</h6>
              <p className="fst-italic">
                {formData.guardian
                  ? formData?.guardian?.phone_number
                  : formData?.customer?.phone_number}
              </p>
            </div>
          </div>
        </>
      )}
      <div className="input-group mt-4">
        <TextAreaField
          fieldLabel="Observaciones"
          fieldName="observations"
          fieldValue={formData?.observations}
          onChangeHandler={onChange}
        />
      </div>
    </div>
  );
};

export default TransactionDetailFields;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TransactionAdjustmentBody from "./TransactionAdjustmentBody";
import TransactionAdjustmentAside from "./TransactionAdjustmentAside";
import BackButton from "../../../common/navigation/buttons/BackButton";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import LoadingSpinner from "../../../../components/common/forms/fieldSections/LoadingSpinner";

const TransactionAdjustmentForm = ({ onSubmitForm }) => {
  const { user } = useSelector((state) => state.auth);
  const { seeds, isLoading } = useSelector((state) => state.seed);
  const { seedSpecies } = useSelector((state) => state.seedSpecie);

  const initialFormData = {
    user: user,
    seeds: [],
    seed_specie: {},
    observations: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  // SEED LOGIC
  const [seedsCopy, setSeedsCopy] = useState(seeds);
  const [seedsFiltered, setSeedsFiltered] = useState([]);

  // MODALS LOGIC
  const [showTransactionOverviewModal, setShowTransactionOverviewModal] =
    useState(false);

  useEffect(() => {}, [formData, seedsCopy]);

  // MODAL LOGIC
  const openTransactionOverviewModal = () => {
    if (formData.seeds.length < 1) {
      alert("Debes Agregar Semillas");
      return;
    }

    setShowTransactionOverviewModal(true);
  };

  const closeTransactionOverviewModal = () =>
    setShowTransactionOverviewModal(false);

  const handleSeedSpecieChange = (selectedSeedSpecie) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seed_specie: { ...selectedSeedSpecie },
    }));
    onChangeSpecie(selectedSeedSpecie?.seed_specie_name);
  };

  const onChangeSpecie = (selectedSeedSpecie) => {
    const newSeedSelectedArray = seedsCopy.filter((seed) => {
      return seed?.seed_specie?.seed_specie_name === selectedSeedSpecie;
    });

    setSeedsFiltered(newSeedSelectedArray);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const handleAddSeed = (seedToUpdated) => {
    const updatedAllSeedsCopy = seedsCopy.filter((seed) => {
      return seed.id !== seedToUpdated.id;
    });

    setSeedsCopy(updatedAllSeedsCopy);

    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: [...prevFormData.seeds, seedToUpdated],
    }));
  };

  const handleDeleteSeed = (seedToDelete) => {
    const newSeedSelectedArray = formData.seeds.filter((seed) => {
      return seed.id !== seedToDelete.id;
    });

    const updatedAllSeedsCopy = [...seedsCopy, seedToDelete];
    setSeedsCopy(updatedAllSeedsCopy);

    setFormData((prevFormData) => ({
      ...prevFormData,
      seeds: newSeedSelectedArray,
    }));
  };

  const handleCreateTransaction = () => {
    closeTransactionOverviewModal();

    if (formData.seeds.length < 1) {
      alert("No Tienes Semillas Agregadas");
      return;
    }

    onSubmitForm(formData);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="d-flex justify-content-start">
          <div className="border border-2 rounded-4 shadow">
            <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
              <div className="container">
                <TitleSection title="Creacion De Transaccion Para Ajuste Inventario" />
              </div>
              <div>
                <BackButton />
              </div>
            </div>
            <div className="p-3">
              <TransactionAdjustmentBody
                seeds={
                  Object.keys(formData?.seed_specie).length > 0
                    ? seedsFiltered
                    : seedsCopy
                }
                formData={formData}
                addSeed={handleAddSeed}
                allSpecies={seedSpecies}
                onChange={handleInputChange}
                handleSeedSpecieChange={handleSeedSpecieChange}
                showTransactionOverviewModal={showTransactionOverviewModal}
                closeTransactionOverviewModal={closeTransactionOverviewModal}
                handleCreateTransaction={handleCreateTransaction}
              />
            </div>
          </div>
          {!showTransactionOverviewModal && (
            <div className="aside-form w-25">
              <TransactionAdjustmentAside
                data={formData}
                onDeleteItem={handleDeleteSeed}
                onSubmit={handleCreateTransaction}
                openTransactionOverviewModal={openTransactionOverviewModal}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransactionAdjustmentForm;

import React, { useState, useEffect } from "react";
import "../../../../styles/forms.css";

import SeedFormFields from "./SeedFormFields";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import LoadingSpinner from "../../../common/forms/fieldSections/LoadingSpinner";

const SeedForm = ({ onSubmitForm, isUpdating, isLoading }) => {
  const initialFormData = {
    total_cost: "",
    seed_specie: "",
    seeds_per_gram: "",
    seed_entry_weight: "",
    seed_observations: "",
    seed_cost_per_gram: "",
    seed_location_stuck: "",
    seed_usage: "pendiente",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {}, [isUpdating]);

  const handleSeedSpecieChange = (selectedSeedSpecie) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seed_specie: selectedSeedSpecie.id,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value.toLowerCase(),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToReturn = {
      ...formData,
      seed_cost_per_gram: (
        formData.total_cost / formData.seeds_per_gram
      ).toFixed(2),
    };

    onSubmitForm(formDataToReturn);
  };

  return (
    <div className="container custom-border-medium shadow">
      <div className="d-flex justify-content-between bg-light text-dark">
        <div className="container">
          <TitleSection
            title={`${isUpdating ? "Actualizar" : "Registrar"} Semilla`}
          />
        </div>
      </div>
      <form className="pt-0" onSubmit={handleSubmit}>
        <div className="mb-3">
          <div>
            <SeedFormFields
              formData={formData}
              onChange={handleInputChange}
              isUpdating={false}
              handleSeedSpecieChange={handleSeedSpecieChange}
            />
          </div>
        </div>
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-4">
            <ButtonSection
              text={`${isUpdating ? "Actualizar" : "Registrar"}`}
              color="green"
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default SeedForm;

import { createSlice } from "@reduxjs/toolkit";
import * as fixedCostThunks from "./thunks/fixedCostThunks";

// Initial state of the seed slice
const initialState = {
  fixedCosts: [],
  fixedCost: {},
  fixedCostPerPresentation: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

const fixedCostSlice = createSlice({
  name: "fixedCost",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Thunks related to seed actions
      .addCase(fixedCostThunks.registerFixedCost.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(fixedCostThunks.registerFixedCost.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(fixedCostThunks.registerFixedCost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fixedCostThunks.getAllFixedCosts.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(fixedCostThunks.getAllFixedCosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fixedCosts = action.payload;
      })
      .addCase(fixedCostThunks.getAllFixedCosts.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(fixedCostThunks.getFixedCostPerPresentation.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(
        fixedCostThunks.getFixedCostPerPresentation.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.fixedCostPerPresentation = action.payload;
        }
      )
      .addCase(
        fixedCostThunks.getFixedCostPerPresentation.rejected,
        (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.errorMessage = action.payload;
        }
      )
      .addCase(fixedCostThunks.updateFixedCost.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(fixedCostThunks.updateFixedCost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seed = action.payload;
        state.isSuccess = true;
      })
      .addCase(fixedCostThunks.updateFixedCost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(fixedCostThunks.deleteFixedCost.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fixedCostThunks.deleteFixedCost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(fixedCostThunks.deleteFixedCost.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      });
  },
});

// Export the actions from the reducers
export const { reset } = fixedCostSlice.actions;

// Export the reducer
export default fixedCostSlice.reducer;

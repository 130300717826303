import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useDataAndReloadPage from "../../../helpers/hooks/useDataAndReloadPage";
import { getFilteredSeeds } from "../../../store/seeds/seeds/thunks/seedThunks";
import QualityTestForm from "../../../components/qualityTests/forms/QualityTestForm";

function QualityTestCreatePage() {
  const { seed, errorMessage, isLoading } = useSelector((state) => state.seed);
  const { user } = useSelector((state) => state.auth);

  const { slug } = useParams();
  /*
  to do
  const [searchTerm, setSearchTerm] = useState({
    term: `slug=${slug}`,
  });
  */
  const { reload } = useDataAndReloadPage(
    [{ func: getFilteredSeeds, param: {
      term: `slug=${slug}`,
    } }],
    [errorMessage]
  );

  const handleQualityTestCreation = async (testData) => {
    console.log(testData);
  };

  reload();

  return (
    <div className="container">
      <QualityTestForm
        onSubmitForm={handleQualityTestCreation}
        seed={seed}
        user={user}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </div>
  );
}

export default QualityTestCreatePage;

import React, { useState } from "react";
import DecimalField from "../../../common/forms/fields/numberFields/DecimalField";

const TransactionAdjustmentSeedCard = ({ seed, onAddSeed }) => {
  const [newWeight, setNewWeight] = useState(0);

  const handleNewWeightChange = (event) => {
    setNewWeight(event.target.value);
  };

  const addSeed = (e) => {
    e.preventDefault();

    const adjustmentWeight =
      Number(seed.seed_current_weight) - Number(newWeight);

    if (0 > adjustmentWeight) {
      alert("Peso Nuevo Es Mayor Al Peso Actual");
      return;
    }

    const seedInfo = {
      ...seed,
      newWeight: newWeight,
    };

    onAddSeed(seedInfo);
  };

  return (
    <div className="container mt-2">
      <form className="card" onSubmit={addSeed}>
        <div className="card-body d-flex d-flex justify-content-between">
          <div className="w-50">
            <h4 className="card-title">{seed.seed_specie.seed_botanic_name}</h4>
            <p className="fst-italic">
              {seed.seed_specie.seed_group.seed_group_name}
            </p>
            <p className="fst-italic">{seed.seed_code}</p>
          </div>

          <div className="mt-4 ms-5 w-50">
            <h6 className="card-subtitle mb-2 text-muted">Peso Actual</h6>
            <p>{seed.seed_current_weight}</p>
          </div>

          <div className="form-group w-50 ms-4 mt-3">
            <DecimalField
              fieldLabel="Peso Nuevo"
              fieldName="newWeight"
              fieldValue={newWeight}
              onChangeHandler={handleNewWeightChange}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Agregar Semilla
        </button>
      </form>
    </div>
  );
};

export default TransactionAdjustmentSeedCard;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import SeedGroupForm from "../forms/SeedGroupForm";
import ListTable from "../../../common/tables/ListTable";
import { formatDateTime } from "../../../../helpers/dateHelper";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";

function SeedGroupsListTable({ onSeedGroupOperation }) {
  const { isLoading, errorMessage, seedGroups, isError } = useSelector(
    (state) => state.seedGroup
  );

  const [isGroupFormOpen, setIsGroupFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [seedGroup, setSeedGroup] = useState({});

  useEffect(() => {
    if (!isEditing) setSeedGroup({});
  }, [isEditing, isGroupFormOpen]);

  const handleRedirect = (selectedSeedGroup) => {
    setIsEditing(true);
    setSeedGroup(selectedSeedGroup);
    setIsGroupFormOpen(!isGroupFormOpen);
  };

  const handleToggleForm = () => {
    setIsGroupFormOpen(!isGroupFormOpen);
    setIsEditing(false);
  };

  const handleDelete = (seedGroupData) => {
    onSeedGroupOperation("delete", seedGroupData);
  };

  const handleRegisterSeedGroup = async (seedGroupData) => {
    const operationType = isEditing ? "update" : "register";

    await onSeedGroupOperation(operationType, seedGroupData);

    setIsEditing(false);
    setSeedGroup({});
    if (!isError) {
      setIsGroupFormOpen(false);
    }
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => capitalizeFirstLetterOfWords(row.seed_group_name),
      name: "Nombre De Grupo",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row.created_at),
      name: "Fecha De Creacion",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row.updated_at),
      name: "Fecha Actualizacion",
    },
  ];

  return (
    <div className="container">
      <div className="d-flex justify-content-between p-3 mb-2 bg-light text-dark">
        <div className="container">
          <TitleSection title="Grupos De Semillas" />
        </div>
        <div className="w-25 d-flex justify-content-end">
          <ButtonSection
            text={isGroupFormOpen ? "Atras" : "Crear Nuevo Grupo"}
            color={isEditing ? "grey" : "yellow"}
            onClick={handleToggleForm}
          />
        </div>
      </div>

      {isGroupFormOpen && (
        <div>
          <SeedGroupForm
            isLoading={isLoading}
            seedGroup={seedGroup}
            errorMessage={errorMessage}
            onRegisterSeedGroup={handleRegisterSeedGroup}
          />
        </div>
      )}
      <div>
        <ListTable
          data={seedGroups}
          columns={columns}
          isLoading={isLoading}
          onDelete={handleDelete}
          onRedirect={handleRedirect}
          emptyMessage="No Grupo De Semillas Registrado"
        />
      </div>
    </div>
  );
}

export default SeedGroupsListTable;

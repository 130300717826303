import axios from "axios";
import Cookies from "js-cookie";

// Define API URLs
const API_BASE_URL = "http://127.0.0.1:8000/api";
const REGISTER_URL = `${API_BASE_URL}/users/register/`;
const LOGIN_URL = `${API_BASE_URL}/users/login/`;
const VERIFY_URL = `${API_BASE_URL}/token/verify/`;
const GETUSER_URL = `${API_BASE_URL}/users/me/`;
const GET_ALL_ESPORA_USERS_URL = `${API_BASE_URL}/users/all-users/`;
const UPDATE_USER_PASSWORD_URL = `${API_BASE_URL}/users/update_password/`;
const DELETE_ESPORA_USER_URL = `${API_BASE_URL}/users/delete/`;

const register = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(REGISTER_URL, userData, config);

  return response.data;
};

const login = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(LOGIN_URL, userData, config);

  if (response.data) {
    Cookies.set("access", response.data.access, {
      expires: 1,
      sameSite: "strict",
      secure: process.env.NODE_ENV === "production",
    });
    Cookies.set("refresh", response.data.refresh, {
      expires: 1,
      sameSite: "strict",
      secure: process.env.NODE_ENV === "production",
    });
  }
  return response.data;
};

const logout = async () => {
  Cookies.set("access", "", {
    expires: new Date(0),
    sameSite: "strict",
    secure: process.env.NODE_ENV === "production",
  });

  Cookies.set("refresh", "", {
    expires: new Date(0),
    sameSite: "strict",
    secure: process.env.NODE_ENV === "production",
  });
};

const getUser = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${Cookies.get("access")}`,
    },
  };

  const response = await axios.get(GETUSER_URL, config);

  return response.data;
};

const getEsporaUsers = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${Cookies.get("access")}`,
    },
  };

  const response = await axios.get(GET_ALL_ESPORA_USERS_URL, config);
  return response.data;
};

const checkAuthentication = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const userData = JSON.stringify({
    token: `${Cookies.get("access")}`,
  });

  await axios.post(VERIFY_URL, userData, config);
};

const updateProfile = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${Cookies.get("access")}`,
    },
  };
  let url = `${API_BASE_URL}/profile/update/${userData.id}/`;

  const response = await axios.patch(url, userData, config);
  return response.data;
};

const updateUserPassword = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${Cookies.get("access")}`,
    },
  };
  const response = await axios.post(UPDATE_USER_PASSWORD_URL, userData, config);
  return response.data;
};

const deleteEsporaUser = async (userData) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${Cookies.get("access")}`,
    },
  };
  const response = await axios.delete(
    `${DELETE_ESPORA_USER_URL}${userData}`,
    config
  );
  return response.data;
};

const authService = {
  login,
  logout,
  getUser,
  register,
  updateProfile,
  getEsporaUsers,
  deleteEsporaUser,
  updateUserPassword,
  checkAuthentication,
};

export default authService;

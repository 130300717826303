import React, { useState } from "react";
import "../../../../styles/forms.css";
import { useNavigate } from "react-router-dom";
import useDataAndReloadPage from "../../../../helpers/hooks/useDataAndReloadPage";
import useOperationHandler from "../../../../helpers/hooks/useOperationHandler";
import {
  getFilteredGuardians,
  registerGuardian,
  deleteGuardian,
} from "../../../../store/partners/guardians/thunks/guardianThunks";
import GuardiansListTable from "../../../../components/partners/guardians/tables/GuardiansListTable";

function GuardiansListTablePage() {
  const [searchTerm, setSearchTerm] = useState({ term: "" });

  const navigate = useNavigate();

  const { reload } = useDataAndReloadPage(
    [{ func: getFilteredGuardians, param: searchTerm }],
    [searchTerm]
  );

  const { handleOperation } = useOperationHandler({
    register: registerGuardian,
    delete: deleteGuardian,
  });

  const handleGuardianOperation = async (operation, fixedCostData) => {
    await handleOperation(operation, fixedCostData);

    reload();
  };

  const handleSearch = (term) => {
    const newTerm = Object.entries(term)
      .map(([key, value]) => (value ? `${key}=${value}` : ""))
      .filter(Boolean)
      .join("&");
    const updatedSearchTerm = { ...searchTerm, term: newTerm, page: "1" };
    setSearchTerm(updatedSearchTerm);
  };

  const handleRedirectToDetail = (guardianId) => {
    navigate(`/guardians/detail/${guardianId}`);
  };

  return (
    <div className="container custom-border-large">
      <div className="mt-4">
        <GuardiansListTable
          onSearch={handleSearch}
          onRedirect={handleRedirectToDetail}
          onSubmitOperation={handleGuardianOperation}
        />
      </div>
    </div>
  );
}

export default GuardiansListTablePage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ListTable from "../../../common/tables/ListTable";
import { formatDateTime } from "../../../../helpers/dateHelper";

const UndeliveredTransactionsTable = ({ transactions }) => {
  const navigate = useNavigate();

  const filteredTransactions = transactions.filter((transaction) => {
    return (
      transaction.transaction_type === "venta" &&
      transaction.delivery_status === "pendiente"
    );
  });

  useEffect(() => {}, [transactions]);

  const handleRedirect = (transactionData) => {
    navigate(`/transactions/detail/${transactionData.id}`);
  };

  const columns = [
    {
      sortable: true,
      selector: (row) => row?.transaction_number,
      name: "Numero De Transaccion",
    },
    {
      sortable: true,
      selector: (row) => row?.customer?.name,
      name: "Cliente",
    },
    {
      sortable: true,
      selector: (row) => formatDateTime(row?.created_at),
      name: "Fecha Cracion Transaccion",
    },
    {
      sortable: true,
      selector: (row) => row?.payment_status,
      name: "Estado Del Pago",
    },
  ];

  return (
    <div>
      <ListTable
        pagination
        data={filteredTransactions}
        columns={columns}
        onRedirect={handleRedirect}
        keyField="transaction_number"
        emptyMessage="No Tienes Transacciones Pendientes Por Entregar"
      />
    </div>
  );
};

export default UndeliveredTransactionsTable;

import React from "react";

function SubtitlesSection({ subTitle, className }) {
  return (
    <div className={className}>
      {/* Display the subtitle in a styled box */}
      <div className="bg-success bg-gradient text-white px-2 mb-4 rounded">
        <p className="fs-4 fw-light">{subTitle}</p>
      </div>
    </div>
  );
}

export default SubtitlesSection;

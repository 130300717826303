import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import GuardianDetailFields from "./GuardianDetailFields";
import BackButton from "../../../common/navigation/buttons/BackButton";
import TitleSection from "../../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../../common/forms/fieldSections/ButtonSection";
import LoadingSpinner from "../../../common/forms/fieldSections/LoadingSpinner";
import SubtitlesSection from "../../../common/forms/fieldSections/SubtitlesSection";
import SecondarySeedsListTable from "../../../seeds/seeds/tables/SecondarySeedsListTable";
import SecondaryTransactionListTable from "../../../transactions/tables/SecondaryTransactionListTable";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const GuardianDetail = ({
  onSubmitForm,
  handleRedirectToSeedDetail,
  handleRedirectToTransactionDetail,
}) => {
  const { isLoading, guardian } = useSelector((state) => state.guardian);
  const { seeds } = useSelector((state) => state.seed);
  const { transactions } = useSelector((state) => state.transactions);

  const initialFormData = {
    name: guardian?.name || "",
    email: guardian?.email || "",
    document: guardian?.document || "",
    phone_number: guardian?.phone_number || "+57",
    vereda: guardian?.vereda || "",
    municipality: guardian?.municipality || "",
    climate: guardian?.climate || "",
    altitude_range: guardian?.altitude_range || "",
    have_rut: guardian?.have_rut || false,
    partner_type: guardian?.partner_type || "",
    description: guardian?.description || "",
    created_at: guardian?.created_at,
    updated_at: guardian?.updated_at,
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData(initialFormData);
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardian]);

  const onChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSubmitForm(formData);
  };

  return (
    <div>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <div className="container custom-border-large shadow">
          <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
            <div className="container">
              <TitleSection
                title={capitalizeFirstLetterOfWords(formData.name)}
              />
            </div>
            <div>
              <BackButton />
            </div>
          </div>

          {/* Form Section */}
          <form className="p-5 pt-0" onSubmit={onSubmit}>
            <div className="d-flex justify-content-center">
              <ButtonSection text="Actualizar" color="green" />
            </div>

            {/* General Information Section */}
            <div className="mb-3">
              <div className="mt-4">
                <SubtitlesSection subTitle="informacion General" />
              </div>
              <div>
                <GuardianDetailFields formData={formData} onChange={onChange} />
              </div>
            </div>
          </form>

          {/* Seeds Section */}
          <div className="p-5">
            <div>
              <SubtitlesSection
                subTitle="Semillas Disponibles"
                className="mb-4"
              />
            </div>
            <div>
              <SecondarySeedsListTable
                seeds={seeds}
                isLoading={isLoading}
                onRedirect={handleRedirectToSeedDetail}
              />
            </div>
          </div>

          {/* Transactions Section */}
          <div className="p-5">
            <div>
              <SubtitlesSection
                subTitle="Transacciones Realizadas"
                className="mb-4"
              />
            </div>
            <div>
              <div className="mt-2 bg-primary bg-gradient text-white px-3 rounded">
                <div>
                  <p className="fs-5 fw-light">Transacciones Abiertas</p>
                </div>
              </div>
              <SecondaryTransactionListTable
                onRedirect={handleRedirectToTransactionDetail}
                transactions={transactions.filter(
                  (item) => item.transaction_status === "abierto"
                )}
                showExpandableRows={true}
                isLoading={isLoading}
              />
            </div>
            <div>
              <div className="mt-2 bg-primary bg-gradient text-white px-3 rounded">
                <div>
                  <p className="fs-5 fw-light">Transacciones Cerradas</p>
                </div>
              </div>
              <SecondaryTransactionListTable
                onRedirect={handleRedirectToTransactionDetail}
                transactions={transactions.filter(
                  (item) => item.transaction_status === "cerrado"
                )}
                showExpandableRows={true}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuardianDetail;

import React from "react";

const TitleSection = ({ title, className }) => {
  return (
    <div className={className}>
      <div className="bg-light text-dark">
        <h2 className="fs-2 fw-light">{title}</h2>
      </div>
    </div>
  );
};

export default TitleSection;

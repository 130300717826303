import React from "react";
import "../../../../../styles/forms.css";

const CheckField = ({
  fieldLabel,
  fieldKey,
  fieldName,
  isChecked,
  onChangeHandler,
}) => {
  const handleChange = onChangeHandler || (() => {}); // Default onChange function

  return (
    <div key={fieldKey ? fieldKey : null}>
      <label className="form-label fw-lighter">{fieldLabel}: </label>
      <input
        className="form-check-input checkbox-large ms-4"
        type="checkbox"
        name={fieldName ? fieldName : null}
        checked={isChecked ? true : false}
        onChange={handleChange}
      />
    </div>
  );
};

export default CheckField;

import React, { useState } from "react";
import EsporaUserDetailFields from "./EsporaUserDetailFields";
import BackButton from "../../common/navigation/buttons/BackButton";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";
import TitleSection from "../../common/forms/fieldSections/TitleSection";
import LoadingSpinner from "../../common/forms/fieldSections/LoadingSpinner";

const EsporaUserDetails = ({
  isLoading,
  esporaUser,
  errorMessage,
  updateEsporaUser,
}) => {
  const initialFormData = {
    id: esporaUser?.id,
    username: esporaUser?.profile?.username,
    email: esporaUser?.email,
    phone_number: esporaUser?.profile?.phone_number,
    address: esporaUser?.profile?.address,
    license: esporaUser?.profile?.license,
    about_me: esporaUser?.profile?.about_me,
  };

  const [formData, setFormData] = useState(initialFormData);

  const onChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    updateEsporaUser(formData);
  };

  return (
    <>
      <div className="d-flex justify-content-between p-3 bg-light text-dark">
        <div>
          <TitleSection title="Informacion Del Perfil" />
        </div>
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={onSubmitForm}>
        <div className="mb-3">
          <div>
            <EsporaUserDetailFields formData={formData} onChange={onChange} />
          </div>
        </div>
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection text="Actualizar" color="green" />
          </div>
        )}
      </form>
    </>
  );
};

export default EsporaUserDetails;

import React from "react";
import { useSelector } from "react-redux";
import TextField from "../fields/textFields/TextField";
import SelectField from "../fields/selectionFields/SelectField";
import { capitalizeFirstLetterOfWords } from "../../../../helpers/stringsHelpers";

const SeedGroupSelection = ({
  isReadOnly,
  handleChange,
  selectedSeedGroup,
}) => {
  const { seedGroups } = useSelector((state) => state.seedGroup);

  return (
    <div className="form-group d-flex mt-4">
      {isReadOnly ? (
        <div className="w-50">
          <TextField
            fieldLabel="Grupo De Semillla:"
            fieldValue={capitalizeFirstLetterOfWords(
              selectedSeedGroup?.seed_group_name
            )}
            isReadOnly={true}
          />
        </div>
      ) : (
        <>
          <div className="w-50">
            <SelectField
              label="Selecciona Un Grupo"
              labelKey="seed_group_name"
              handleChange={handleChange}
              optionsToShow={seedGroups}
              selectedOption={selectedSeedGroup}
            />
          </div>
        </>
      )}
    </div>
  );
};

SeedGroupSelection.propTypes = {};

export default SeedGroupSelection;

import axios from "axios";
import Cookies from "js-cookie";

// Define API base URL
const API_BASE_URL = "http://127.0.0.1:8000/api";

// Centralized headers for API requests
const headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `JWT ${Cookies.get("access")}`,
  },
};

/**
 * Seed API Service for handling Seed-related operations.
 */
const seedSpecieService = {
  /**
   * Retrieves a list of all Seed Species.
   * @returns {Promise} A promise that resolves to the list of Seed Species.
   */
  getAllSeedSpecies: async () => {
    const response = await axios.get(
      `${API_BASE_URL}/seeds/seed-species/list/`,
      headers
    );
    return response.data;
  },

  /**
   * Registers a new Seed Specie.
   * @param {Object} seedSpecieData - Data for registering a new Seed Specie.
   * @returns {Promise} A promise that resolves to the registered Seed Specie data.
   */
  registerSeedSpecie: async (seedSpecieData) => {
    const response = await axios.post(
      `${API_BASE_URL}/seeds/seed-species/create/`,
      seedSpecieData,
      headers
    );

    return response.data;
  },

  /**
   * Updates the data of an existing Seed Specie.
   * @param {Object} seedSpecieData - Updated data for the Seed Specie.
   * @returns {Promise} A promise that resolves to the updated Seed Specie data.
   */
  updateSeedSpecie: async (seedSpecieData) => {
    const url = `${API_BASE_URL}/seeds/seed-species/update/${seedSpecieData.id}/`;
    const response = await axios.patch(url, seedSpecieData, headers);
    return response.data;
  },

  /**
   * Deletes a Seed Specie by ID.
   * @param {Object} seedSpecieData - Data specifying the Seed Specie to be deleted.
   * @returns {Promise} A promise that resolves when the Seed Specie is successfully deleted.
   */
  deleteSeedSpecie: async (seedSpecieData) => {
    const url = `${API_BASE_URL}/seeds/seed-species/delete/${seedSpecieData.id}/`;
    const response = await axios.delete(url, headers);
    return response.data;
  },
};

export default seedSpecieService;

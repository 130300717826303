import React, { useState } from "react";
import EsporaUserFormFields from "./EsporaUserFormFields";
import BackButton from "../../common/navigation/buttons/BackButton";
import TitleSection from "../../common/forms/fieldSections/TitleSection";
import ButtonSection from "../../common/forms/fieldSections/ButtonSection";
import LoadingSpinner from "../../common/forms/fieldSections/LoadingSpinner";

const EsporaUserForm = ({ onSubmitForm, isLoading }) => {
  const initialFormData = {
    username: "",
    email: "",
    password: "",
    rePassword: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const onChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.rePassword) {
      alert("Los Passwords No Cohinciden");
      return;
    }

    onSubmitForm({
      username: formData.username,
      email: formData.email,
      password: formData.password,
    });
  };

  return (
    <div className="container custom-border-large shadow mt-5 w-50">
      <div className="d-flex justify-content-between p-3 mb-4 bg-light text-dark">
        <div className="container">
          <TitleSection title="Register Espora User" />
        </div>
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <div>
            <EsporaUserFormFields formData={formData} onChange={onChange} />
          </div>
        </div>
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <ButtonSection text="Register" color="green" />
          </div>
        )}
      </form>
    </div>
  );
};

export default EsporaUserForm;

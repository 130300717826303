import { createSlice } from "@reduxjs/toolkit";
import * as seedThunks from "./thunks/seedThunks";

const initialState = {
  seeds: [],
  seed: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

const seedSlice = createSlice({
  name: "seed",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(seedThunks.registerSeed.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(seedThunks.registerSeed.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(seedThunks.registerSeed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(seedThunks.getSeed.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(seedThunks.getSeed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seed = action.payload;
        state.isSuccess = true;
      })
      .addCase(seedThunks.getSeed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(seedThunks.updateSeed.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(seedThunks.updateSeed.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seed = action.payload;
        state.isSuccess = true;
      })
      .addCase(seedThunks.updateSeed.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedThunks.getFilteredSeeds.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(seedThunks.getFilteredSeeds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.seeds = action.payload;
      })
      .addCase(seedThunks.getFilteredSeeds.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedThunks.getSeedsWithoutQualityTests.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(
        seedThunks.getSeedsWithoutQualityTests.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.seeds = action.payload;
        }
      )
      .addCase(
        seedThunks.getSeedsWithoutQualityTests.rejected,
        (state, action) => {
          state.isError = true;
          state.isLoading = false;
          state.isSuccess = false;
          state.errorMessage = action.payload;
        }
      );
  },
});

// Export the actions from the reducers
export const { reset } = seedSlice.actions;

// Export the reducer
export default seedSlice.reducer;

import React from "react";
import "../../../styles/forms.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { registerAdjustmentTransaction } from "../../../store/transactions/thunks/transactionsThunks";
import TransactionAdjustmentForm from "../../../components/transactions/transactionAdjustment/forms/TransactionAdjustmentForm";

function TransactionCreateAdjustmentInventoryPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateTrasaction = async (transactionData) => {
    const action = await dispatch(
      registerAdjustmentTransaction(transactionData)
    );

    if (action.error) {
      alert("Hubo Un Error Creando La Transaccion");
      return;
    }

    alert("La Transaccion Se Creo Exitosamente.");

    navigate(`/transactions/create/`);
  };

  return (
    <div>
      <TransactionAdjustmentForm onSubmitForm={handleCreateTrasaction} />
    </div>
  );
}

export default TransactionCreateAdjustmentInventoryPage;

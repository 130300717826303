export function capitalizeFirstLetterOfWords(inputString) {
  if (typeof inputString === "string" && inputString.trim() !== "") {
    const words = inputString.split(" ");

    const capitalizedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    });

    const resultString = capitalizedWords.join(" ");

    return resultString;
  } else {
    return inputString;
  }
}

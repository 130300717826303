import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../hocs/PrivateRoute";
import GuardianDetailPage from "../containers/pages/partners/guardians/GuardianDetailPage";
import GuardiansListTablePage from "../containers/pages/partners/guardians/GuardiansListTablePage";

/**
 * Defines the routes related to guardians within the application.
 * Utilizes the PrivateRoute higher-order component to secure access to certain guardian-related routes.
 * @returns {JSX.Element} The rendered guardian routes.
 */
const GuardianRoutes = () => (
  <Routes>
    {/* Route for viewing details of a specific guardian (requires authentication) */}
    <Route
      path="detail/:slug"
      element={
        <PrivateRoute>
          <GuardianDetailPage />
        </PrivateRoute>
      }
    />

    {/* Route for viewing a list of guardians (requires authentication) */}
    <Route
      path="list"
      element={
        <PrivateRoute>
          <GuardiansListTablePage />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default GuardianRoutes;

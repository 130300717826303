import React from "react";
import "../../../styles/forms.css";
import { DeleteIcon, InfoIcon } from "../../../helpers/Iconos";
import ButtonSection from "../../../components/common/forms/fieldSections/ButtonSection";
import DataTable from "react-data-table-component";

import LoadingSpinner from "../../common/forms/fieldSections/LoadingSpinner";

function ListTable({
  data,
  columns,
  onDelete,
  keyField,
  className,
  isLoading,
  pagination,
  onRedirect,
  expandableRowsComponent,
  emptyMessage,
}) {
  if (onRedirect || onDelete) {
    columns = [
      ...columns,
      {
        name: "Acciones",
        cell: (row) => (
          <>
            {onRedirect && (
              <div>
                {/* Botón para redirección */}
                <ButtonSection
                  text={InfoIcon}
                  color="blue"
                  onClick={() => onRedirect(row)}
                />
              </div>
            )}
            {onDelete && (
              <div className="ms-2">
                {/* Botón para eliminar */}
                <ButtonSection
                  text={DeleteIcon}
                  color="grey"
                  onClick={() => onDelete(row)}
                />
              </div>
            )}
          </>
        ),
        ignoreRowClick: true,
      },
    ];
  }

  return (
    <div className={`container custom-border-large p-3 shadow ${className}`}>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : data?.length === 0 ? (
        <p className="fst-italic">{emptyMessage}</p>
      ) : (
        <DataTable
          data={data}
          fixedHeader
          columns={columns}
          pagination={pagination}
          expandableRows={expandableRowsComponent ? true : false}
          expandableRowsComponent={expandableRowsComponent}
          keyField={keyField}
        />
      )}
    </div>
  );
}

export default ListTable;

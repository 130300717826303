import React from "react";
import { Link } from "react-router-dom";

const Toolbar = () => {
  return (
    <div className="pt-lg-3">
      <ul className="nav flex-column">
        <li className="nav-item m-1">
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed ms-2"
            data-bs-target="#entities-module-collapse"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            Entidades
          </button>
          <div className="collapse" id="entities-module-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4">
              {/* Guardians Section */}
              <li className="nav-item">
                <Link
                  to="/guardians/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Guardianes
                </Link>
              </li>

              {/* Customers Section */}
              <li className="nav-item">
                <Link
                  to="/customers/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Clientes
                </Link>
              </li>
            </ul>
          </div>
        </li>
        {/* Seeds Section */}
        <li className="nav-item m-1">
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed ms-2"
            data-bs-target="#semilla-module-collapse"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            Semillas
          </button>
          <div className="collapse" id="semilla-module-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4">
              {/* Seeds List */}
              <li className="nav-item">
                <Link
                  to="/seeds/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Stock De Semillas
                </Link>
              </li>
              {/* Seeds Groups */}
              <li className="nav-item">
                <Link
                  to="/seed-groups/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Grupos De Semillas
                </Link>
              </li>

              {/* Species of Seeds */}
              <li className="nav-item">
                <Link
                  to="/seed-species/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Especies De Semillas
                </Link>
              </li>

              {/* Presentations of Seeds */}
              <li className="nav-item">
                <Link
                  to="/seed-presentations/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Presentaciones De Semillas
                </Link>
              </li>
            </ul>
          </div>
        </li>

        {/* Cost Management Section */}
        <li className="nav-item m-1">
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed ms-2"
            data-bs-target="#costs-module-collapse"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            Administracion Costos
          </button>
          <div className="collapse" id="costs-module-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4">
              {/* Fixed Costs Section */}
              <li className="nav-item">
                <Link
                  to="/fixed-costs/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Costos Fijos
                </Link>
              </li>
            </ul>
          </div>
        </li>

        {/* Transactions Management Section */}
        <li className="nav-item m-1">
          <button
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed ms-2"
            data-bs-target="#transaction-module-collapse"
            data-bs-toggle="collapse"
            aria-expanded="false"
          >
            Transacciones
          </button>
          <div className="collapse" id="transaction-module-collapse">
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-4">
              {/* Create Transaction Section */}
              <li className="nav-item">
                <Link
                  to="/transactions/create"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Crear Transaccion
                </Link>
              </li>
              {/* List Transactions Section */}
              <li className="nav-item">
                <Link
                  to="/transactions/list"
                  className="nav-link d-flex align-items-center gap-2 active"
                >
                  Listar Transacciones
                </Link>
              </li>
            </ul>
          </div>
        </li>

        {/* Reports Section */}
        {/*
        <li className="nav-item">
          <a
            href="#"
            className="nav-link d-flex align-items-center gap-2 active"
          >
            Reports
          </a>
        </li>
        */}
      </ul>
    </div>
  );
};

export default Toolbar;

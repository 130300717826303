import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TransactionDetails from "../../../components/transactions/forms/TransactionDetails";
import LoadingSpinner from "../../../components/common/forms/fieldSections/LoadingSpinner";
import {
  updateTransaction,
  getTransaction,
} from "../../../store/transactions/thunks/transactionsThunks";

const TransactionsDetailsPage = () => {
  const { isLoading } = useSelector((state) => state.transactions);

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(getTransaction(slug))]);
    };

    fetchData();
    // to do: fix this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleTransactionUpdate = async (transactionData) => {
    const updatedTransactionData = { ...transactionData, slug };
    const action = await dispatch(updateTransaction(updatedTransactionData));
    if (action.error) {
      alert("Algo Salio Mal Actualizando");
      return;
    }
    alert("Semilla Actualizada.");
  };

  return (
    <div className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <TransactionDetails
          onSubmitForm={handleTransactionUpdate}
          // onSubmitOperation={handleOperation}
        />
      )}
    </div>
  );
};

export default TransactionsDetailsPage;

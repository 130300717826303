import { createSlice } from "@reduxjs/toolkit";
import * as seedGroupThunks from "./thunks/seedGroupThunks";

// Initial state of the seed slice
const initialState = {
  seedGroups: [],
  seedgroup: {},
  count: null,
  next: null,
  previous: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

/**
 * Redux slice for handling seed-related actions.
 */
const seedGroupSlice = createSlice({
  name: "seedGroup",
  initialState,
  reducers: {
    /**
     * Reset the seed slice to its initial state.
     * @param {Object} state - Current state of the seed slice.
     */
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Thunks related to seed actions
      .addCase(seedGroupThunks.registerSeedGroup.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
      })
      .addCase(seedGroupThunks.registerSeedGroup.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(seedGroupThunks.registerSeedGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(seedGroupThunks.getAllSeedGroups.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(seedGroupThunks.getAllSeedGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.seedGroups = action.payload;
        state.count = action.payload.count;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
      })
      .addCase(seedGroupThunks.getAllSeedGroups.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedGroupThunks.updateSeedGroup.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(seedGroupThunks.updateSeedGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seed = action.payload;
        state.isSuccess = true;
      })
      .addCase(seedGroupThunks.updateSeedGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload;
      })
      .addCase(seedGroupThunks.deleteSeedGroup.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(seedGroupThunks.deleteSeedGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(seedGroupThunks.deleteSeedGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      });
  },
});

// Export the actions from the reducers
export const { reset } = seedGroupSlice.actions;

// Export the reducer
export default seedGroupSlice.reducer;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SecurityDataForm from "../../../components/auth/forms/SecurityDataForm";
import { updatePassword } from "../../../store/auth/authSlice";

function UserSecurityForm() {
  const { isSuccess } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isSuccess, dispatch]);

  const handleUpdate = async (userData) => {
    if (window.confirm("Are you sure you want to update your password?")) {
      const action = await dispatch(updatePassword(userData));

      if (action.error) {
        alert("La Contraseña Actual No Es Valida");
        return;
      }

      alert("Your password has been updated.");
    }
  };

  return (
    <div className="container">
      <SecurityDataForm onUpdate={handleUpdate} />
    </div>
  );
}

export default UserSecurityForm;
